import { Validation } from '../../../shared/interfaces/util/FormValidation';

export const dateValidation: Validation = {
  required: {
    value: true,
    message: 'Date required'
  }
};

export const projectValidation: Validation = {
  required: {
    value: true,
    message: 'Project required'
  }
};

export const descriptionValidation: Validation = {
  required: {
    value: true,
    message: 'Description is required'
  },
  minLength: {
    value: 10,
    message: 'Description must be at least 10 characters long'
  },
  maxLength: {
    value: 512,
    message: 'Description must be less than 512 characters long'
  }
};

export const expenseTypeValidation: Validation = {
  required: {
    value: true,
    message: 'Expense type required'
  }
};

export const quantityValidation: Validation = {
  required: {
    value: true,
    message: 'Quantity is required'
  },
  min: {
    value: 0,
    message: 'Quantity must be 0 or larger'
  },
  //only accepts numbers and decimals
  pattern: {
    value: /((^|, )(^-?\d*,?\d*|^-?\d*\.?\d*))+$/,
    message: 'Quantity must be a number'
  }
};

export const costValidation: Validation = {
  required: {
    value: true,
    message: 'Travel cost is required'
  },
  min: {
    value: 0,
    message: 'Travel cost must be 0 or larger'
  },
  //only accepts numbers and decimals
  pattern: {
    value: /((^|, )(^-?\d*,?\d*|^-?\d*\.?\d*))+$/,
    message: 'Travel cost must be a number'
  }
};

export const currencyExchangeRateValidation: Validation = {
  required: {
    value: true,
    message: 'Currency exchange rate is required'
  },
  min: {
    value: 0,
    message: 'Currency exchange rate must be 0 or larger'
  },
  //only accepts numbers and decimals
  pattern: {
    value: /((^|, )(^-?\d*,?\d*|^-?\d*\.?\d*))+$/,
    message: 'Currency exchange rate must be a number'
  }
};

export const currencyValidation: Validation = {
  required: {
    value: true,
    message: 'Currency is required'
  }
};