import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CancelButton } from '../../../components/forms/CancelButton';
import { DateInput } from '../../../components/forms/DateInput';
import { DropDownInput } from '../../../components/forms/DropDownInput';
import { SubmitButton } from '../../../components/forms/SubmitButton';
import { TextAreaInput } from '../../../components/forms/TextAreaInput';
import { TextInput } from '../../../components/forms/TextInput';
import { Validation } from '../../../shared/interfaces/util/FormValidation';
import { useKeycloak } from '@react-keycloak/web';
import { Loader } from '../../../components/common/Loader';
import { useAppDispatch } from '../../../redux/store';
import { createToast } from '../../../redux/slices/toastSlice';
import { fetchProjects } from '../../../redux/slices/projectSlice';
import { ProjectsSelector } from '../../../redux/selectors/projects';
import { unwrapResult } from '@reduxjs/toolkit';
import { Project } from '../../../shared/interfaces/Project';
import { Option, OptionGroup } from '../../../shared/interfaces/util/SelectOption';
import * as Validator from './Validators';

/**
 * Props passed from parent component
 */
interface BookingFormProps {
  date?: moment.Moment;
  project?: string;
  task?: string;
  hours?: number;
  description?: string;
  reference?: string;
  status: 'add' | 'modify';
  aiResult?: boolean;
  userResponse?: boolean;
  onSubmit: (data: BookingFormData) => void;
  onCancel: () => void;
}

/**
 * Form data when user submits form
 */
export interface BookingFormData {
  date: string;
  project: string;
  task?: string;
  hours: string;
  description: string;
  reference?: string;
  startDate?: string;
  endDate?: string;
  userResponse?: boolean;
}

export const BookingForm = ({
  date,
  project,
  task,
  hours,
  description,
  reference,
  status,
  aiResult,
  userResponse,
  onSubmit,
  onCancel
}: BookingFormProps) => {

  const { register, setValue, getValues, watch, formState: { errors, isSubmitting }, handleSubmit } = useForm<FieldValues>({
    defaultValues: { project: project, task: task, hours: hours, description: description, reference: reference }
  });
  const { initialized } = useKeycloak();
  const projects = ProjectsSelector();
  const [projectOptions, setProjectOptions] = useState<(Option | OptionGroup)[]>([]);
  const [taskOptions, setTaskOptions] = useState<Option[]>([]);
  const [isRangeSelectProject, setIsRangeSelectProject] = useState(false);
  const watchProject = watch('project');
  const dispatch = useAppDispatch();

  /**
     * Fetch projects from API to redux state
     */
  useEffect(() => {
    if (!initialized) return;
    dispatch(fetchProjects())
      .then(unwrapResult)
      .catch(error => {
        console.log(error);
        dispatch(createToast('error', 'Error', 'Error fetching projects'));
      });
  }, [initialized, dispatch]);

  /**
     * Create form dropdown fields for projects
     */
  useEffect(() => {
    if (projects.length === 0) return;
    let generalProjects: Option[] = [];
    let assignedProjects: Option[] = [];
    projects.forEach((project) => {
      if (project.projectType.name === 'General projects') {
        generalProjects.push({
          text: project.name,
          value: project.id.toString()
        });
      } else {
        assignedProjects.push({
          text: `${project.name} [${project.customer.name}]`,
          value: project.id.toString()
        });
      }
    });
    let options: (Option | OptionGroup)[] = [];
    options.push({
      text: '-- Select project --',
      value: '-1'
    });
    options.push({
      label: 'ASSIGNED PROJECTS:',
      options: assignedProjects
    });
    options.push({
      label: 'GENERAL PROJECTS:',
      options: generalProjects
    });
    setProjectOptions(options);
  }, [projects]);


  /**
     * Checks if project has range select property in it
     * 
     * Range select - user can fill date range with the same booking
     * 
     * @param project - current selected project
     * @return boolean - true if range select, false if not range select
     */
  const checkIsRangeSelectProject = useCallback((project: Project) => {
    const RANGE_SELECT_PROJECT_NAMES = ['ATR Soft Sick leave', 'ATR Soft Annual holiday', 'ATR Soft Unpaid holiday'];
    let isRangeSelectProjectTmp = false;
    if (!project || !project.name) return false;
    RANGE_SELECT_PROJECT_NAMES.forEach(projectName => {
      if (project.name === projectName) {
        isRangeSelectProjectTmp = true;
      }
    });
    return isRangeSelectProjectTmp;
  }, []);

  /**
     * Update date input values
     * 
     * Workaround for react-hook-form not automatically updating
     * date input values if project is switched between range booking and
     * normal booking
     */
  const updateDateInput = useCallback((isRangeSelect: boolean) => {
    if (isRangeSelect) {
      setValue('startDate', date?.format('YYYY-MM-DD'));
      setValue('endDate', date?.format('YYYY-MM-DD'));
      return;
    }
    setValue('startDate', '');
    setValue('endDate', '');
  }, [date, setValue]);

  /**
     * Triggers when new project is selected from dropdown.
     * 
     * Update task options in dropdown (and create range select date inputs if project type is range select)
     */
  useEffect(() => {
    const currentProject = projects.filter((project) =>
      (project.id.toString() === watchProject));

    /**
         * Check if project is range booking project and update state accordingly
         * 
         * Conditional prevents from creating a range booking in "modify booking"-view
         */
    if (!window.location.pathname.includes('modifybooking')) {
      const isRangeSelectProjectTmp = checkIsRangeSelectProject(currentProject[0]);
      updateDateInput(isRangeSelectProjectTmp);
      setIsRangeSelectProject(isRangeSelectProjectTmp);
    }

    if (currentProject.length !== 1) {
      setTaskOptions([]);
      return;
    }
    // Set tasks for project
    const activeTasks = currentProject[0].tasks.filter((task) => (task.status && task.status.active));
    const options = activeTasks.map((task) => ({
      value: task.id.toString(),
      text: task.name
    }));
    setTaskOptions(options);

  }, [projects, watchProject, checkIsRangeSelectProject, updateDateInput]);

  /**
     * When task options change, set selection to either default task or first task
     */
  useEffect(() => {
    if (taskOptions.length > 0) {
      if (task && watchProject === project) {
        setValue('task', task);
      } else {
        setValue('task', taskOptions[0].value);
      }
    }
  }, [taskOptions, watchProject, project, task, setValue]);

  const dateValidation: Validation = {
    validate: {
      validateDateRange: () => moment(getValues('startDate')).diff(moment(getValues('endDate')), 'days') > -62
        || 'Maximum date range can be set to 2 months.',
      startDateBeforeEndDate: () => moment(getValues('startDate')).isSameOrBefore(moment(getValues('endDate')))
        || 'End date cannot be before start date.'
    }
  };

  /**
     * Prevent rendering before projects are loaded
     * Default values don't work if component is rendered before projects are loaded
     */
  if (projectOptions.length === 0) {
    return (
      <div data-testid="loader">
        <Loader data-testid="loader" />
      </div>
    );
  }

  return (
    <div >
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isRangeSelectProject ?
          <>
            <DateInput
              label="Start Date *"
              name="startDate"
              defaultDate={date ? date : moment()}
              register={register}
              dateValidation={dateValidation}
              errors={errors.startDate}
            />
            <DateInput
              label="End Date *"
              name="endDate"
              defaultDate={date ? date : moment()}
              register={register}
              dateValidation={dateValidation}
              errors={errors.endDate} />
          </>
          :
          <DateInput
            label="Date *"
            name="date"
            defaultDate={date ? date : moment()}
            register={register}
            errors={errors.date}
          />
        }
        <DropDownInput
          label="Project *"
          name="project"
          popupType="project"
          watch={watch}
          options={projectOptions}
          register={register}
          validation={Validator.projectValidation}
          errors={errors.project}
        />
        <DropDownInput
          label="Task"
          name="task"
          watch={watch}
          popupType="task"
          options={taskOptions}
          register={register}
          validation={{}}
          errors={errors.task}
          taskOptionCount={taskOptions.length}
        />
        <TextInput
          label="Hours *"
          name="hours"
          inputMode="decimal"
          register={register}
          validation={Validator.hoursValidation}
          errors={errors.hours}
        />
        <TextAreaInput
          label="Description *"
          name="description"
          register={register}
          validation={Validator.descriptionValidation}
          errors={errors.description}
        />
        <TextInput
          label="Reference"
          name="reference"
          register={register}
          validation={Validator.referenceValidation}
          errors={errors.reference}
        />
        {status === 'modify' && aiResult !== undefined && userResponse === undefined &&
          <AIResponseContainer>
            <AIResponseText>AI check of this booking</AIResponseText>
            <AIResponseText style={{ paddingBottom: 0 }}>Booking is {aiResult ? 'CORRECT' : 'INCORRECT'}</AIResponseText>
            <DropDownInput
              label="Was AI analysis correct?"
              name="aiResponse"
              watch={watch}
              options={[{ text: 'Yes, AI was correct', value: '1' }, { text: 'No, AI was wrong', value: '0' }]}
              register={register}
            />
          </AIResponseContainer>
        }
        <ButtonContainer>
          <SubmitButton
            disabled={isSubmitting}
            text={{ add: 'ADD HOURS', modify: 'MODIFY HOURS' }[status]}
          />
          <CancelButton
            text="CANCEL"
            onClick={onCancel}
          />
        </ButtonContainer>
      </Form>
    </div >
  );
};

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Form = styled.form`
    max-width: 500px;
    width: 90%;
    padding: 10px;
    font-family: "Consolas", monospace;
`;

const AIResponseContainer = styled.div`
    margin-top: 10px;
`;

const AIResponseText = styled.p`
    font-weight: 700;
    padding: 0;
    margin: 0;
    padding-bottom: 5px;
`;
