import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import styled from 'styled-components';
import { MidweekHolidaysSelector } from '../../redux/selectors/midweekHoliday';
import { ProjectsSelector } from '../../redux/selectors/projects';
import { createBooking } from '../../redux/slices/bookingSlice';
import { createToast } from '../../redux/slices/toastSlice';
import { useAppDispatch } from '../../redux/store';
import { CreateBookingParam } from '../../shared/interfaces/request/CreateBookingParam';

interface HolidayAlertControllerProps {
    weekDays: moment.Moment[];
    loading: boolean;
}

/**
 * Component to control midweek-holiday alerts in week view
 */
export const HolidayAlertController = ({ weekDays, loading }: HolidayAlertControllerProps) => {

  const midweekHolidays = MidweekHolidaysSelector(weekDays[0], weekDays[6]);
  const projects = ProjectsSelector();
  const dispatch = useAppDispatch();
  const holidayProject = projects.find((project) => project.name === 'ATR Soft Midweek holiday');

  /**
     * Submit autofilled midweek holiday-booking to API trough redux dispatch
     */
  const autoFillDate = (date: string, holidayName: string) => {
    if (!holidayProject) return;

    const booking: CreateBookingParam = {
      date: date,
      projectId: holidayProject?.id,
      hours: 7.5,
      description: `Midweek holiday: ${holidayName}`
    };
    dispatch(createBooking(booking))
      .then(unwrapResult)
      .then(res => {
        dispatch(createToast('success', 'Success', 'Booking created successfully'));
      })
      .catch(err => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error creating booking'));
      });
  };

  if (loading) return null;

  return (
    <div>
      {midweekHolidays?.map(m => (
        <Container key={m.date + ',' + m.name}>
          <Title>There are midweek holidays in this week: {m.name} ({m.date})</Title>
          <AutoFillButton onClick={() => autoFillDate(m.date, m.name)}>Fill automatically</AutoFillButton>
        </Container>
      ))}
    </div>
  );
};

const Container = styled.div`
    padding: 10px;
    background-color: #f4d13676; /* Red */
    margin-bottom: 15px;
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
    }
`;

const Title = styled.span`
`;

const AutoFillButton = styled.button`
    color: white;
    background-color: var(--atrMainColor);
    padding: 6px;
    margin-left: 25px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    @media (max-width: 900px) {
        margin-top: 10px;
        margin-left: 0;
    }
`;