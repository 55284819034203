/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
import React from 'react';
import { useMemo } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';
import { RiSurveyLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import styled from 'styled-components';
import { Survey } from '../../shared/interfaces/Survey';

interface SurveyTableProps {
    surveySelector: () => Survey[];
}

export const SurveyTable = ({ surveySelector }: SurveyTableProps) => {
  const surveys: Survey[] = surveySelector();

  /**
     * Create columns. UseMemo to prevent unnecessary re-renders
     */
  const columns: Column<any>[] = useMemo(() => [
    {
      Header: 'Id',
      accessor: 'id',
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'Survey text',
      accessor: 'surveyText',
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'Schedule',
      accessor: 'schedule',
      Cell: ({ row }: any) => {
        const scheduleType = row.original.schedule;
        if (scheduleType === 0) {
          return 'once';
        }
        if (scheduleType === 1) {
          return 'daily';
        }
        if (scheduleType === 2) {
          return 'weekly';
        }
        if (scheduleType === 3) {
          return 'monthly';
        }
        if (scheduleType === 4) {
          return 'yearly';
        }
      },
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'Recur every',
      accessor: 'recurEvery',
      Cell: ({ row }: any) => {
        const scheduleType = row.original.schedule;
        if (scheduleType === 0) {
          return 'once';
        }
        if (scheduleType === 1) {
          return `${row.original.recurEvery} days`;
        }
        if (scheduleType === 2) {
          return `${row.original.recurEvery} weeks`;
        }
        if (scheduleType === 3) {
          return `${row.original.recurEvery} months`;
        }
        if (scheduleType === 4) {
          return `${row.original.recurEvery} years`;
        }
      },
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'Start date',
      accessor: 'startDate',
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'End date',
      accessor: 'endDate',
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'Survey type',
      accessor: 'submitType',
      Cell: ({ row }: any) => {
        const surveyType = row.original.submitType;
        if (surveyType === 0) {
          return <span>Single submit</span>;
        }
        if (surveyType === 1) {
          return <span>Yes/no</span>;
        }
        if (surveyType === 2) {
          return <span>Smiley</span>;
        }
        return <span>-</span>;
      },
      sticky: 'left'
    },
    {
      Header: 'Edit',
      Cell: ({ row }: any) => {
        return <IconButton data-testid={`edit-survey-${row.original.id}`} to={`/modifysurvey/${row.original.id}`}><FaWrench /></IconButton>;
      },
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'Responses',
      Cell: ({ row }: any) => {
        return <IconButton data-testid={`view-responses-${row.original.id}`} to={`/viewsurveyresponses?surveyid=${row.original.id}`}><RiSurveyLine /></IconButton>;
      },
      disableFilters: true,
      sticky: 'left'
    }
  ]
  , []);

  // Row data
  const data: Survey[] = useMemo(() => surveys, [surveys]);

  const {
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    pageOptions,
    canNextPage,
    canPreviousPage,
    prepareRow
  } = useTable({
    columns,
    data,
    initialState: { pageSize: 18 }
  }, useSortBy, usePagination
  );
  return (
    <div>
      <Table>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableHead {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                </TableHead>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <PaginationContainer>
        <ArrowButton onClick={() => previousPage()} disabled={!canPreviousPage}><AiOutlineArrowLeft /></ArrowButton>
        <span> Page {state.pageIndex + 1} of {pageOptions.length} </span>
        <ArrowButton onClick={() => nextPage()} disabled={!canNextPage}><AiOutlineArrowRight /></ArrowButton>
      </PaginationContainer>
    </div>
  );
};

const Table = styled.table`
    width: 95%;
    min-width: 900px;
`;

const TableHead = styled.th`
    text-align: left;
    background-color: var(--atrMainColor);
    color: white;
    padding: 8px;
`;

const TableRow = styled.tr`
    :nth-child(even){background-color: var(--atrSecondaryColor);}
    :hover {background-color: #cfcccc99;}
    padding: 20px;
    margin: 20px;
`;

const TableCell = styled.td`
    padding: 3px;
`;

const PaginationContainer = styled.div`
    text-align: center;
    margin-right: 100px;
    margin-top: 20px;
`;

const ArrowButton = styled.button`
    background-color: var(--atrMainColor);
    color: white;
    border-radius: 5px;
    font-size: 15px;
`;

const IconButton = styled(Link)`
    padding: 0px 4px;
    padding-top: 3px;
    overflow: visible;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    border-radius: 7px;
`;