import moment from 'moment';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { Errors, Validation } from '../../shared/interfaces/util/FormValidation';
import { InputError } from './InputError';
import { Label } from './Label';

type DateInputProps = {
    label: string;
    name: string;
    defaultDate?: moment.Moment;
    register: UseFormRegister<FieldValues>;
    dateValidation?: Validation;
    errors?: Errors;
}

export const DateInput = ({ label, name, defaultDate, register, dateValidation, errors }: DateInputProps) => {
  return (
    <Container>
      <Label forInput={name} text={label} />
      {dateValidation?
      /* Range date input */
        <>
          <DateInputField
            type="date"
            id={name}
            aria-label={name}
            defaultValue={defaultDate ? defaultDate.toISOString().split('T')[0] : undefined}
            {...register(name, dateValidation)}
          />
          {errors && <InputError message={errors.message} />}
        </>
        :
      /* Normal date input */
        <>
          <DateInputField
            type="date"
            id={name}
            aria-label={name}
            defaultValue={defaultDate ? defaultDate.toISOString().split('T')[0] : undefined}
            {...register(name, {
              required: true,
            })}
          />
          {errors && errors.type === 'required' && <InputError message={'Date value required.'} />}
        </>
      }
    </Container>
  );
};

const Container = styled.div`
    padding: 5px 0;
`;

const DateInputField = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0;
    padding: 8px 10px;
    background-color: var(--inputColor);
    color: white;

    &::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
`;