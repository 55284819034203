import { useSelector } from 'react-redux';
import { RootState } from '../store';

export const ProjectsSelector = () => {
  return useSelector((state: RootState) => state.projects);
};

export const ProjectByIdSelector = (id: number | null) => {
  return useSelector((state: RootState) => {
    if (id === null) return;
    return state.projects.find(p => p.id === Number(id));
  });
};

export const TaskByIdSelector = (projectId: number | null, taskId: | number | null) => {
  return useSelector((state: RootState) => {
    if (projectId === null || taskId === null) return;
    const project = state.projects.find(p => p.id === Number(projectId));
    return project?.tasks.find(t => t.id === Number(taskId));
  });
};