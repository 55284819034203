import moment from 'moment';
import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CancelButton } from '../../../components/forms/CancelButton';
import { DateInput } from '../../../components/forms/DateInput';
import { DropDownInput } from '../../../components/forms/DropDownInput';
import { SubmitButton } from '../../../components/forms/SubmitButton';
import { TextAreaInput } from '../../../components/forms/TextAreaInput';
import { TextInput } from '../../../components/forms/TextInput';
import { Validation } from '../../../shared/interfaces/util/FormValidation';
import { scheduleOptions, submitTypeOptions, yesNoOptions } from './Options';
import { descriptionValidation, surveyTextValidation } from './Validators';
import { nameValidation } from './Validators';

interface SurveyFormProps {
  name?: string;
  description?: string;
  surveyText?: string;
  schedule?: string;
  recurEvery?: string;
  startDate?: string;
  endDate?: string;
  skippable?: string;
  submitType?: string;
  forAll?: string;
  scoreSchedule?: string;
  status: 'create' | 'modify';
  onSubmit: (data: SurveyFormData) => void;
  onCancel: () => void;
}

export interface SurveyFormData {
  name: string;
  description: string;
  surveyText: string;
  schedule: string;
  recurEvery: string;
  startDate: string;
  endDate: string;
  skippable: string;
  submitType: string;
  forAll: string;
  scoreSchedule: string;
}

export const SurveyForm = ({ 
  name,
  description,
  surveyText,
  schedule,
  recurEvery,
  startDate,
  endDate,
  skippable,
  submitType,
  forAll,
  scoreSchedule,
  status,
  onSubmit,
  onCancel }: SurveyFormProps) => {

  const { register, getValues, setValue, watch, formState: { errors, isSubmitting }, handleSubmit } = useForm<FieldValues>({
    defaultValues: {
      name,
      description,
      surveyText,
      schedule: schedule || '0',
      recurEvery,
      startDate,
      endDate,
      skippable,
      submitType,
      forAll,
      scoreSchedule: scoreSchedule  || '0'
    }
  });

  const watchScoreSchedule = watch('scoreSchedule');
  const watchSchedule = watch('schedule');

  let recurEveryDisabled = watchScoreSchedule !== '0' || watchSchedule === '0' || watchSchedule === undefined;

  //set submit type to smileys if score schedule is active
  useEffect(() => {
    if (watchScoreSchedule == '1') {
      setValue('submitType', '2');
    }
  }, [watchScoreSchedule]);

  const recurEveryValidation: Validation = {
    required: {
      //only required if recur every is not disabled
      value: !recurEveryDisabled,
      message: 'Recur every is required'
    },
    min: {
      value: 1,
      message: 'Recur every must be a positive number'
    },
    pattern: {
      value: /^[0-9]*$/,
      message: 'Recur every must be a whole number'
    }
  };

  const startDateValidation: Validation = {
    required: {
      value: true,
      message: 'Start date is required'
    },
    validate: {
      startDateBeforeEndDate: () => 
        getValues('endDate') === ''
        || moment(getValues('startDate'), 'YYYY-MM-DD', true)
          .isSameOrBefore(moment(getValues('endDate'), 'YYYY-MM-DD', true))
        || 'Start date must be before end date'
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        label="Name *"
        name="name"
        register={register}
        validation={nameValidation}
        errors={errors.name}
      />
      <TextAreaInput
        label="Description"
        name="description"
        register={register}
        validation={descriptionValidation}
        errors={errors.description}
      />
      <TextAreaInput
        label="Survey text *"
        name="surveyText"
        register={register}
        validation={surveyTextValidation}
        errors={errors.surveyText}
      />
      <DateInput
        label="Start date *"
        name="startDate"
        register={register}
        dateValidation={startDateValidation}
        defaultDate={startDate ? moment(startDate).utc(true) : moment()}
        errors={errors.startDate}
      />
      <DateInput
        label="End date"
        name="endDate"
        register={register}
        dateValidation={{}}
      />
      <FieldSet disabled={status === 'modify' && getValues('submitType') !== '2'}>
        <DropDownInput
          label="Score schedule *"
          name="scoreSchedule"
          register={register}
          options={yesNoOptions}
          validation={{}}
          errors={errors.scoreSchedule}
        />
      </FieldSet>
      <DropDownInput
        label="Schedule *"
        name="schedule"
        register={register}
        options={watchScoreSchedule == '0' ? scheduleOptions : []}
        validation={{}}
        errors={errors.schedule}
      />
      <TextInput
        label="Recur every *"
        name="recurEvery"
        register={register}
        isDisabled={recurEveryDisabled}
        validation={recurEveryValidation}
        errors={errors.recurEvery}
      />
      <DropDownInput
        label="Skippable *"
        name="skippable"
        register={register}
        options={yesNoOptions}
        validation={{}}
        errors={errors.skippable}
      />
      <FieldSet disabled={watchScoreSchedule === '1' || status == 'modify'}>
        <DropDownInput
          label="Submit type *"
          name="submitType"
          register={register}
          options={submitTypeOptions}
          validation={{}}
          errors={errors.submitType}
        />
      </FieldSet>
      <DropDownInput
        label="For all *"
        name="forAll"
        register={register}
        options={yesNoOptions}
        validation={{}}
        errors={errors.forAll}
      />
      <ButtonContainer>
        <SubmitButton
          disabled={isSubmitting}
          text={{ create: 'CREATE SURVEY', modify: 'MODIFY SURVEY'}[status]}
        />
        <CancelButton
          text="CANCEL"
          onClick={() => onCancel()}
        />
      </ButtonContainer>
    </Form>
  );
};

const FieldSet = styled.fieldset`
  padding: 0;
  margin: 0;
  border: 0 none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Form = styled.form`
  max-width: 500px;
  padding: 10px;
  font-family: "Consolas", monospace;
`;