import moment from 'moment';
import React from 'react';
import { BsBriefcaseFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TravelsSelector } from '../../redux/selectors/travels';
import { WeekDataSelector } from '../../redux/selectors/weekData';
import { TravelAccordion } from './TravelAccordion';

interface TravelAccordionControllerProps {
    weekDays: moment.Moment[];
    loading: boolean;
}

export const TravelAccordionController = ({ weekDays, loading }: TravelAccordionControllerProps) => {
  const travels = TravelsSelector(weekDays[0], weekDays[6]);
  const { bookingsEmpty } = WeekDataSelector(weekDays);

  if (!travels) return null;

  const getTotalExpenseAmount = () => {
    let total = 0;
    travels.forEach(travel => {
      travel.expenses.forEach(expense => {
        total += expense.costEUR;
      });
    });
    return total;
  };

  if (bookingsEmpty && loading && travels.length === 0) return null;

  if (travels.length === 0) return (
    <>
      <Title>Travels and expenses</Title>
      <NoTravelsText>No travels created for this week</NoTravelsText>
      <CreateTravelButton to={`/createtravel?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>Create new travel</CreateTravelButton>
    </>
  );
  return (
    <>
      <Title>Travels and expenses<AddHoursLink
        to={`/createtravel?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>
        <BsBriefcaseFill />
                +
      </AddHoursLink></Title>
      {travels.map((travel, i) => (
        <TravelAccordion
          travelId={travel.id}
          invoiced={travel.invoiced}
          key={travel.id}
          project={travel.project}
          color={i % 2 ? 'white' : ''}
          task={travel.task}
          expenses={travel.expenses}
          startTime={moment(travel.startTime)}
          endTime={moment(travel.endTime)}
          purpose={travel.purpose}
          meterAtStart={travel.meterAtStart}
          meterAtEnd={travel.meterAtEnd}
          route={travel.description}
          invoicedFromCustomer={travel.invoicedFromCustomer}
        />
      ))}
      <TotalExpenseAmount>
                Total: {getTotalExpenseAmount().toFixed(2)}€
      </TotalExpenseAmount>
    </>
  );
};

const Title = styled.h2`
    font-weight: 600;
    font-size: 1.3rem;
`;

const NoTravelsText = styled.p`
    margin-bottom: 0;
`;

const CreateTravelButton = styled(Link)`
    display: inline-block;
    color: white;
    background-color: var(--atrMainColor);
    padding: 7px;
    margin-top: 10px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
`;

const TotalExpenseAmount = styled.h2`
    font-weight: 400;
    margin-left: 0;
    margin-top: 0.7rem;
    padding: 0.5rem 1.2rem;
    background-color: #7e7e7e32;
    font-size: 1.2rem;
`;

const AddHoursLink = styled(Link)`
    padding: 3px 6px 0px 6px;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    display: inline-block;
    margin-left: 8px;
`;