import moment from 'moment';
import styled from 'styled-components';
import { BookingsSelector } from '../../redux/selectors/bookings';
import { FlexitimesSelector, FlexitimeBalanceSelector } from '../../redux/selectors/flexitimes';
import Accordion from './Accordion';
import { Loader } from '../common/Loader';

interface AccordionControllerProps {
  weekData: moment.Moment[];
  selectedDate?: moment.Moment;
  loading: boolean;
}

/**
 * Set up accordions for mobile weekview
 */
export const AccordionController = ({ weekData, selectedDate, loading }: AccordionControllerProps) => {

  const bookings = BookingsSelector(weekData[0], weekData[6]);
  const flexitimes = FlexitimesSelector(weekData[0], weekData[6]);
  const flexitimeBalance = FlexitimeBalanceSelector();

  if (bookings?.length === 0 && loading) return <Loader />;

  return (
    <>
      {weekData.map((dayData, i) => (
        <Accordion
          date={dayData}
          color={i % 2 ? 'primary' : 'secondary'}
          bookings={bookings}
          flexitimes={flexitimes}
          key={`${dayData.format('YYYY-MM-DD')} "," ${i}`}
          openDefault={dayData.isSame(selectedDate, 'days')}
        />
      ))}
      <FlexitimeBalance>
        Flexitime balance: <FlexitimeHours>{flexitimeBalance.toFixed(2)}h</FlexitimeHours>
      </FlexitimeBalance>
    </>
  );
};

const FlexitimeBalance = styled.h2`
    font-weight: 400;
    margin-left: 0;
    margin-top: 0.7rem;
    padding: 0.5rem 1.2rem;
    background-color: #7e7e7e32;
    font-size: 1.2rem;
`;

const FlexitimeHours = styled.span`
    @media(min-width: 375px) {
        float: right;
        padding-right: 30px;
    }
`;