import { FieldValues, UseFormRegister, UseFormWatch } from 'react-hook-form';
import styled from 'styled-components';
import { Errors, Validation } from '../../shared/interfaces/util/FormValidation';
import { Option, OptionGroup } from '../../shared/interfaces/util/SelectOption';
import { InputError } from './InputError';
import { Label } from './Label';
import { FaInfoCircle } from 'react-icons/fa';
import { useState } from 'react';
import { ProjectInfoPopup } from '../booking/ProjectInfoPopup';
import { TaskInfoPopup } from '../booking/TaskInfoPopup';

type DropDownInputProps = {
  label: string;
  name: string;
  options: (Option | OptionGroup)[];
  defaultValue?: string;
  register: UseFormRegister<FieldValues>;
  validation?: Validation;
  errors?: Errors;
  isDisabled?: boolean;
  // OPTIONAL POPUP PROPERTIES:
  popupType?: 'project' | 'task';
  watch?: UseFormWatch<FieldValues>;
  taskOptionCount?: number;
}

export const DropDownInput = ({
  popupType,
  label,
  name,
  options,
  defaultValue,
  taskOptionCount,
  watch,
  register,
  validation,
  errors,
  isDisabled
}: DropDownInputProps) => {
  const [projectInfoPopupOpen, setProjectInfoPopupOpen] = useState<boolean>(false);
  const [taskInfoPopupOpen, setTaskInfoPopupOpen] = useState<boolean>(false);

  /**
     * Project and task id variables for popup
     * These can be ignored if not using popup functionality
     */
  let watchProject: null | number = null;
  let watchTask: null | number = null;

  // Get current project and task id from dropdown menu
  if (watch) {
    watchProject = watch('project');
    if (taskOptionCount && taskOptionCount > 0) {
      watchTask = watch('task');
    }
  }

  const openPopup = () => {
    if (popupType === 'project') {
      setProjectInfoPopupOpen(true);
    }
    if (popupType === 'task') {
      setTaskInfoPopupOpen(true);
    }
  };

  const closePopup = () => {
    if (popupType === 'project') {
      setProjectInfoPopupOpen(false);
    }
    if (popupType === 'task') {
      setTaskInfoPopupOpen(false);
    }
  };

  return (
    <Container >
      <Label forInput={name} text={label} />
      <InputContainer itemProp={popupType}>
        <InputField
          id={name}
          defaultValue={defaultValue}
          {...register(name, validation)}
          disabled={options.length === 0 || isDisabled ? true : false}
        >
          {options.map((option: Option | OptionGroup) => {
            //if option group, map the group and the options it contains
            if ('label' in option) {
              return (
                <optgroup key={option.label} label={option.label}>
                  {option.options.map(option => (
                    <option key={option.value} value={option.value}>{option.text}</option>
                  ))}
                </optgroup>
              );
            }
            //if option, render "normally"
            else {
              return (
                <option key={option.value} value={option.value}>{option.text}</option>
              );
            }
          }
          )}
        </InputField>
        {popupType && popupType === 'task' && watchTask !== null && watchTask
          ? <FaInfoCircle
            onClick={() => openPopup()}
            style={{ position: 'relative', width: '30px', top: 7, bottom: 0, right: 35, cursor: 'pointer', color: '#ffffffc5' }} />
          : popupType && popupType === 'project' && watchProject !== null && watchProject && Number(watchProject) !== -1 ?
            <FaInfoCircle
              onClick={() => openPopup()}
              style={{ position: 'relative', width: '30px', top: 7, bottom: 0, right: 35, cursor: 'pointer', color: '#ffffffce' }} />
            : popupType ? <FaInfoCircle
              style={{ position: 'relative', width: '30px', top: 7, bottom: 0, right: 35, cursor: 'not-allowed', color: '#ffffff65' }} />
              : <></>
        }
      </InputContainer>
      {errors && <InputError message={errors.message} />}

      {projectInfoPopupOpen && watchProject !== null &&
        <ProjectInfoPopup closePopup={closePopup} projectId={watchProject} />
      }
      {taskInfoPopupOpen && watchTask && watchProject !== null &&
        <TaskInfoPopup closePopup={closePopup} projectId={watchProject} taskId={watchTask} />
      }
    </Container>
  );
};

const Container = styled.div`
    padding: 5px 0px;
`;

const InputContainer = styled.div`
    display: flex;
    width: ${props => props.itemProp ? 'calc(100% + 30px)' : '100%'};
`;

const InputField = styled.select`
    box-sizing: border-box;
    width: 100%;
    border: 0;
    padding: 8px 7px;
    background-color: var(--inputColor);
    color: white;

    &: disabled {
        background-color: var(--disabledInputColor);
    }
`;