import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bookingSlice } from './slices/bookingSlice';
import { expenseSlice } from './slices/expenseSlice';
import { expenseTypeSlice } from './slices/expenseTypeSlice';
import { flexitimeSlice } from './slices/flexitimeSlice';
import { midweekHolidaysSlice } from './slices/midweekHolidaySlice';
import { projectSlice } from './slices/projectSlice';
import { surveyResponseSlice } from './slices/surveyResponseSlice';
import { surveySlice } from './slices/surveySlice';
import { toastSlice } from './slices/toastSlice';
import { travelSlice } from './slices/travelSlice';
import { userSlice } from './slices/userSlice';

const reducer = combineReducers({
  bookings: bookingSlice.reducer,
  flexitimes: flexitimeSlice.reducer,
  projects: projectSlice.reducer,
  midweekHolidays: midweekHolidaysSlice.reducer,
  users: userSlice.reducer,
  toasts: toastSlice.reducer,
  travels: travelSlice.reducer,
  expenseTypes: expenseTypeSlice.reducer,
  expenses: expenseSlice.reducer,
  surveyResponses: surveyResponseSlice.reducer,
  surveys: surveySlice.reducer
});

const store = configureStore({
  reducer
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;