import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Booking } from '../../shared/interfaces/Booking';
import { Flexitime } from '../../shared/interfaces/Flexitime';
import { BookingCard } from './BookingCard';
import { DeviationCard } from './DeviationCard';
import { FlexitimeCard } from './FlexitimeCard';
import { TotalCard } from './TotalCard';

interface AccordionProps {
  date: moment.Moment;
  color?: string;
  bookings?: Booking[];
  flexitimes?: Flexitime[];
  openDefault?: boolean;
}

/**
 * Accordion-component for bookings in mobile weekview
 */
const Accordion = ({ date, color, bookings, flexitimes, openDefault }: AccordionProps) => {

  const [clicked, setClicked] = useState<boolean>(false);
  // Highlight date in red if booking is incorrect by AIMO and contains no user response
  const [isDateHighlighted, setIsDateHighlighted] = useState<boolean>(false);
  const flexitime = flexitimes?.find(flexitime => flexitime.date === date.format('YYYY-MM-DD'));
  const filteredBookings = bookings?.filter(booking => booking.date === date.format('YYYY-MM-DD'));
  const flexiHours = flexitime ? flexitime.hours : 0;
  const currentDate = moment();

  /**
   * Check if any of the bookings are incorrect (determined by AIMO)
   */
  useEffect(() => {
    filteredBookings?.forEach(booking => {
      if (booking.aiResult !== undefined && !booking.aiResult && booking.userResponse === undefined) {
        setIsDateHighlighted(true);
        return;
      }
    });
  }, [bookings]);

  /**
   * Determine if accordion is closed or open
   * when view is first initialized
   */
  useEffect(() => {
    if (openDefault) {
      setClicked(true);
    } else {
      setClicked(false);
    }
  }, [openDefault]);

  const toggle = () => {
    setClicked(!clicked);
  };

  const getTotalHours = () => {
    let hours = 0;
    filteredBookings?.forEach(booking => {
      hours += booking.hours;
    });
    return hours;
  };

  return (
    <AccordionSection>
      <Container>
        <Wrap data-testid="header-wrapper" bold={currentDate.isSame(date, 'days')} color={isDateHighlighted ? 'highlight' : color} onClick={() => toggle()}>
          <DateDisplay>{date.format('ddd - DD.MM.yyyy')}</DateDisplay>
          <div>
            <HoursDisplay>{getTotalHours().toFixed(2)}h</HoursDisplay>
            <DropdownIcon>{clicked ? '-' : '+'}</DropdownIcon>
          </div>
        </Wrap>
        {clicked ? (
          <Dropdown>
            <Content color={color}>
              <ProjectsTitleContainer>
                <ProjectTitle>
                  Bookings
                </ProjectTitle>
                <HoursTitle>
                  <AddHoursLink to={`/createbooking/${date.format('DD.MM.yyyy')}?fromView=weekview&fromDate=${date.format('DD.MM.yyyy')}`}>
                    +
                  </AddHoursLink>
                </HoursTitle>

              </ProjectsTitleContainer>
              <div style={{ paddingLeft: '15px' }}>
                {filteredBookings?.map(booking => (
                  <BookingCard
                    key={booking.id}
                    id={booking.id}
                    project={booking.project.name}
                    task={booking.task?.name}
                    hours={booking.hours}
                    description={booking.description}
                    userResponse={booking.userResponse}
                    aiResult={booking.aiResult}
                    color={color}
                    fromView="weekview"
                    fromDate={date.format('DD.MM.yyyy')}
                  />
                ))}
              </div>
              <TotalCard
                hours={getTotalHours()}
                difference={0}
                color={color}
                date={date}
              />
              <DeviationCard
                hours={getTotalHours()}
                date={date}
                color={color}
                flexitime={flexiHours}
              />
              <FlexitimeCard
                flexitime={flexiHours}
                color={color}
                date={date}
              />
            </Content>
          </Dropdown>) : null}
      </Container>
    </AccordionSection>
  );
};

const AccordionSection = styled.div`
`;

const Container = styled.div`
  font-size: 1.2rem;
`;

const ProjectsTitleContainer = styled.div`
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 6px;
    margin: 5px;
`;

const Wrap = styled.div<{ bold: boolean }>`
  transition: background-color 0.35s;
  background-color: ${props => props.color === 'highlight' ? 'var(--atrWarnRowColor)' : props.color === 'primary' ? 'var(--bodyColor)' : 'var(--atrSecondaryColor)'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-weight: ${props => props.bold ? '700' : '400'};
  h1 {
    padding: 0 1.2rem;
    font-size: 1.2rem;
  }
  span {
    margin-right: 1.2rem;
  }
`;

const Content = styled.div`
transition: all 0.25s;
  display: flex;
  flex-direction: column;
  padding: 6px;
  background-color: ${props => props.color === 'primary' ? 'var(--bodyColor)' : 'var(--atrSecondaryColor)'};
  color: var(--textColor);
`;

const Dropdown = styled.div`
  background: #ffffff;
  color: #000000;
  width: 100%;
  border-top: 1px solid #000000;
`;

const DateDisplay = styled.h1`
  min-width: 0;
  font-weight: inherit;
`;

const HoursDisplay = styled.span`
`;

const DropdownIcon = styled.span`
`;

const ProjectTitle = styled.div`
    grid-column: 1;
    padding-top: 3px;
`;

const HoursTitle = styled.div`
    grid-column: 2;
    font-weight: bold;
    text-align: right;
`;

const AddHoursLink = styled(Link)`
    padding: 0px 8px 3px 8px;
    margin-right: 5px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
`;

export default Accordion;