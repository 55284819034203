import { MidweekHoliday } from '../shared/interfaces/MidweekHoliday';
import { http } from '../utils/interceptor';
import { handleError } from '../utils/serviceErrorHandler';

export const getMidweekHolidays = (startDate: string, endDate: string) => {
  return http.get<MidweekHoliday[]>(`/midweekholiday?from=${startDate}&to=${endDate}`).then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error('Failed to get midweek holidays');
  });
};