import { Validation } from '../../../shared/interfaces/util/FormValidation';

export const timeValidation: Validation = {
  required: {
    value: true,
    message: 'Time is required'
  }
};

export const projectValidation: Validation = {
  min: {
    value: 0,
    message: 'Please select a real project'
  }
};

export const routeValidation: Validation = {
  required: {
    value: true,
    message: 'Route is required'
  },
  minLength: {
    value: 5,
    message: 'Route must be at least 5 characters long'
  },
  maxLength: {
    value: 256,
    message: 'Route must be less than 256 characters long'
  }
};

export const purposeValidation: Validation = {
  required: {
    value: true,
    message: 'Purpose is required'
  },
  minLength: {
    value: 5,
    message: 'Purpose must be at least 5 characters long'
  },
  maxLength: {
    value: 256,
    message: 'Purpose must be less than 256 characters long'
  }
};
