import { ExpenseType } from '../shared/interfaces/ExpenseType';
import { http } from '../utils/interceptor';
import { handleError } from '../utils/serviceErrorHandler';

export const getExpenseTypes = () => {
  return http.get<ExpenseType[]>('/travel/expensetypes').then((response) => {
    return response;
  }).catch((error) => {
    handleError(error);
    throw new Error('Failed to get travels');
  });
};