import React from 'react';
import styled from 'styled-components';
import { ExpenseType } from '../../shared/interfaces/ExpenseType';
import { BiX } from 'react-icons/bi';

interface ExpenseTypePopupProps {
  expenseType: ExpenseType;
  removePopup: () => void;
}

export const ExpenseTypePopup = ({ expenseType, removePopup }: ExpenseTypePopupProps) => {

  const onClosingClick = (event: React.MouseEvent) => {
    event.preventDefault();
    removePopup();
  };

  return (
    <OuterContainer onClick={(e) => onClosingClick(e)}>
      <Container onClick={(e) => e.stopPropagation()}>
        <TextContainer>
          <TextSegment>
            <Title>
              Expense name
            </Title>
            {expenseType.name}
          </TextSegment>
          <TextSegment>
            <Title>
              Cost
            </Title>
            {expenseType.costEUR}€
          </TextSegment>
          <TextSegment>
            <Title>
              Description
            </Title>
            {expenseType.description}
          </TextSegment>
        </TextContainer>
        <CloseButton onClick={(e) => onClosingClick(e)}>
          <BiX />
        </CloseButton>
      </Container>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0,0.5);
`;

const Container = styled.div`
  position: fixed;
  background-color: var(--bodyColor);
  padding: 10px;
  width: 500px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 30%;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
`;

const TextContainer = styled.div`
  pointer-events: none;
`;

const CloseButton = styled.button`
  color: var(--textColor);
  cursor: pointer;
  height: 1em;
  width: 1em;
  font-size: 40px;
  padding: 0;
  border: none;
  background: none;
`;

const Title = styled.h3`
  margin: 0;
`;

const TextSegment = styled.div`
  padding-bottom: 8px;
`;