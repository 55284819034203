import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Loader } from '../components/common/Loader';
import { TravelByIdSelector } from '../redux/selectors/travels';
import { createToast } from '../redux/slices/toastSlice';
import { fetchTravelById, updateTravel } from '../redux/slices/travelSlice';
import { useAppDispatch } from '../redux/store';
import { useKeycloak } from '@react-keycloak/web';
import { TravelForm, TravelFormData } from './forms/TravelForm/TravelForm';
import { ModifyTravelParam } from '../shared/interfaces/request/ModifyTravelParam';

interface ParamTypes {
  id: string;
}

export const ModifyTravel = () => {

  const { id } = useParams<ParamTypes>();
  const travel = TravelByIdSelector(id);
  const dispatch = useAppDispatch();
  const { initialized } = useKeycloak();
  const location = useLocation();
  const history = useHistory();

  /**
   * Parse return location from url
   */
  let redirectUrl = '/weekview';
  const query = new URLSearchParams(location.search);
  if (query.has('fromView') && query.has('fromDate')) {
    redirectUrl = `/${query.get('fromView')}/${query.get('fromDate')}`;
  }

  /**
   * If keycloak is initialized, fetch travel data
   * from API to redux-state
   */
  useEffect(() => {
    if (!initialized) return;
    dispatch(fetchTravelById(id))
      .then(unwrapResult)
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error fetching travel'));
      });
  }, [id, dispatch, initialized]);

  /**
   * Create ModifyTravelParam-object from form data and
   * send request to modify travel to API trough redux dispatch
   */
  const onSubmit = async (data: TravelFormData) => {

    if(data.expenses == undefined) {
      data.expenses = [];
    }

    const newTravel: ModifyTravelParam = {
      id: parseInt(id),
      startTime: data.startDate + 'T' + data.startTime + ':00',
      endTime: data.endDate + 'T' + data.endTime + ':00',
      projectId: Number(data.project),
      taskId: Number(data.task),
      meterAtStart: Number(data.meterAtStart),
      meterAtEnd: Number(data.meterAtEnd),
      description: data.route,
      purpose: data.purpose,
      invoicedFromCustomer: data.invoiced === '1',
      expenseIds: data.expenses.filter(e => (e !== false)).map((e) => (Number(e))),
      expenseTypeIds: data.expenseTypes.filter(e => (e !== false)).map((e) => (Number(e)))
    };

    await dispatch(updateTravel(newTravel))
      .then(unwrapResult)
      .then(() => {
        history.push(redirectUrl);
        dispatch(createToast('success', 'Success', 'Travel modified successfully'));
      })
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error modifying travel'));
      });
  };

  const onCancel = () => {
    history.push(redirectUrl);
  };

  if (!travel) {
    return (
      <div data-testid="loader"><Loader /></div>
    );
  }

  if (travel.invoiced) {
    return (
      <div>Travels that have been invoiced cannot be edited</div>
    );
  }

  return (
    <TravelForm
      status="modify"
      datetimeStart={moment(travel.startTime)}
      datetimeEnd={moment(travel.endTime)}
      project={travel.project.id.toString()}
      task={travel.task?.id.toString()}
      meterAtStart={travel.meterAtStart}
      meterAtEnd={travel.meterAtEnd}
      isInvoiced={travel.invoicedFromCustomer}
      route={travel.description}
      purpose={travel.purpose}
      expenses={travel.expenses}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};