import { http } from '../utils/interceptor';
import { Booking } from '../shared/interfaces/Booking';
import { CreateBookingParam } from '../shared/interfaces/request/CreateBookingParam';
import { handleError } from '../utils/serviceErrorHandler';
import { CreateRangeBookingParam } from '../shared/interfaces/request/CreateRangeBookingParam';
import { ModifyBookingParam } from '../shared/interfaces/request/ModifyBookingParam';

export const getBookings = (startDate: string, endDate: string) => {
  return http.get<Booking[]>(`/bookings?from=${startDate}&to=${endDate}`).then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error('Failed to get bookings');
  });
};

export const getBooking = (id: number) => {
  return http.get<Booking>(`/bookings/${id}`).then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error(`Failed to get booking with id ${id}`);
  });
};

export const createBooking = (booking: CreateBookingParam) => {
  return http.post<Booking>('/bookings', booking).then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error('Failed to create booking');
  });
};

export const createRangeBookings = (booking: CreateRangeBookingParam, from: string, to: string) => {
  return http.post<Booking>(`/bookings/range?from=${from}&to=${to}`, booking).then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error('Failed to create booking');
  });
};

export const updateBooking = (booking: ModifyBookingParam) => {
  return http.put<Booking>('/bookings', booking).then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error('Failed to create booking');
  });
};