import React, { useEffect } from 'react';
import { BiX } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ProjectByIdSelector } from '../../redux/selectors/projects';

interface ProjectInfoPopupProps {
  closePopup: () => void;
  projectId: number;
}

export const ProjectInfoPopup = ({ closePopup, projectId }: ProjectInfoPopupProps) => {
  const project = ProjectByIdSelector(projectId);

  return (
    <OuterContainer >
      <Container>
        {project ?
          <TextContainer>
            <TextSegment>
              <Title>
                Project
              </Title>
              {project.name}
            </TextSegment>
            <TextSegment>
              <Title>
                Code
              </Title>
              {project.code}
            </TextSegment>
            <TextSegment>
              <Title>
                Description
              </Title>
              {project.description ? project.description : '-'}
            </TextSegment>
            <TextSegment>
              <Title>
                Customer
              </Title>
              {project.customer ? project.customer.name : '-'}
            </TextSegment>
            <TextSegment>
              <Title>
                Manager
              </Title>
              {project.manager ? `${project.manager.firstname} ${project.manager.lastname}` : '-'}
            </TextSegment>
            <TextSegment>
              <Title>
                Tech lead
              </Title>
              {project.techLead ? `${project.techLead.firstname} ${project.techLead.lastname}` : '-'}
            </TextSegment>
            <TextSegment>
              <Title>
                Start date
              </Title>
              {project.startDate ? project.startDate : '-'}
            </TextSegment>
            <TextSegment>
              <Title>
                End date
              </Title>
              {project.endDate ? project.endDate : '-'}
            </TextSegment>
            <TextSegment>
              <Title>
                Jira project
              </Title>

              <JiraLink href={`https://atrdevelopment.atlassian.net/browse/${project.jiraProjectKey}`} >
                {project.jiraProjectKey ? project.jiraProjectKey : '-'}
              </JiraLink>
            </TextSegment>
          </TextContainer>
          :
          <TextContainer>
            <h2>ERROR</h2>
            <h4>Could not get project information</h4>
          </TextContainer>
        }
        <CloseButton onClick={closePopup}>
          <BiX />
        </CloseButton>
      </Container>
    </OuterContainer>
  );
};


const OuterContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
`;

const Container = styled.div`
  position: fixed;
  background-color: var(--bodyColor);
  padding: 10px;
  width: 850px;
  max-width: 82%;
  max-height: 92vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 3%;
  top: 15%;
  
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  @media(max-width: 1100px){
    overflow-y: scroll;
    max-height: 80vh;
  }
`;

const TextContainer = styled.div`
`;

const CloseButton = styled.button`
  color: var(--textColor);
  cursor: pointer;
  height: 1em;
  width: 1em;
  font-size: 40px;
  padding: 0;
  border: none;
  background: none;
`;

const Title = styled.h3`
  margin: 0;
`;

const TextSegment = styled.div`
  padding-bottom: 8px;
`;

const JiraLink = styled.a`
  color: var(--linkColor);
`;