import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// DAILY-SECTION ICONS
import ATRworksLogo from '../../../assets/ATR_works_logo.png';
import AddHoursIcon from '../../../assets/menu_icons/add_hours.png';
import TimesheetIcon from '../../../assets/menu_icons/timesheet.png';
import AddTravelIcon from '../../../assets/menu_icons/add_travels.png';
import ViewSurveyIcon from '../../../assets/menu_icons/view_surveys.png';
import ViewSurveyResponsesIcon from '../../../assets/menu_icons/view_survey_responses.png';
import CreateSurveyIcon from '../../../assets/menu_icons/create_survey.png';

// MANAGE-SECTION ICONS
import UsersIcon from '../../../assets/menu_icons/users.png';
import { FiHelpCircle } from 'react-icons/fi';
import { BiX } from 'react-icons/bi';

import './Sidebar.css';
import { isAuthorized } from '../../../utils/keycloak';

interface SidebarProps {
    sidebarOpen: boolean;
    collapseSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ sidebarOpen, collapseSidebar }) => {

  const closeOnMobile = () => {
    const deviceWidth = window.innerWidth;
    if (deviceWidth < 1000) {
      collapseSidebar();
    }
  };

  return (
    <div className={sidebarOpen ? 'sidebar open' : 'sidebar closed'} id="sidebar-container">
      <CloseSidebar onClick={() => collapseSidebar()} data-testid="close-sidebar">
        <CloseIcon />
      </CloseSidebar>
      <Logo data-testid="logo">
        <LogoImage src={ATRworksLogo} alt="atr-works logo" />
      </Logo>
      <NavigationContainer>
        <h3 style={{ fontWeight: 500 }}>DAILY</h3>
        <Splitter />
        <ListContainer>
          <NavigationItem onClick={() => closeOnMobile()}>
            <NavigationLink to="/createbooking">
              <NavigationImg src={AddHoursIcon} alt="icon" />
              <span>ADD WORK HOURS</span>
            </NavigationLink>
          </NavigationItem>
          <NavigationItem onClick={() => closeOnMobile()}>
            <NavigationLink to="/dayview">
              <NavigationImg src={TimesheetIcon} alt="icon" />
              <span>VIEW DAILY BOOKINGS</span>
            </NavigationLink>
          </NavigationItem>
          <NavigationItem onClick={() => closeOnMobile()}>
            <NavigationLink to="/weekview">
              <NavigationImg src={TimesheetIcon} alt="icon" />
              <span>VIEW WEEKLY BOOKINGS</span>
            </NavigationLink>
          </NavigationItem>
          <NavigationItem onClick={() => closeOnMobile()}>
            <NavigationLink to="/createtravel">
              <NavigationImg src={AddTravelIcon} alt="icon" />
              <span>ADD TRAVEL</span>
            </NavigationLink>
          </NavigationItem>
        </ListContainer>
      </NavigationContainer>

      <NavigationContainer>
        <h3 style={{ fontWeight: 500 }}>OTHER</h3>
        <Splitter />
        <ListContainer>
          <NavigationItem onClick={() => closeOnMobile()}>
            <NavigationLink to="/preferences">
              <NavigationImg src={UsersIcon} alt="icon" />
              <span>PREFERENCES</span>
            </NavigationLink>
          </NavigationItem>
          <NavigationItem onClick={() => closeOnMobile()}>
            <NavigationLink to="/help">
              <FiHelpCircle id="help-icon" />
              <span>HELP</span>
            </NavigationLink>
          </NavigationItem>
        </ListContainer>
      </NavigationContainer>

      { isAuthorized(['Survey Creators']) &&
            <NavigationContainer>
              <h3 style={{ fontWeight: 500 }}>ATR SURVEY</h3>
              <Splitter />
              <ListContainer>
                <NavigationItem onClick={() => closeOnMobile()}>
                  <NavigationLink to="/createsurvey">
                    <NavigationImg src={CreateSurveyIcon} alt="view_survey" />
                    <span>CREATE SURVEY</span>
                  </NavigationLink>
                </NavigationItem>
                <NavigationItem onClick={() => closeOnMobile()}>
                  <NavigationLink to="/viewsurveys">
                    <NavigationImg src={ViewSurveyResponsesIcon} alt="view_survey" />
                    <span>VIEW SURVEYS</span>
                  </NavigationLink>
                </NavigationItem>
                <NavigationItem onClick={() => closeOnMobile()}>
                  <NavigationLink to="/viewsurveyresponses">
                    <NavigationImg src={ViewSurveyResponsesIcon} alt="view_survey" />
                    <span>VIEW SURVEY RESPONSES</span>
                  </NavigationLink>
                </NavigationItem>
              </ListContainer>
            </NavigationContainer>
      }

      <InformationContainer>
        <div id="appname">ATRWorks Extension</div>
        <div id="version">version {process.env.REACT_APP_VERSION}</div>
        <div id="copyright">Copyright 2021 ATR Soft Oy</div>
      </InformationContainer>
    </div>
  );
};

export default Sidebar;

const CloseSidebar = styled.button`
    background: rgba(255, 255, 255, 0.596);
    color: rgba(45, 49, 49, 0.671);
    border-radius: 6px;
    border: none;
    float: right;
    padding: 2px 1px 0px 1px;
    margin: 1rem;
    :hover {
        cursor: pointer;
    }
`;

const CloseIcon = styled(BiX)`
    font-size: 40px;
`;

const Logo = styled.div`
    max-width: 100%;
`;

const LogoImage = styled.img`
max-width: 100%;
`;

const NavigationContainer = styled.div`

    padding: 0 1.5rem;
`;

const Splitter = styled.hr`
    background: black;
    border: 1px solid rgb(0, 0, 0);
`;

const ListContainer = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    li:nth-child(even) {
        margin-left: 1.3rem;
    }
`;

const NavigationItem = styled.li`
    padding: 0.5rem;
    font-size: 12px;
    margin: 0.1rem;
    list-style: none;
    display: block;
`;

const NavigationImg = styled.img`
    width: 50px;
`;

const NavigationLink = styled(Link)`
    color: var(--textColor);
    font-weight: 500;
    :hover {
        color: grey;
    }
    #help-icon {
        background-color: white;
        color: black;
        font-size: 52px;
    }
    display: block;
    text-decoration: none;
    width: 4.5rem;
`;

const InformationContainer = styled.div`
    padding: 1.5rem 1.5rem;
    font-size: 13px;
    color: white;
`;