import React from 'react';
import styled from 'styled-components';

interface BackdropProps {
    collapseSidebar: () => void;
    sidebarOpen: boolean;
}

/**
 * Responsible for disabling and darkening the main content
 * when sidebar is open on mobile resolutions.
 */
const Backdrop: React.FC<BackdropProps> = ({ sidebarOpen, collapseSidebar }) => {
  const deviceWidth = window.innerWidth;

  if (deviceWidth >= 900 || !sidebarOpen) {
    return null;
  }
  return (
    <BackdropContainer onClick={() => collapseSidebar()} className="backdrop" data-testid="backdrop-container" />
  );
};

export default Backdrop;

const BackdropContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.096);
    z-index: 100;
`;