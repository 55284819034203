import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { Errors, Validation } from '../../shared/interfaces/util/FormValidation';
import { InputError } from './InputError';
import { Label } from './Label';

type TimeInputProps = {
  label: string;
  name: string;
  defaultValue?: string;
  register: UseFormRegister<FieldValues>;
  validation?: Validation;
  errors?: Errors;
}

export const TimeInput = ({ label, name, defaultValue, register, validation, errors}: TimeInputProps) => {
  return (
    <Container>
      <Label forInput={name} text={label} />
      <TimeInputField
        type="time"
        id={name}
        defaultValue={defaultValue}
        aria-label={name}
        {...register(name, validation)}
      />        
      {errors && <InputError message={errors.message}/>}
    </Container>
  );
};

const Container = styled.div`
    padding: 5px 0;
    flex: 1;
`;

const TimeInputField = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0;
    padding: 8px 10px;
    background-color: var(--inputColor);
    color: white;

    &::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
`;