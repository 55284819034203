import { Validation } from '../../../shared/interfaces/util/FormValidation';

export const nameValidation: Validation = {
  required: {
    value: true,
    message: 'Name is required'
  },
  maxLength: {
    value: 256,
    message: 'Name must be less than 256 characters long'
  }
};

export const descriptionValidation: Validation = {
  maxLength: {
    value: 1024,
    message: 'Description must be less than 1024 characters long'
  }
};

export const surveyTextValidation: Validation = {
  required: {
    value: true,
    message: 'Survey text is required'
  },
  maxLength: {
    value: 1024,
    message: 'Survey text must be less than 1024 characters long'
  }
};


//startDateValidation and recurEveryValidation in SurveyForm
//because of dependencies on input values