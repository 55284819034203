import { useKeycloak } from '@react-keycloak/web';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { RiSurveyLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../components/common/Loader';
import { SurveyTable } from '../components/survey/SurveyTable';
import { FutureSurveysSelector, OldSurveysSelector, OngoingSurveysSelector } from '../redux/selectors/surveys';
import { fetchSurveys } from '../redux/slices/surveySlice';
import { createToast } from '../redux/slices/toastSlice';
import { useAppDispatch } from '../redux/store';

export const ViewSurveys = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { initialized } = useKeycloak();
  /**
     * Fetch survey data from API 
     */
  useEffect(() => {
    const fetchData = async () => {
      if (!initialized) return;
      setLoading(true);
      await dispatch(fetchSurveys())
        .then(unwrapResult)
        .catch(error => {
          console.log(error);
          dispatch(createToast('error', 'Error', 'Could not connect to API'));
        }),
      setLoading(false);
    };
    fetchData();
  }, [initialized]);

  /**
     * If not keycloak is not initialized, return loader
     */
  if (loading) return (
    <div data-testid="loader">
      <Loader data-testid="loader" />
    </div>
  );
  return (
    <div>
      <Title>View ongoing surveys <CreateSurveyButton to="/createsurvey"><RiSurveyLine />+</CreateSurveyButton></Title>
      <SurveyTable surveySelector={OngoingSurveysSelector} />
      <Title>View future surveys</Title>
      <SurveyTable surveySelector={FutureSurveysSelector} />
      <Title>View old surveys</Title>
      <SurveyTable surveySelector={OldSurveysSelector} />
    </div>
  );
};

const Title = styled.h2`
    margin: 10px 0;
    padding: 0;
    font-weight: 600;
`;

const CreateSurveyButton = styled(Link)`
    padding: 3px 6px 0px 6px;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    display: inline-block;
`;