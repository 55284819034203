import Keycloak from 'keycloak-js';

export const keycloak = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID!
});

export const handleKeycloakEvents = (event: unknown) => {
  if (event === 'onTokenExpired') {
    onTokenExpired();
  }
};

export const isAuthorized = (roles: string[]) => {
  return roles.some(role => {
    const hasRealmRole = keycloak.hasRealmRole(role);
    const hasResourceRole = keycloak.hasResourceRole(role);
    return hasRealmRole || hasResourceRole;
  });
};

const onTokenExpired = () => {
  keycloak.updateToken(30)
    .then((refreshed) => {
      if (refreshed) {
        console.log('token updated');
      }
    })
    .catch((err) => {
      console.log(err);
    });
};