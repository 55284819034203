import { LogError } from '../services/LoggerService';
import { keycloak } from './keycloak';
import { createBrowserHistory } from 'history';

export const handleError = (error: any) => {
  if (error.response.status === 401) {
    createBrowserHistory().push('/');
    window.location.reload();
    keycloak.logout();
  } else {
    LogError(error.response);
  }
};