import { createGlobalStyle } from 'styled-components';

export const lightTemplate = {
  body: 'white',
  textColor: 'black',
  atrMainColor: 'rgb(0, 171, 201)',
  atrWarnRowColor: 'rgb(255, 188, 188)',
  atrSecondaryColor: '#CCEEF4',
  inputColor: '#333',
  disabledInputColor: '#333333c3',
  linkColor: '#5252ff'
};

export const darkTemplate = {
  body: '#1F2425',
  textColor: '#ffffffc5',
  atrMainColor: '#398491',
  atrWarnRowColor: 'rgb(105, 1, 1)',
  atrSecondaryColor: 'rgb(56, 56, 56)',
  inputColor: '#3c3c3c',
  disabledInputColor: '#2c2c2c',
  linkColor: '#0bbed6'
};

export const GlobalStyles = createGlobalStyle`
    :root {
        --atrMainColor: ${(props: any) => props.theme.atrMainColor};
        --textColor: ${(props: any) => props.theme.textColor};
        --bodyColor: ${(props: any) => props.theme.body};
        --atrSecondaryColor: ${(props: any) => props.theme.atrSecondaryColor};
        --atrWarnRowColor: ${(props: any) => props.theme.atrWarnRowColor};
        --inputColor: ${(props: any) => props.theme.inputColor};
        --disabledInputColor: ${(props: any) => props.theme.disabledInputColor};
        --linkColor: ${(props: any) => props.theme.linkColor};
    }
    body {
        transition: background-color 0.35s;
        color: var(--textColor);
        background: ${(props: any) => props.theme.body};
    }
`;