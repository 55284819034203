import { FaWrench } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BiWrench } from 'react-icons/bi';
import moment from 'moment';
import { Deviation } from './Deviation';
import { FlexitimeBalanceSelector } from '../../redux/selectors/flexitimes';
import { WeekDataSelector } from '../../redux/selectors/weekData';
import { Loader } from '../common/Loader';

interface WeekTableProps {
  weekDays: moment.Moment[];
  loading: boolean;
}

export const WeekTable = ({ weekDays, loading }: WeekTableProps) => {

  const flexitimeBalance = FlexitimeBalanceSelector();
  /**
     * Combine booking and flexitime-data into one weekdata-object
     * makes it easier to iterate in jsx
     */
  const weekData = WeekDataSelector(weekDays);
  const currentDate = moment();

  if (weekData.bookingsEmpty && loading) return <Loader />;

  return (
    <Table>
      <thead>
        <TableRow>
          <TableHeader>Date</TableHeader>
          <TableHeader>Project</TableHeader>
          <TableHeader>Task</TableHeader>
          <TableHeader>Description</TableHeader>
          <TableHeader>Hours</TableHeader>
          <TableHeader>Total hours</TableHeader>
          <TableHeader>Deviation</TableHeader>
          <TableHeader>Flextime</TableHeader>
          <TableHeader>Add</TableHeader>
        </TableRow>
      </thead>
      <tbody>
        {weekData.days.map((dayData, dayCount) => (
          dayData.bookings.length > 0 ? dayData.bookings.map((booking, i) => (
            <TableRow color={booking.aiResult !== undefined && !booking.aiResult && booking.userResponse === undefined ? 'warn' : dayCount % 2 === 0 ? 'primary' : ''} key={booking.id}>
              <TableColumn>
                {i === 0 && <DayLink to={`/dayview/${dayData.date.format('DD.MM.yyyy')}`} $bold={currentDate.isSame(dayData.date, 'days') ? true : false}>
                  {dayData.date.format('dddd')} <br /> {dayData.date.format('DD.MM.yyyy')}
                </DayLink>

                }
              </TableColumn>
              <TableColumn>
                <ProjectContainer>
                  <EditHoursLink to={`/modifybooking/${booking.id}?fromView=weekview&fromDate=${dayData.date.format('DD.MM.yyyy')}`}>
                    <FaWrench />
                  </EditHoursLink>
                  {booking.project.name}
                </ProjectContainer>
              </TableColumn>
              <TableColumn>
                {booking.task?.name}
              </TableColumn>
              <TableColumn>
                {booking.description}
              </TableColumn>
              <TableColumn>
                {`${booking.hours.toFixed(2)}h`}
              </TableColumn>
              <TableColumn>
                {i === 0 && `${dayData.totalHours.toFixed(2)}h`}
              </TableColumn>
              <TableColumn>
                {i === 0 && <Deviation
                  date={dayData.date}
                  hours={dayData.totalHours}
                  flexitime={dayData.flexitime}
                />}
              </TableColumn>
              <TableColumn>
                {i === 0 && dayData.flexitime.toFixed(2) + 'h'}
              </TableColumn>
              <TableColumn>
                {i === 0 && <AddHoursLink
                  data-testid={`createbookingbtn-${dayData.date.format('DD.MM.yyyy')}`}
                  to={`/createbooking/${dayData.date.format('DD.MM.yyyy')}?fromView=weekview&fromDate=${dayData.date.format('DD.MM.yyyy')}`}>
                  +
                </AddHoursLink>
                }
              </TableColumn>
            </TableRow>
          )) : <TableRow color={dayCount % 2 === 0 ? 'primary' : ''} key={Math.random()}>
            <TableColumn>
              <DayLink to={`/dayview/${dayData.date.format('DD.MM.yyyy')}`} $bold={currentDate.isSame(dayData.date, 'days') ? true : false}>
                {dayData.date.format('dddd')} <br /> {dayData.date.format('DD.MM.yyyy')}
              </DayLink>
            </TableColumn>
            <TableColumn>
            </TableColumn>
            <TableColumn>
            </TableColumn>
            <TableColumn>
            </TableColumn>
            <TableColumn>
            </TableColumn>
            <TableColumn>
            </TableColumn>
            <TableColumn>
            </TableColumn>
            <TableColumn>
              {dayData.flexitime.toFixed(2)}h
            </TableColumn>
            <TableColumn>
              <AddHoursLink
                data-testid={`createbookingbtn-${dayData.date.format('DD.MM.yyyy')}`}
                to={`/createbooking/${dayData.date.format('DD.MM.yyyy')}?fromView=weekview&fromDate=${dayData.date.format('DD.MM.yyyy')}`}>
                +
              </AddHoursLink>
            </TableColumn>
          </TableRow>
        ))}
        <FlextimeRow>
          <FlextimeColumn>
            {`Flexitime hour balance: ${flexitimeBalance.toFixed(2)}h`}
          </FlextimeColumn>
          <FlextimeColumn>
            <ModifyLink
              data-testid={`modifyflextimebtn-${weekDays[0].startOf('isoWeek').format('YYYY-MM-DD')}`}
              to={`/flextimeview/${weekDays[0].startOf('isoWeek').format('YYYY-MM-DD')}`}
            >
              <BiWrench /> Modify
            </ModifyLink>
          </FlextimeColumn>
          <FlextimeColumn>
          </FlextimeColumn>
          <FlextimeColumn>
          </FlextimeColumn>
          <FlextimeColumn>
          </FlextimeColumn>
          <FlextimeColumn>
            {`${weekData.totalWeeklyHours.toFixed(2)}h`}
          </FlextimeColumn>
          <FlextimeColumn>
          </FlextimeColumn>
          <FlextimeColumn>
            {`${weekData.totalWeeklyFlexitime.toFixed(2)}h`}
          </FlextimeColumn>
          <FlextimeColumn>
          </FlextimeColumn>
        </FlextimeRow>
      </tbody>
    </Table >
  );
};

const Table = styled.table`
    width: 100%;
    font-size: 15px;
    border-spacing: 0;
`;

const TableRow = styled.tr`
    transition: background-color 0.35s;
    background: ${props => props.color === 'primary' ? 'var(--atrSecondaryColor)' : props.color === 'warn' ? 'var(--atrWarnRowColor)' : 'var(--bodyColor)'};
`;

const TableHeader = styled.th`
    border-bottom: 2px solid black;
    text-align: left;
    padding: 0 10px;
`;

const TableColumn = styled.td`
    padding: 4px 4px;
`;

const DayLink = styled(Link) <{ $bold: boolean }>`
    color: var(--textColor);
    font-weight: ${props => props.$bold ? '600' : '400'};
    text-decoration-thickness: 1px;
`;

const ModifyLink = styled(Link)`
    color: var(--linkColor);
    text-decoration-thickness: 1px;
    text-decoration: none;
    padding-left: 8px;
    font-weight: bold;
`;

const AddHoursLink = styled(Link)`
    padding: 0px 8px 3px 8px;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
`;

const EditHoursLink = styled(Link)`
    padding: 3px 4px;
    padding-top: 5px;
    margin: 4px;
    overflow: visible;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    border-radius: 7px;
`;

const FlextimeRow = styled.tr`
    background-color: #7e7e7e32;
`;

const FlextimeColumn = styled.td`
    padding: 0px;
`;

const ProjectContainer = styled.ul`
    padding: 0;
    margin: 0;
`;
