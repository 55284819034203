import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../store';

export const SurveyResponseSelector = () => {
  return useSelector((state: RootState) => state.surveyResponses.surveyResponses);
};

export const OngoingSurveysSelector = () => {
  return useSelector((state: RootState) => state.surveys.filter(survey => (
    moment(survey.startDate).isSameOrBefore(moment(), 'days') &&
    (survey.endDate === undefined || moment(survey.endDate).isSameOrAfter(moment(), 'days'))
  )), shallowEqual);
};

export const SurveyByIdSelector = (id: number) => {
  return useSelector((state: RootState) => {
    if (isNaN(id)) return;
    return state.surveys.find(s => s.id === id);
  });
};

export const OldSurveysSelector = () => {
  return useSelector((state: RootState) => state.surveys.filter(survey => (
    survey.endDate !== null && moment(survey.endDate).isBefore(moment(), 'days')
  )), shallowEqual);
};

export const FutureSurveysSelector = () => {
  return useSelector((state: RootState) => state.surveys.filter(survey => (
    moment(survey.startDate).isAfter(moment(), 'days')
  )), shallowEqual);
};

export const AllSurveysSelector = () => {
  return useSelector((state: RootState) => state.surveys);
};