import axios from 'axios';
import { keycloak } from './keycloak';

/**
 * HTTP-interceptor that is used for every API request
 */
const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:9090/api'
});

http.interceptors.request.use(config => {
  const token = keycloak.token;
  config.headers.Authorization = `Bearer ${token}`;

  return config;
}, err => {
  return Promise.reject(err);
});

export {
  http
};