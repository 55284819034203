import styled from 'styled-components';
import { ToastContent } from '../../../shared/interfaces/util/ToastContent';

export const Toast = ({content, onClick}: {content: ToastContent, onClick: (id: number) => void}) => {
    
  return(
    <ToastStyled
      status={content.status}
      onClick={() => onClick(content.id)}
    >
      <ToastIcon>
        {content.status === 'success' && (
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M5 12l5 5l10 -10" />
          </svg>
        )}
        {content.status === 'error' && (
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 9v2m0 4v.01" />
            <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
          </svg>
        )}
      </ToastIcon>
      <ToastTextContainer>
        <ToastTitle>{content.title}</ToastTitle>
        <ToastDescription>{content.description}</ToastDescription>
        <ToastGuideText>(click to close)</ToastGuideText>
      </ToastTextContainer>
    </ToastStyled>
  );
};

interface ToastStyledProps { status: 'success' | 'error'; }
const ToastStyled = styled.div<ToastStyledProps>`
    display: flex;
    padding: 15px 15px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 4px;
    color: white;

    background-color: ${props => {
    switch (props.status) {
    case 'success':
      return '#7AC470';
    case 'error':
      return '#ED4337';
    }
  }}

`;
const ToastIcon = styled.div`
`;
const ToastTextContainer = styled.div`
    padding-left: 10px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
`;
const ToastTitle = styled.div`
    font-size: 18px;
    width: 90%;
`;
const ToastDescription = styled.div`
    font-size: 14px;
`;
const ToastGuideText = styled.div`
    font-size: 12px;
`;