import React from 'react';
import styled from 'styled-components';


const IconPart = styled.div`
  width: 35px;
  height: 5px;
  background-color: var(--textColor);
  margin: 6px 0;
`;

const HamburgerIcon = () => {
  return (
    <>
      <IconPart />
      <IconPart />
      <IconPart />
    </>
  );
};


export default HamburgerIcon;