/* eslint-disable indent */
import moment from 'moment';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { CreateBookingParam } from '../shared/interfaces/request/CreateBookingParam';
import { CreateRangeBookingParam } from '../shared/interfaces/request/CreateRangeBookingParam';
import { BookingForm, BookingFormData } from './forms/BookingForm/BookingForm';
import { useAppDispatch } from '../redux/store';
import { createBooking, createRangeBookings, fetchBookings } from '../redux/slices/bookingSlice';
import { createToast } from '../redux/slices/toastSlice';
import { CurrentUserSelector } from '../redux/selectors/users';
import { unwrapResult } from '@reduxjs/toolkit';
import { Loader } from '../components/common/Loader';
import { useEffect, useState } from 'react';
import { MidweekHolidayByDaySelector } from '../redux/selectors/midweekHoliday';
import { fetchMidweekHolidays } from '../redux/slices/midweekHolidaySlice';
import { useKeycloak } from '@react-keycloak/web';
import { fetchProjects } from '../redux/slices/projectSlice';
import { ProjectByIdSelector, ProjectsSelector } from '../redux/selectors/projects';

interface ParamTypes {
  date: string;
}

export const CreateBooking = () => {
  const { initialized } = useKeycloak();
  const { date } = useParams<ParamTypes>();
  const midweekHoliday = MidweekHolidayByDaySelector(moment(date, 'DD.MM.YYYY', true).utc(true));
  const projects = ProjectsSelector();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentUser = CurrentUserSelector();

  useEffect(() => {
    const fetchData = async () => {
      if (!initialized) return;

      let parsedDate = moment(date, 'DD.MM.YYYY', true).utc(true);
      if (!date) {
        parsedDate = moment();
      }
      // Fetch data from API to redux state
      dispatch(fetchMidweekHolidays({ startDate: parsedDate, endDate: parsedDate }))
        .then(unwrapResult)
        .catch(err => {
          console.log(err);
          dispatch(createToast('error', 'Error', 'Could not connect to API'));
        });
      dispatch(fetchProjects())
        .then(unwrapResult)
        .catch(err => {
          console.log(err);
          dispatch(createToast('error', 'Error', 'Could not connect to API'));
        });
    };
    fetchData();
  }, [initialized, dispatch]);
  /**
    * Parse date from URL
    */
  let defaultDate: moment.Moment | undefined = undefined;
  if (date) {
    let parsedDate = moment(date, 'DD.MM.YYYY', true).utc(true);
    if (parsedDate.isValid()) {
      defaultDate = parsedDate;
    }
  }

  /**
    * Parse return location from URL
    */
  let redirectUrl = '/weekview';
  const query = new URLSearchParams(location.search);
  if (query.has('fromView') && query.has('fromDate')) {
    redirectUrl = `/${query.get('fromView')}/${query.get('fromDate')}`;
  }

  /**
    * Submit form data.
    */
  const onSubmit = async (data: BookingFormData) => {
    const projectName = projects.find(p => p.id === Number(data.project))?.name;
    // Check and alert if the user creates a midweek holiday booking to a date that has no midweek holidays assigned
    if (projectName === 'ATR Soft Midweek holiday' && !midweekHoliday) {
      const answer = confirm('There are no midweek holidays assigned to this date. Are you sure you want to create a booking?');
      if (!answer) {
        return;
      }
    }
    if (await createRangeBooking(data)) {
      return;
    }
    await createNormalBooking(data);
  };

  /**
    * Cancel-button method
    */
  const onCancel = () => {
    history.push(redirectUrl);
  };

  /**
    * Parse booking data into CreateRangeBookingParam-object
    * and send data to API trough redux dispatch
    * 
    * @param data - booking form data
    * @returns isRangeBooking - boolean to determine if form data submitted is range booking
    */
  const createRangeBooking = async (data: BookingFormData) => {
    let isRangeBooking = false;

    if (data.startDate !== '' && data.startDate && data.endDate !== '' && data.endDate && data.startDate !== data.endDate) {
      isRangeBooking = true;
      const booking: CreateRangeBookingParam = {
        projectId: parseInt(data.project),
        taskId: data.task ? parseInt(data.task) : undefined,
        hours: Number(data.hours.replace(',', '.')),
        description: data.description,
        reference: data.reference
      };
      await dispatch(createRangeBookings({ booking, from: data.startDate, to: data.endDate }))
        .then(unwrapResult)
        .then(res => {
          history.push(redirectUrl);
          dispatch(createToast('success', 'Success', `Bookings created succesfully from ${data.startDate} to ${data.endDate}`));
        })
        .catch((err) => {
          console.log(err);
          dispatch(createToast('error', 'Error', 'Error creating bookings'));
        });
    }
    return isRangeBooking;
  };

  /**
    * Parse booking data into CreateBookingParam-object
    * and send data to API trough redux dispatch
    * 
    * @param data - booking form data
    */
  const createNormalBooking = async (data: BookingFormData) => {
    const booking: CreateBookingParam = {
      date: data.date,
      projectId: parseInt(data.project),
      taskId: data.task ? parseInt(data.task) : undefined,
      hours: Number(data.hours.replace(',', '.')),
      description: data.description,
      reference: data.reference
    };
    await dispatch(createBooking(booking))
      .then(unwrapResult)
      .then(res => {
        history.push(redirectUrl);
        dispatch(createToast('success', 'Success', 'Booking created successfully'));
      })
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error creating booking'));
      });
  };

  if (!currentUser) {
    return (
      <div data-testid="loader"><Loader /></div>
    );
  }

  return (
    <>
      <BookingForm
        status="add"
        date={defaultDate}
        project={currentUser?.default_project?.toString()}
        task={currentUser?.default_task?.toString()}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  );
};