import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { fetchSurvey, updateSurvey } from '../redux/slices/surveySlice';
import { useAppDispatch } from '../redux/store';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory, useParams } from 'react-router-dom';
import { SurveyByIdSelector } from '../redux/selectors/surveys';
import { createToast } from '../redux/slices/toastSlice';
import { SurveyForm, SurveyFormData } from './forms/SurveyForm/SurveyForm';
import { Survey } from '../shared/interfaces/Survey';
import { Loader } from '../components/common/Loader';

interface ParamTypes {
  id: string;
}

export const ModifySurvey = () => {

  const { id } = useParams<ParamTypes>();
  const survey = SurveyByIdSelector(parseInt(id));
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { initialized } = useKeycloak();

  /**
   * Fetch survey data from API
   * if id is valid and keycloak is initialized
   */
  useEffect(() => {
    if (isNaN(parseInt(id)) || !initialized) return;
    dispatch(fetchSurvey(parseInt(id)))
      .then(unwrapResult)
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error fetching survey'));
      });
  }, [initialized]);

  /**
   * Parse form data to Survey-object and send put-request to API
   * to update survey through redux-dispatch
   */
  const onSubmit = async (data : SurveyFormData) => {
    if(!survey) return;

    const newSurvey: Survey = {
      id: survey.id,
      name: data.name,
      description: data.description,
      surveyText: data.surveyText,
      schedule: data.schedule !== undefined ? Number(data.schedule) : 0,
      recurEvery: data.recurEvery !== '' ? Number(data.recurEvery) : 1,
      startDate: data.startDate,
      endDate: data.endDate,
      skippable: data.skippable === '1',
      submitType: survey.submitType,
      forAll: data.forAll === '1',
      scoreSchedule: data.scoreSchedule === '1'
    };

    await dispatch(updateSurvey(newSurvey))
      .then(unwrapResult)
      .then(res => {
        dispatch(createToast('success', 'Success', 'Survey modified successfully'));
        history.push('/viewsurveys');
      })
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error modifying survey'));
      });
  };

  /**
   * Cancel button method
   */
  const onCancel = () => {
    history.push('/viewsurveys');
    console.log('cancel');
  };

  if (isNaN(parseInt(id))) return (<div>Survey id has to be a number</div>);

  if (!survey) {
    return (
      <div data-testid="loader"><Loader /></div>
    );
  }

  return (
    <SurveyForm
      name={survey.name}
      description={survey.description}
      surveyText={survey.surveyText}
      schedule={survey.schedule.toString()}
      recurEvery={survey.recurEvery.toString()}
      startDate={survey.startDate}
      endDate={survey.endDate}
      skippable={survey.skippable ? '1' : '0'}
      submitType={survey.submitType.toString()}
      forAll={survey.forAll ? '1' : '0'}
      scoreSchedule={survey.scoreSchedule ? '1' : '0'}
      status="modify"
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};