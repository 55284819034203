import moment from 'moment';
import styled from 'styled-components';
import { Deviation } from './Deviation';

interface DeviationCardProps {
  hours: number;
  date: moment.Moment;
  color?: string;
  flexitime: number;
}

export const DeviationCard = ({ hours, date, color, flexitime }: DeviationCardProps) => {

  return (
    <Container color={color}>
      <TextContainer>
        Deviation
      </TextContainer>
      <DifferenceErrorContainer>
        <Deviation
          hours={hours}
          flexitime={flexitime}
          date={date}
        />
      </DifferenceErrorContainer>
    </Container>
  );
};

const Container = styled.div`
    transition: background-color 0.25s;
    background-color: ${props => props.color === 'primary' ? 'var(--bodyColor)' : 'var(--atrSecondaryColor)'};
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 6px;
    margin: 5px;
`;

const TextContainer = styled.div`
    grid-column: 1;
    padding-top: 3px;
`;

const DifferenceErrorContainer = styled.div`
    color: ${props => props.color ? props.color : 'black'};
    grid-column: 2;
    text-align: right;
`;