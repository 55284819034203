import { UpdatePreferencesParams } from '../shared/interfaces/request/UpdatePreferencesParams';
import { PreferencesForm, PreferencesFormData } from './forms/PreferenceForm/PreferencesForm';
import { updatePreferences } from '../redux/slices/userSlice';
import { createToast } from '../redux/slices/toastSlice';
import { useAppDispatch } from '../redux/store';
import { CurrentUserSelector } from '../redux/selectors/users';
import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { Loader } from '../components/common/Loader';

export const ModifyPreferences = () => {

  const dispatch = useAppDispatch();
  const currentUser = CurrentUserSelector();

  /**
     * Create UpdatePreferencesParams-object from form data and submit it to API trough
     * redux dispatch
     */
  const onSubmit = async (data: PreferencesFormData) => {
    const params: UpdatePreferencesParams = {
      projectId: data.defaultproject === '' ? null : Number(data.defaultproject),
      taskId: data.defaulttask === '' ? null : Number(data.defaulttask),
    };
    await dispatch(updatePreferences(params))
      .then(unwrapResult)
      .then(res => {
        dispatch(createToast('success', 'Success', 'Preferences updated successfully'));
      })
      .catch(err => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error updating preferences'));
      });
  };

  if (!currentUser) {
    return (
      <div data-testid="loader"><Loader /></div>
    );
  }


  return (
    <PreferencesForm
      project={currentUser.default_project?.toString()}
      task={currentUser.default_task?.toString()}
      onSubmit={onSubmit}
    />
  );
};