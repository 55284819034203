import React, { useEffect, useState } from 'react';
import Backdrop from './Backdrop/Backdrop';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import { useAppDispatch } from '../../redux/store';
import { fetchCurrent } from '../../redux/slices/userSlice';
import { createToast } from '../../redux/slices/toastSlice';
import { useKeycloak } from '@react-keycloak/web';

import './Layout.css';
import { CurrentUserSelector } from '../../redux/selectors/users';
import { unwrapResult } from '@reduxjs/toolkit';
import { ToastController } from './toast/ToastController';
import styled, { ThemeProvider } from 'styled-components';
import { useScript } from '../../utils/useScript';
import { useHistory } from 'react-router-dom';
import { lightTemplate, GlobalStyles, darkTemplate } from '../../utils/themes';

interface LayoutProps {
    children: any;
}

const Layout = ({ children }: LayoutProps) => {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentUser = CurrentUserSelector();
  const [darkTheme, setDarkTheme] = useState(false);

  useScript(!initialized, `Bearer ${keycloak.token}`);

  /**
     * Fetch user details when keycloak is initialized
     */
  useEffect(() => {
    if (!initialized) return;
    dispatch(fetchCurrent())
      .then(unwrapResult)
      .catch(err => {
        dispatch(createToast('error', 'Error', 'Could not connect to API'));
      });
  }, [initialized, dispatch]);

  /**
     * Set sidebar open by default is desktop resolution, load theme
     */
  useEffect(() => {
    // Load theme from localStorage
    if (localStorage.getItem('theme') === 'dark') {
      setDarkTheme(true);
    } else {
      setDarkTheme(false);
    }

    // Device width
    const deviceWidth = window.innerWidth;
    if (deviceWidth > 1000) {
      setSidebarOpen(true);
    }
  }, []);

  const toggleTheme = () => {
    if (!darkTheme) {
      localStorage.setItem('theme', 'dark');
    } else {
      localStorage.removeItem('theme');
    }
    setDarkTheme(!darkTheme);
  };


  const collapseSidebar = () => {
    setSidebarOpen(false);
  };

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const handleLogout = () => {
    history.push('/');
    keycloak.logout();
  };

  return (
    <ThemeProvider theme={darkTheme ? darkTemplate : lightTemplate}>
      <GlobalStyles />
      <Container>
        <Sidebar
          collapseSidebar={collapseSidebar}
          sidebarOpen={sidebarOpen}
          data-testid="sidebar"
        />
        <Backdrop
          collapseSidebar={collapseSidebar}
          sidebarOpen={sidebarOpen}
          data-testid="backdrop"
        />
        <ToastController />
        <div className={sidebarOpen ? 'main-content extend' : 'main-content collapse'}>
          <Header
            darkTheme={darkTheme}
            toggleTheme={toggleTheme}
            username={currentUser?.username}
            logout={handleLogout}
            openSidebar={openSidebar}
            sidebarOpen={sidebarOpen}
            data-testid="header"
          />

          <main>
            <div className="content">
              {children}
            </div>
          </main>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default Layout;

const Container = styled.div`
    min-height: 100vh;
    @media(min-width: 900px){
        display: flex;
    }
`;
