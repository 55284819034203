import React, { useRef } from 'react';
import { BiWrench } from 'react-icons/bi';
import { FaMoneyBillAlt, FaWrench } from 'react-icons/fa';
import styled from 'styled-components';
import CreateBookingImg from '../assets/help/create_booking.png';
import CreateBookingRangeImg from '../assets/help/create_booking_range.png';
import FlexitimeBalanceImg from '../assets/help/flexitime_balance.png';
import FlexitimeExample1 from '../assets/help/flexitime_example1.png';
import FlexitimeExample2 from '../assets/help/flexitime_example2.png';
import CreateTravel from '../assets/help/create_travel.png';
import AddTravel from '../assets/menu_icons/add_travels.png';
import AddExpenseToTravel from '../assets/help/add_expense_to_travel.png';
import Travels from '../assets/help/travels.png';
import TravelDetails from '../assets/help/travel_details.png';
import AddWorkHours from '../assets/menu_icons/add_hours.png';
import { BsBriefcaseFill } from 'react-icons/bs';
import { FaInfoCircle } from 'react-icons/fa';


export const Help = () => {
  const scrollToRef = (ref: React.MutableRefObject<any>) => window.scrollTo(0, ref.current.offsetTop);

  const general = useRef(null);
  const workHours = useRef(null);
  const flexitime = useRef(null);
  const travels = useRef(null);
  const executeScroll = (ref: React.MutableRefObject<any>) => scrollToRef(ref);

  return (
    <HelpContainer>
      <HelpTitle><span>Help</span></HelpTitle>
      <NavigationBox>
        <NavigationList>
          <NavigationListItem onClick={() => executeScroll(general)}>General</NavigationListItem>
          <NavigationListItem onClick={() => executeScroll(workHours)}>Work hours</NavigationListItem>
          <NavigationListItem onClick={() => executeScroll(flexitime)}>Flexitime "slide hours"</NavigationListItem>
          <NavigationListItem onClick={() => executeScroll(travels)}>Travels</NavigationListItem>
        </NavigationList>
      </NavigationBox>

      <SectionTitle ref={general}>General</SectionTitle>
      <SectionSubTitle>ATRWorks Extension</SectionSubTitle>
      <p>ATRWorks Extension is an extension software to an already existing work hour tracker and project
                management application ATRWorks. ATRWorks Extension works on mobile resolutions, allowing users to track work hours from their mobile devices.</p>
      <SectionSubTitle>Functionalities</SectionSubTitle>
      <ul>
        <li>Add/modify work hours</li>
        <li>View work hours and bookings in weekview or dayview</li>
        <li>Add/modify slide hours "flexitime"</li>
        <li>Change user preferences</li>
        <li>Create, view and update travels and travel related expenses</li>
      </ul>

      <SectionTitle ref={workHours}>Work hours</SectionTitle>
      <SectionSubTitle>Add workhours</SectionSubTitle>
      <p>
                User can add work hours for a specific day by creating a new booking.
                A booking can be added by clicking <img src={AddWorkHours} alt="add-workhours" style={{ width: '20px' }} /> ADD WORK HOURS in the sidebar or
        <AddHoursLink>
                    +
        </AddHoursLink>
                -icon in week or day view.
                Booking can be modified by clicking
        <EditHoursLink >
          <FaWrench />
        </EditHoursLink>
                -icon.
      </p>
      <img src={CreateBookingImg} alt="create-booking" style={{ width: '300px' }} />
      <p>
                When adding workhours to the ATR Soft Sick leave, ATR Soft Annual holiday or ATR Soft Unpaid holiday projects, the date selector is replaced by start date
                and end date selectors. A booking is created for all work days in the time period after submitting the form. The maximum size for the time period is two months.
      </p>
      <img src={CreateBookingRangeImg} alt="crete-booking-range" style={{ width: '300px' }} />
      <p>It's recommended to report your work hours every day (especially at the end of the month). Work hours description should be detailed enough
                to specify what has been done. E.g. "Implementing special characters handling".
      </p>
      <SectionSubTitle>Project</SectionSubTitle>
      <p>
                Project-section is divided into two categories:
        <i> Assigned projects</i> are projects assigned to you by your project manager and should include the projects that you are currently working on.
        <i> General projects</i> are visible to all users and should be used for e.g. holidays, sick days.
      </p>

      <SectionTitle ref={flexitime}>Flexitime "slide hours"</SectionTitle>
      <SectionSubTitle>What is flexitime?</SectionSubTitle>
      <p>Flexitime is a voluntary exceed of 7.5h work day. Flexitime balance is your total flexitime amount gathered.
                You can find your flexitime balance in the bottom of the week view: </p>
      <img src={FlexitimeBalanceImg} alt="flexitime-balance" />
      <SectionSubTitle>Modify flexitime</SectionSubTitle>
      <p>Modify flexitime by clicking <ModifyLink><BiWrench /> Modify</ModifyLink>-link in the bottom of week view or <EditButton>Change</EditButton>-button on mobile week view.</p>
      <SectionSmallTitle>Example</SectionSmallTitle>
      <p>User worked 8.5 hours on Monday. User is going to add 1 hour to his flexitime balance: </p>
      <img src={FlexitimeExample1} alt="flexitime-example1" style={{ width: 300 }} />
      <p>User decides to use that one hour on Wednesday: </p>
      <img src={FlexitimeExample2} alt="flexitime-example2" style={{ width: 300 }} />

      <SectionTitle ref={travels}>Travels</SectionTitle>
      <SectionSubTitle>View travel</SectionSubTitle>
      <p>User can view travels and travel related expenses in 'Week view' under bookings.
                A travel can be modified by cliking the <EditHoursLink >
        <FaWrench />
      </EditHoursLink>-link next to project name. Travel or travel-related expenses cannot be modified
                if a travel has already been invoiced. In this case the wrench-icon will not be visible to a user.
      </p>
      <img src={Travels} alt="travels" style={{ width: 300 }} />
      <p>User can see all the travel and expense related information by opening the travel accordion. An expense can be added
                by clicking the
      <EditHoursLink style={{ borderRadius: 0 }} >
        <FaMoneyBillAlt />+
      </EditHoursLink> icon. An expense can be modified by clicking the <EditHoursLink >
        <FaWrench />
      </EditHoursLink>-icon next to expense name. If a travel has already been invoiced, these
                icons will not be visible to a user.
      </p>
      <img src={TravelDetails} alt="travel-details" style={{ width: 300 }} />
      <SectionSubTitle>Create travel</SectionSubTitle>
            A new travel can be created by clicking the <img src={AddTravel} alt="add-travel" style={{ width: 30 }} /> ADD TRAVEL
            -icon from the sidebar or by clicking
      <EditHoursLink style={{ borderRadius: 0 }} >
        <BsBriefcaseFill />+
      </EditHoursLink>-icon. User can see expense-related information like cost per unit (unit can be for example km travelled or a single day depending on the expense type)
            by clicking the <FaInfoCircle />-icon.
      <br />
      <br />
      <img src={CreateTravel} alt="create-travel" style={{ width: 300 }} />
      <SectionSubTitle>Add/modify expense</SectionSubTitle>
      <p>In the example below, some of the fields are greyed out which means they are disabled and cannot be modified.
                'Project' and 'Time period'-fields can only be modified from 'Modify travel'-view. 'Quantity', 'Cost', 'Currency'
                and 'Currency exchange rate'-fields are enabled or disabled depending on the expense type. If quantity is disabled, it will be
                calculated from distance travelled or by the amount of days in the travel period depending on the expense type.
      </p>
      <img src={AddExpenseToTravel} alt="add-expense-to-travel" style={{ width: 300 }} />
    </HelpContainer>
  );
};

const HelpContainer = styled.div`
    padding: 0.3rem 1.3rem;
    @media(min-width: 1000px) {
        width: 55%;
    }
`;

const HelpTitle = styled.h1`
    font-weight: 600;
    span {
        padding-bottom: 6px;
        border-bottom: 5px solid var(--atrMainColor);
    }
`;

const NavigationBox = styled.div`
    background-color: #00abc942;
    margin: 2rem 0;
    padding: 0.3rem 0rem;
    width: 15rem;
`;

const NavigationList = styled.ul`
`;

const NavigationListItem = styled.li`
    cursor: pointer;
    list-style: none;
    color: var(--textColor);
    margin: 0.2rem 0;
`;

const SectionTitle = styled.h1`
    font-weight: 600;
`;

const SectionSubTitle = styled.h2`
    font-weight: 600;
`;

const SectionSmallTitle = styled.h3`
    font-weight: 600;
`;

const AddHoursLink = styled.span`
    display: inline;
    padding: 0px 6px 5px 6px;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: #00ABC9;
    margin: 0 3px;
`;

const EditHoursLink = styled.span`
    display: inline;
    padding: 3px 4px;
    margin: 4px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: #00ABC9;
    border-radius: 7px;
    width: 18px;
`;

const ModifyLink = styled.span`
    color: #5252ff;
    font-weight: bold;
    text-decoration-thickness: 1px;
    text-decoration: none;
    padding-left: 2px;
`;

const EditButton = styled.button`
    padding: 5px 5px;
    margin: 2px;
    border: none;
    font-size: 15px;
    color: white;
    background-color: #00ABC9;
`;