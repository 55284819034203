import { Project } from '../shared/interfaces/Project';
import { http } from '../utils/interceptor';
import { handleError } from '../utils/serviceErrorHandler';

export const getProjects = () => {
  return http.get<Project[]>('/projects').then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error('Failed to get projects');
  });
};