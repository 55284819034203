import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createExpense, getExpenseById, modifyExpense } from '../../services/ExpenseService';
import { CreateExpenseParam } from '../../shared/interfaces/request/CreateExpenseParam';
import { Expense } from '../../shared/interfaces/Expense';

export const fetchExpenseById = createAsyncThunk(
  'expenses/fetchExpenseById',
  async (id: string) => {
    return getExpenseById(id).then(res => res.data);
  }
);

export const addExpense = createAsyncThunk(
  'expenses/addExpense',
  async (expense: CreateExpenseParam) => {
    return createExpense(expense).then(res => res.data);
  }
);

export const changeExpense = createAsyncThunk(
  'expenses/changeExpense',
  async (expense: Expense) => {
    return modifyExpense(expense).then(res => res.data);
  }
);

export const expenseSlice = createSlice({
  name: 'expenses',
  initialState: [] as Expense[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExpenseById.fulfilled, (state, { payload }) => {
      const index = state.findIndex(t => t.id === payload.id);
      index !== -1 ? state[index] = payload : state.push(payload);
    });
    builder.addCase(addExpense.rejected, (state, { payload }) => {
      console.log('addExpense rejected');
    });
    builder.addCase(addExpense.fulfilled, (state, { payload }) => {
      console.log('addExpense fulfilled');
    });
  }
});