import { Validation } from '../../../shared/interfaces/util/FormValidation';

export const projectValidation: Validation = {
  min: {
    value: 0,
    message: 'Please select a real project'
  }
};

export const hoursValidation: Validation = {
  required: {
    value: true,
    message: 'Hours are required'
  },
  min: {
    value: 0,
    message: 'Hours must be 0 or larger'
  },
  max: {
    value: 24,
    message: 'Hours must be 24 or smaller'
  },
  //only accepts numbers and decimals
  pattern: {
    value: /((^|, )(^-?\d*,?\d*|^-?\d*\.?\d*))+$/,
    message: 'Hours must be a number'
  }
};

export const descriptionValidation: Validation = {
  required: {
    value: true,
    message: 'Description is required'
  },
  minLength: {
    value: 10,
    message: 'Description must be at least 10 characters long'
  },
  maxLength: {
    value: 512,
    message: 'Description must be less than 512 characters long'
  }
};

export const referenceValidation: Validation = {
  maxLength: {
    value: 128,
    message: 'Reference must be less than 128 characters long'
  }
};
