import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface DeviationParams {
    hours: number;
    date: moment.Moment;
    flexitime: number;
}
export const Deviation = ({ hours, date, flexitime }: DeviationParams) => {
  const [deviationColor, setDeviationColor] = useState('black');
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    setDifference(hours - (date.isoWeekday() > 5 ? 0 : 7.5) - flexitime);
    if (difference === 0) {
      setDeviationColor('black');
    }
    else {
      setDeviationColor('red');
    }
  }, [difference, date, hours, flexitime]);

  return (
    <DifferenceError color={deviationColor}>
      {difference.toFixed(2)}h
    </DifferenceError>
  );
};

const DifferenceError = styled.span`
    transition: color 0.3s ease;
    color: ${props => props.color === 'red' ? props.color : 'var(--textColor)'};
`;