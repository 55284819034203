import { FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';

import { Label } from './Label';
import { InputError } from './InputError';
import { Validation, Errors } from '../../shared/interfaces/util/FormValidation';

type TextInputProps = {
    label: string;
    name: string;
    defaultValue?: string;
    register: UseFormRegister<FieldValues>;
    validation: Validation;
    inputMode?: 'decimal' | 'text';
    errors: Errors;
    isDisabled?: boolean;
}

export const TextInput = ({ label, name, defaultValue, register, validation, inputMode, errors, isDisabled }: TextInputProps) => {
  return (
    <Container>
      <Label forInput={name} text={label} />
      {isDisabled ?
        <InputFieldDisabled
          readOnly={isDisabled}
          type="text"
          inputMode={inputMode}
          id={name}
          defaultValue={defaultValue}
          {...register(name, validation)}
        />
        :
        <InputField
          type="text"
          inputMode={inputMode}
          id={name}
          defaultValue={defaultValue}
          {...register(name, validation)}
        />
      }
      {errors && <InputError message={errors.message} />}
    </Container>
  );
};

const Container = styled.div`
    padding: 5px 0;
`;

const InputField = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0;
    padding: 8px 10px;
    background-color: var(--inputColor);
    color: white;
`;
const InputFieldDisabled = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0;
    padding: 8px 10px;
    background-color: var(--disabledInputColor);
    color: #ffffff;
`;