import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateSurveyParam } from '../../shared/interfaces/request/CreateSurveyParam';
import { Survey } from '../../shared/interfaces/Survey';
import * as surveyService from '../../services/SurveyService';
import moment from 'moment';

export const fetchSurvey = createAsyncThunk(
  'surveys/fetchSurvey',
  async (id: number) => {
    return surveyService.getSurvey(id)
      .then(res => {
        return res.data;
      });
  }
);

export const createSurvey = createAsyncThunk(
  'surveys/createSurvey',
  async (survey: CreateSurveyParam) => {
    return surveyService.createSurvey(survey)
      .then(res => {
        return res.data;
      });
  }
);

export const fetchSurveys = createAsyncThunk(
  'surveys/fetchSurveys',
  async () => {
    return surveyService.getSurveys()
      .then(res => {
        return res.data;
      });
  }
);

export const updateSurvey = createAsyncThunk(
  'surveys/updateSurvey',
  async (survey: Survey) => {
    return surveyService.updateSurvey(survey)
      .then(res => {
        return res.data;
      });
  }
);

export const surveySlice = createSlice({
  name: 'surveys',
  initialState: [] as Survey[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSurveys.fulfilled, (state, { payload }) => {
      payload.forEach((survey) => {
        const index = state.findIndex(b => b.id === survey.id);
        index !== -1 ? state[index] = survey : state.push(survey);
      });
    });
    builder.addCase(fetchSurvey.fulfilled, (state, { payload }) => {
      const index = state.findIndex(b => b.id === payload.id);
      index !== -1 ? state[index] = payload : state.push(payload);
      return state;
    });

    builder.addCase(createSurvey.fulfilled, (state, { payload }) => {
      state.push(payload);
    });
    builder.addCase(updateSurvey.fulfilled, (state, { payload }) => {
      const index = state.findIndex(b => b.id === payload.id);
      index !== -1 ? state[index] = payload : state.push(payload);
      return state;
    });
  }
});