import { useKeycloak } from '@react-keycloak/web';
import { Component, ComponentType } from 'react';
import { Route } from 'react-router-dom';
import { isAuthorized } from './keycloak';

interface ProtectedRouteProps {
  exact?: boolean;
  path: string;
  component: ComponentType<any>;
  roles: string[];
}

export const ProtectedRoute = ({ exact, path, component, roles }: ProtectedRouteProps) => {

  const keycloak = useKeycloak();

  return (
    <Route
      exact={exact}
      path={path}
      component={isAuthorized(roles) ? component : () => (<div>You do not have the permissions to access this page</div>)}
    />
  );
};