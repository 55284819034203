import React from 'react';
import { RiFileExcel2Line } from 'react-icons/ri';
import styled from 'styled-components';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SurveyResponse } from '../../shared/interfaces/SurveyResponse';

interface ExportToExcelButtonProps {
    surveyResponseData: SurveyResponse[];
}

export const ExportToExcelButton = ({ surveyResponseData }: ExportToExcelButtonProps) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  /**
     * Create and download XLSX worksheet from survey response JSON data
     * @param fileName name of the file
     */
  const exportToCSV = (fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(surveyResponseData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <ExportExcelButton onClick={() => exportToCSV('survey_responses')} >Export to excel <RiFileExcel2Line style={{ fontSize: '17px', verticalAlign: '-5px' }} /></ExportExcelButton>
  );
};

const ExportExcelButton = styled.button`
    padding: 8px 20px;
    margin-right: 6px;
    border: 0;
    font-weight: bold;
    background-color:#2caf2c;
    color: white;
    cursor: pointer;
`;