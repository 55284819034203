import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '../../redux/store';
import { createToast } from '../../redux/slices/toastSlice';
import { Validation } from '../../shared/interfaces/util/FormValidation';
import { SaveFlexitimeParam } from '../../shared/interfaces/request/SaveFlexitimeParam';
import { CancelButton } from '../forms/CancelButton';
import { SubmitButton } from '../forms/SubmitButton';
import { Loader } from '../common/Loader';
import { modifyFlexitime } from '../../redux/slices/flexitimeSlice';
import { useEffect } from 'react';
import { WeekDataSelector } from '../../redux/selectors/weekData';

interface FormData {
    flexitimes: FlexitimeData[];
}

interface FlexitimeData {
    value: string;
}

interface FlexitimeTableProps {
    weekDays: moment.Moment[]
}

export const FlextimeTable = ({ weekDays }: FlexitimeTableProps) => {

  const { register, formState: { errors }, reset, handleSubmit } = useForm();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const weekData = WeekDataSelector(weekDays);
  const currentDate = moment();

  /**
     * Update form default values
     * 
     * More info - https://react-hook-form.com/api/useform/reset
     */
  useEffect(() => {
    const resetFlexitimes = () => {
      reset();
    };
    resetFlexitimes();
  }, [weekData.totalWeeklyFlexitime, reset]);

  const hoursValidation: Validation = {
    required: {
      value: true,
      message: 'Required'
    },
    min: {
      value: -24,
      message: 'Must be -24 or larger'
    },
    max: {
      value: 24,
      message: 'Must be 24 or smaller'
    },
    //only accepts numbers and decimals
    pattern: {
      value: /((^|, )(^-?\d*,?\d*|^-?\d*\.?\d*))+$/,
      message: 'Must be a number'
    }
  };

  /**
     * Submit all flexitime values for specific week
     */
  const onSubmit = async (formData: FormData) => {
    // Iterate trough weekdays
    Promise.all(weekDays.map((dayData, i) => {
      const hours = Number(formData.flexitimes[i].value.replace(',', '.'));
      // Create and submit modified flexitime to API trough dispatch
      const flexiHours: SaveFlexitimeParam = {
        date: dayData.format('YYYY-MM-DD'),
        hours
      };
      return dispatch(modifyFlexitime(flexiHours));
    }))
      .then(res => {
        dispatch(createToast('success', 'Success', 'Flexitimes set successfully'));
        history.push('/weekview');
      })
      .catch(err => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error setting flexitime'));
      });
  };

  if (!weekData) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableHeader>Project</TableHeader>
            <TableHeader>Task</TableHeader>
            <TableHeader>Description</TableHeader>
            <TableHeader>Hours</TableHeader>
            <TableHeader>Total hours</TableHeader>
            <TableHeader>Flexitime</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {weekData.days.map((dayData, dayCount) => (
            dayData.bookings.length > 0 ? dayData.bookings.map((booking, i) => (
              <TableRow key={booking.id} color={dayCount % 2 === 0 ? 'primary' : ''}>
                <TableColumn>
                  {i === 0 && <DayLink to={`/dayview/${dayData.date.format('DD.MM.yyyy')}`} $bold={currentDate.isSame(dayData.date, 'days') ? true : false}>
                    {dayData.date.format('dddd')} <br /> {dayData.date.format('DD.MM.yyyy')}
                  </DayLink>
                  }
                </TableColumn>
                <TableColumn>
                  {booking.project.name}
                </TableColumn>
                <TableColumn>
                  {booking.task?.name}
                </TableColumn>
                <TableColumn>
                  {booking.description}
                </TableColumn>
                <TableColumn>
                  {booking.hours.toFixed(2)}
                </TableColumn>
                <TableColumn>
                  {dayData.totalHours.toFixed(2)}
                </TableColumn>
                <TableColumn>
                  {i === 0 && <Input
                    data-testid={`flexitimes[${dayCount}]`}
                    type="text"
                    defaultValue={dayData.flexitime}
                    {...register(`flexitimes[${dayCount}].value`, hoursValidation)}
                  />}
                  {i === 0 && errors.flexitimes && errors.flexitimes[dayCount] && console.log(errors.flexitimes[dayCount])}
                  {i === 0 && errors.flexitimes && errors.flexitimes[dayCount] && <Error>{errors.flexitimes[dayCount].value.message}</Error>}
                </TableColumn>
              </TableRow>)) : <TableRow color={dayCount % 2 === 0 ? 'primary' : ''} key={dayCount}>
              <TableColumn>
                <DayLink to={`/dayview/${dayData.date.format('DD.MM.yyyy')}`} $bold={currentDate.isSame(dayData.date, 'days') ? true : false}>
                  {dayData.date.format('dddd')} <br /> {dayData.date.format('DD.MM.yyyy')}
                </DayLink>
              </TableColumn>
              <TableColumn>
              </TableColumn>
              <TableColumn>
              </TableColumn>
              <TableColumn>
              </TableColumn>
              <TableColumn>
              </TableColumn>
              <TableColumn>
              </TableColumn>
              <TableColumn>
                <Input
                  data-testid={`flexitimes[${dayCount}]`}
                  type="text"
                  defaultValue={dayData.flexitime}
                  {...register(`flexitimes[${dayCount}].value`, hoursValidation)}
                />
                {errors.flexitimes && errors.flexitimes[dayCount] && console.log(errors.flexitimes[dayCount])}
                {errors.flexitimes && errors.flexitimes[dayCount] && <Error>{errors.flexitimes[dayCount].value.message}</Error>}
              </TableColumn>
            </TableRow>
          ))}

          <SubmitRow>
            <ButtonColumn>
              <SubmitButton text="Submit" />
            </ButtonColumn>
            <ButtonColumn>
              <CancelButton
                text="Cancel"
                onClick={() => { history.goBack(); }}
              />
            </ButtonColumn>
          </SubmitRow>
        </tbody>
      </Table>
    </form>
  );
};


const Table = styled.table`
    width: 100%;
    font-size: 16px;
    border-spacing: 0;
`;

const TableRow = styled.tr`
    background: ${props => props.color === 'primary' ? 'var(--atrSecondaryColor)' : 'var(--bodyColor)'};
`;

const TableHeader = styled.th`
    padding: 8px 5px;
    border-bottom: 2px solid black;
    text-align: left
`;

const TableColumn = styled.td`
    padding: 8px;
`;

const Input = styled.input`
    width: 35px;
    height: 25px;
    border: none;
    padding: 2px;
    margin-top: 1px;
    font-size: 16px;
    color: white;
    background-color: #333;
`;

const SubmitRow = styled.tr`
    width: 60%;
    display:  flex;
`;

const ButtonColumn = styled.td`
    > input {
        width: 100%;
    }
`;

const Error = styled.div`
    font-size: 16px;
    color: #D42E12;
    grid-row: 2;
    grid-column: 2;
`;

const DayLink = styled(Link) <{ $bold: boolean }>`
    color: var(--textColor);
    font-weight: ${props => props.$bold ? '600' : '400'};
    text-decoration-thickness: 1px;
`;