import { ReactKeycloakProvider } from '@react-keycloak/web';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import { CreateBooking } from './pages/CreateBooking';
import { DayView } from './pages/DayView';
import { WeekView } from './pages/WeekView';
import { ModifyBooking } from './pages/ModifyBooking';
import { ModifyPreferences } from './pages/ModifyPreferences';
import { handleKeycloakEvents, keycloak } from './utils/keycloak';
import { FlextimeView } from './pages/FlextimeView';
import { Help } from './pages/Help';
import { AddExpense } from './pages/AddExpense';
import { ModifyExpense } from './pages/ModifyExpense';
import { CreateTravel } from './pages/CreateTravel';
import { TravelDetails } from './pages/TravelDetails';
import { ModifyTravel } from './pages/ModifyTravel';
import React from 'react';
import { ViewSurveyResponses } from './pages/ViewSurveyResponses';
import { CreateSurvey } from './pages/CreateSurvey';
import { ViewSurveys } from './pages/ViewSurveys';
import { ModifySurvey } from './pages/ModifySurvey';
import { ProtectedRoute } from './utils/ProtectedRoute';

const App = () => {

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
      onEvent={(event) => handleKeycloakEvents(event)}
    >
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={WeekView} />
            <Route exact path="/weekview" component={WeekView} />
            <Route exact path="/weekview/:date" component={WeekView} />
            <Route exact path="/dayview" component={DayView} />
            <Route exact path="/dayview/:date" component={DayView} />
            <Route exact path="/createbooking" component={CreateBooking} />
            <Route exact path="/createbooking/:date" component={CreateBooking} />
            <Route exact path="/modifybooking/:id" component={ModifyBooking} />
            <Route exact path="/flextimeview/:week" component={FlextimeView} />
            <Route exact path="/createtravel" component={CreateTravel} />
            <Route exact path="/createtravel/:date" component={CreateTravel} />
            <Route exact path="/modifytravel/:id" component={ModifyTravel} />
            <Route exact path="/help" component={Help} />
            <Route path="/preferences" component={ModifyPreferences} />
            <Route path="/addexpense/:id" component={AddExpense} />
            <Route path="/modifyexpense/:id" component={ModifyExpense} />
            <Route path="/traveldetails/:id" component={TravelDetails} />
            <ProtectedRoute roles={['Survey Creators']} path="/createsurvey" component={CreateSurvey} />
            <ProtectedRoute roles={['Survey Creators']} path="/modifysurvey/:id" component={ModifySurvey} />
            <ProtectedRoute roles={['Survey Creators']} exact path="/viewsurveys" component={ViewSurveys} />
            <ProtectedRoute roles={['Survey Creators']} exact path="/viewsurveyresponses" component={ViewSurveyResponses} />
          </Switch>
        </Layout>
      </Router>
    </ReactKeycloakProvider>
  );
};

export default App;