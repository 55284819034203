import styled from 'styled-components';

type SubmitButtonProps = {
    disabled?: boolean;
    text: string;
}

export const SubmitButton = ({ disabled, text }: SubmitButtonProps) => {
  return(
    <Button
      disabled={disabled}
      type="submit"
      value={text}
    />
  );
};

const Button = styled.input`
    padding: 15px 25px;
    margin: 25px 0;
    border: 0;
    width: 45%;
    font-weight: bold;
    background-color: var(--atrMainColor);
    color: white;
    cursor: pointer;
`;