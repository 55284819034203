/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
import React, { useMemo } from 'react';
import { Column, useTable, useSortBy, usePagination } from 'react-table';
import { SurveyResponseSelector } from '../../redux/selectors/surveys';
import { RiEmotionHappyLine, RiEmotionNormalLine, RiEmotionSadLine, RiEmotionUnhappyLine } from 'react-icons/ri';
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import styled from 'styled-components';
import { ExportToExcelButton } from './ExportToExcelButton';
import { SurveyResponse } from '../../shared/interfaces/SurveyResponse';


export const SurveyResponseTable = () => {
  const surveyResponses = SurveyResponseSelector();

  /**
     * Create columns. UseMemo to prevent unnecessary re-renders
     */
  const columns: Column<any>[] = useMemo(() => [
    {
      Header: 'Id',
      accessor: 'id',
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'Survey id',
      accessor: 'surveyId',
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'Survey name',
      accessor: 'surveyName',
      disableFilters: true,
      sticky: 'left'
    },
    {
      Header: 'First name',
      accessor: 'user.firstname',
      sticky: 'left'
    },
    {
      Header: 'Last name',
      accessor: 'user.lastname',
      sticky: 'left'
    },
    {
      Header: 'Answered',
      accessor: 'responseTime',
      sticky: 'left'
    },
    {
      Header: 'Response',
      accessor: 'responseText',
      sticky: 'left'
    },
    {
      Header: 'Survey type',
      accessor: 'surveyType',
      Cell: ({ row }: any) => {
        const surveyType = row.original.surveyType;
        if (surveyType === 0) {
          return <span>Single submit</span>;
        }
        if (surveyType === 1) {
          return <span>Yes/no</span>;
        }
        if (surveyType === 2) {
          return <span>Smiley</span>;
        }
        return <span>-</span>;
      },
      sticky: 'left'
    },
    {
      Header: 'Submit value',
      // Give a distincit filter value from Submit value-column items
      accessor: (row: any) => {
        return row.surveyType * 10 + row.submitValue;
      },
      // Create
      Cell: ({ row }: any) => {
        const surveyType = row.original.surveyType;
        const submitValue = row.original.submitValue;
        // Single submit survey type
        if (surveyType === 0) {
          return <span>-</span>;
        }
        // Yes/No survey type
        if (surveyType === 1) {
          if (submitValue === 0) {
            return <span>No</span>;
          }
          if (submitValue === 1) {
            return <span>Yes</span>;
          }
        }
        // Smiley survey type
        if (surveyType === 2) {
          if (submitValue === 1) {
            return <UnhappyIcon><RiEmotionSadLine /></UnhappyIcon>;
          }
          if (submitValue === 2) {
            return <SadIcon><RiEmotionUnhappyLine /></SadIcon>;
          }
          if (submitValue === 3) {
            return <NeutralIcon><RiEmotionNormalLine /></NeutralIcon>;
          }
          if (submitValue === 4) {
            return <HappyIcon><RiEmotionHappyLine /></HappyIcon>;
          }
          return <span>-</span>;
        }
        return (
          <span>{submitValue}</span>
        );
      },
      sticky: 'left'
    },
  ]
  , []);

  // Row data
  const data: SurveyResponse[] = useMemo(() => surveyResponses, [surveyResponses]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    pageOptions,
    canNextPage,
    canPreviousPage,
    prepareRow
  } = useTable({
    columns,
    data,
    initialState: { pageSize: 15 }
  }, useSortBy, usePagination
  );

  if (!surveyResponses) return null;

  return (
    <TableContainer>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableHead {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}

                  <SortIcon>{column.isSorted ? (column.isSortedDesc ? <IoIosArrowDown /> : <IoIosArrowUp />) : <IoIosArrowForward />}</SortIcon>
                </TableHead>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <PaginationContainer>
        <div style={{ float: 'left' }}>
          <ExportToExcelButton surveyResponseData={surveyResponses} />
        </div>
        <ArrowButton onClick={() => previousPage()} disabled={!canPreviousPage}><AiOutlineArrowLeft /></ArrowButton>
        <span> Page {state.pageIndex + 1} of {pageOptions.length} </span>
        <ArrowButton onClick={() => nextPage()} disabled={!canNextPage}><AiOutlineArrowRight /></ArrowButton>
      </PaginationContainer>
    </TableContainer>
  );
};

const TableContainer = styled.div`
    width: 95%;
    min-width: 900px;
`;

const Table = styled.table`
    width: 100%;
`;

const TableHead = styled.th`
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--atrMainColor);
    color: white;
    padding: 8px;
`;

const TableRow = styled.tr`
    :nth-child(even){background-color: var(--atrSecondaryColor);}
    :hover {background-color: #cfcccc99;}
`;

const PaginationContainer = styled.div`
    text-align: center;
    margin-right: 100px;
    margin-top: 20px;
`;

const ArrowButton = styled.button`
    background-color: var(--atrMainColor);
    color: white;
    border-radius: 5px;
    font-size: 15px;
`;

// ICONS

const UnhappyIcon = styled.span`
    color: red;
`;
const SadIcon = styled.span`
    color: #ff8800;
`;
const NeutralIcon = styled.span`
    color: #ceaf00;
`;
const HappyIcon = styled.span`
    color: green;
`;

const SortIcon = styled.span`
    color: #ffffff;
    vertical-align: -3px;
    margin-left: 4px;
`;