import { useKeycloak } from '@react-keycloak/web';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Loader } from '../components/common/Loader';
import { SurveyResponseFilter } from '../components/survey/SurveyResponseFilter';
import { SurveyResponseTable } from '../components/survey/SurveyResponseTable';
import { fetchSurveyResponses } from '../redux/slices/surveyResponseSlice';
import { fetchSurveys } from '../redux/slices/surveySlice';
import { createToast } from '../redux/slices/toastSlice';
import { fetchVisibleAndActiveUsers } from '../redux/slices/userSlice';
import { useAppDispatch } from '../redux/store';

export const ViewSurveyResponses = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { initialized } = useKeycloak();

  const surveyId = new URLSearchParams(location.search).get('surveyid');

  useEffect(() => {
    const fetchData = async () => {
      if (!initialized) return;
      setLoading(true);
      await Promise.all([
        dispatch(fetchSurveyResponses({}))
          .then(unwrapResult)
          .catch(error => {
            console.log(error);
            dispatch(createToast('error', 'Error', 'Could not connect to API'));
          }),
        dispatch(fetchVisibleAndActiveUsers())
          .then(unwrapResult)
          .catch(error => {
            console.log(error);
            dispatch(createToast('error', 'Error', 'Could not connect to API'));
          }),
        dispatch(fetchSurveys())
          .then(unwrapResult)
          .catch(error => {
            console.log(error);
            dispatch(createToast('error', 'Error', 'Could not connect to API'));
          })
      ]);
      setLoading(false);
    };
    fetchData();
  }, [initialized]);

  if (loading) return (
    <div data-testid="loader">
      <Loader data-testid="loader" />
    </div>
  );
  return (
    <div>
      <Title>View survey responses</Title>
      <SurveyResponseFilter surveyId={surveyId} />
      <SurveyResponseTable />
    </div>
  );
};

const Title = styled.h2`
    margin: 10px 0;
    padding: 0;
    font-weight: 600;
`;