import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { AllSurveysSelector } from '../../redux/selectors/surveys';
import { VisibleAndActiveUsersSelector } from '../../redux/selectors/users';
import { fetchSurveyResponses } from '../../redux/slices/surveyResponseSlice';
import { createToast } from '../../redux/slices/toastSlice';
import { useAppDispatch } from '../../redux/store';

interface FilterData {
    submitType: string;
    surveyId: string;
    submitValue: string;
    username: string;
    startDate: string;
    endDate: string;
}

interface SurveyResponseFilterProps {
    surveyId: string | null;
}

export const SurveyResponseFilter = ({ surveyId }: SurveyResponseFilterProps) => {
  const users = VisibleAndActiveUsersSelector();
  const surveys = AllSurveysSelector();

  const { register, watch, reset, handleSubmit } = useForm();
  const dispatch = useAppDispatch();
  const [surveyType, setSurveyType] = useState(-1);
  const submitType = watch('submitType');

  /**
     * Needed to update the filters to render the correct submit values
     */
  useEffect(() => {
    setSurveyType(submitType);
  }, [submitType]);

  /**
     * If surveyId is found, fetch all survey responses with survey id
     */
  useEffect(() => {
    if (surveyId !== null) {
      dispatch(fetchSurveyResponses({ surveyId }))
        .then(unwrapResult)
        .catch(error => {
          dispatch(createToast('error', 'Error', 'Could not connect to API'));
        });
    }
  }, [surveyId]);


  /**
     * Submit a survey response request to API with filters
     * @param data - filters
     */
  const onSubmit = (data: FilterData) => {
    dispatch(fetchSurveyResponses({ username: data.username, surveyId: data.surveyId, submitValue: data.submitValue, submitType: data.submitType, startDate: data.startDate, endDate: data.endDate }))
      .then(unwrapResult)
      .catch(error => {
        dispatch(createToast('error', 'Error', 'Could not connect to API'));
      });
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h3 style={{ margin: 0, padding: 0, fontWeight: 600 }}>Filter</h3>
      <hr></hr>
      <table>
        <TableBody>
          <tr>
            <TableHeader>Start date</TableHeader>
            <td data-testid="startdate-input">
              <DateInput type="date"
                defaultValue={undefined}
                {...register('startDate')} />
            </td>
            <TableHeader>End date</TableHeader>
            <td data-testid="enddate-input">
              <DateInput type="date"
                defaultValue={undefined}
                {...register('endDate')} />
            </td>
          </tr>

          <tr>
            <TableHeader>Survey</TableHeader>
            <td data-testid="survey-select"><Select
              defaultValue={surveyId !== null ? Number(surveyId) : -1}
              {...register('surveyId')}>
              <option value={-1}>*</option>
              {surveys.map((survey) =>
                <option key={survey.id} value={survey.id}>{survey.name}</option>
              )}
            </Select></td>
            <TableHeader>Survey type</TableHeader>
            <td data-testid="surveytype-select"><Select
              defaultValue={-1}
              {...register('submitType')}>
              <option value={-1}>*</option>
              <option value={2}>Smiley</option>
              <option value={1}>Yes/No</option>
              <option value={0}>Single submit</option>
            </Select></td>
          </tr>
          <tr>
            <TableHeader>User</TableHeader>
            <td data-testid="user-select"><Select
              defaultValue={-1}
              {...register('username')}>
              <option value={-1}>*</option>
              {users.map((user) =>
                <option key={user.username} value={user.username}>{`${user.firstname} ${user.lastname}`}</option>
              )}
            </Select></td>
            <TableHeader>Submit value</TableHeader>
            <td data-testid="submitvalue-select">
              {Number(surveyType) === 2 ?
                <Select
                  defaultValue={-1}
                  {...register('submitValue')}>
                  <option value={-1}>*</option>
                  <option value={4}>😊 Good</option>
                  <option value={3}>😐 Neutral</option>
                  <option value={2}>😔 Bad</option>
                  <option value={1}>😢 Terrible</option>
                </Select>
                : Number(surveyType) === 1 ?
                  <Select
                    defaultValue={-1}
                    {...register('submitValue')}>
                    <option value={-1}>*</option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </Select>
                  :
                  <Select
                    disabled
                    defaultValue={-1}
                    {...register('submitValue')}>
                    <option value={-1}></option>
                  </Select>
              }
            </td>
          </tr>

        </TableBody>
      </table>
      <SubmitButton type="submit">Search</SubmitButton>
      <ResetButton onClick={(e) => {
        e.preventDefault();
        reset();
      }}>Reset</ResetButton>
    </Form>
  );
};

const Form = styled.form`
    margin-top: 10px;
    width: 700px;
`;
const Select = styled.select`
    box-sizing: border-box;
    border: 0;
    padding: 5px 10px;
    width: 200px;
    background-color: var(--inputColor);
    color: white;
`;
const DateInput = styled.input`
&::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
    box-sizing: border-box;
    border: 0;
    padding: 5px 10px;
    width: 200px;
    background-color: var(--inputColor);
    color: white;
`;

const SubmitButton = styled.button`
    padding: 10px 25px;
    margin: 15px 0;
    margin-right: 6px;
    border: 0;
    font-weight: bold;
    background-color: var(--atrMainColor);
    color: white;
    cursor: pointer;
`;

const ResetButton = styled.button`
    padding: 10px 25px;
    margin: 15px 0;
    margin-right: 6px;
    border: 0;
    font-weight: bold;
    background-color: #7e7e7e7d;
    color: white;
    cursor: pointer;
`;

const TableBody = styled.tbody`
    text-align: left;
    width: 700px;
`;

const TableHeader = styled.th`
    padding: 5px 8px;
    font-weight: 500;
`;