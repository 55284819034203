import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import * as bookingService from '../../services/BookingService';
import { Booking } from '../../shared/interfaces/Booking';
import { CreateBookingParam } from '../../shared/interfaces/request/CreateBookingParam';
import { CreateRangeBookingParam } from '../../shared/interfaces/request/CreateRangeBookingParam';
import { ModifyBookingParam } from '../../shared/interfaces/request/ModifyBookingParam';

export const fetchBookings = createAsyncThunk(
  'bookings/fetchBookings',
  async ({ startDate, endDate }: { startDate: moment.Moment, endDate: moment.Moment }) => {
    return bookingService.getBookings(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .then(res => {
        return res.data;
      });
  }
);

export const fetchBooking = createAsyncThunk(
  'bookings/fetchBooking',
  async (id: number) => {
    return bookingService.getBooking(id)
      .then(res => {
        return res.data;
      });
  }
);

export const createBooking = createAsyncThunk(
  'bookings/createBooking',
  async (booking: CreateBookingParam) => {
    return bookingService.createBooking(booking)
      .then(res => {
        return res.data;
      });
  }
);

export const createRangeBookings = createAsyncThunk(
  'bookings/createRangeBookings',
  async ({ booking, from, to }: { booking: CreateRangeBookingParam, from: string, to: string }) => {
    return bookingService.createRangeBookings(booking, from, to)
      .then(res => {
        return res.data;
      });
  }
);

export const updateBooking = createAsyncThunk(
  'bookings/updateBooking',
  async (booking: ModifyBookingParam) => {
    return bookingService.updateBooking(booking)
      .then(res => {
        return res.data;
      });
  }
);

export const bookingSlice = createSlice({
  name: 'bookings',
  initialState: [] as Booking[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      payload.forEach((booking) => {
        const index = state.findIndex(b => b.id === booking.id);
        index !== -1 ? state[index] = booking : state.push(booking);
      });
    });
    builder.addCase(fetchBookings.rejected, (state, action) => {
      console.log('Could not fetch bookings');
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      const index = state.findIndex(b => b.id === payload.id);
      index !== -1 ? state[index] = payload : state.push(payload);
    });
    builder.addCase(createBooking.fulfilled, (state, { payload }) => {
      state.push(payload);
    });
    builder.addCase(updateBooking.fulfilled, (state, { payload }) => {
      const index = state.findIndex(b => b.id === payload.id);
      index !== -1 ? state[index] = payload : state.push(payload);
      return state;
    });
  }
});