import styled from 'styled-components';
import { useAppDispatch } from '../redux/store';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { createToast } from '../redux/slices/toastSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchTravelById } from '../redux/slices/travelSlice';
import { addExpense } from '../redux/slices/expenseSlice';
import { fetchExpenseTypes } from '../redux/slices/expenseTypeSlice';
import { ExpenseForm, ExpenseFormData } from './forms/ExpenseForm/ExpenseForm';
import { Loader } from '../components/common/Loader';
import { CreateExpenseParam } from '../shared/interfaces/request/CreateExpenseParam';
import { TravelByIdSelector } from '../redux/selectors/travels';

interface ParamTypes {
    id: string;
}

export const AddExpense = () => {

  const { initialized } = useKeycloak();
  const { id } = useParams<ParamTypes>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const travel = TravelByIdSelector(id);

  /**
     * Parse return location from url
     */
  let redirectUrl = '/weekview';
  const query = new URLSearchParams(location.search);
  if (query.has('fromView') && query.has('fromDate')) {
    redirectUrl = `/${query.get('fromView')}/${query.get('fromDate')}`;
  }

  /**
     * If id is valid and keycloak is initialized
     * fetch and set up redux state from API
     */
  useEffect(() => {
    const fetchData = async () => {
      if (!initialized || !id) return;
      setLoading(true);
      await Promise.all([
        // Setup redux state
        dispatch(fetchTravelById(id))
          .then(unwrapResult)
          .catch((err) => {
            console.log(err);
            dispatch(createToast('error', 'Error', 'Travel not found'));
          }),
        dispatch(fetchExpenseTypes())
          .then(unwrapResult)
          .catch((err) => {
            console.log(err);
            dispatch(createToast('error', 'Error', 'No expense types found'));
          })
      ]);
      setLoading(false);
    };
    fetchData();
  }, [id, initialized, dispatch]);

  /**
     * Submit form data - create CreateExpense-object from user form data
     * and send request to API trough redux-dispatch
     */
  const onSubmit = async (data: ExpenseFormData) => {
    if (!travel) {
      dispatch(createToast('error', 'Error', 'Error creating expense'));
      history.push(redirectUrl);
      return;
    }
    const expense: CreateExpenseParam = {
      travelId: travel?.id,
      expenseTypeId: Number(data.expensetype),
      description: data.description,
      cost: Number(data.cost),
      currency: data.currency,
      currencyExRate: Number(data.currencyexchangerate),
      quantity: Number(data.quantity)
    };
    await dispatch(addExpense(expense))
      .then(unwrapResult)
      .then(res => {
        history.push(redirectUrl);
        dispatch(createToast('success', 'Success', 'Expense created succesfully'));
      })
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error creating expense'));
      });
  };

  const onCancel = () => {
    history.push(redirectUrl);
  };


  if (loading || !travel) return (
    <Container data-testid="loader">
      <Loader />
    </Container>
  );
  return (
    <Container>
      <h2>Add expense</h2>
      <ExpenseForm
        travel={travel}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Container>
  );
};

const Container = styled.div`
    max-width: 500px;
    padding: 10px;
    font-family: "Consolas", monospace;
`;