import { Option } from '../../../shared/interfaces/util/SelectOption';

export const yesNoOptions: Option[] = [
  {
    text: 'Yes',
    value: '1'
  }, {
    text: 'No',
    value: '0'
  }
];

export const scheduleOptions: Option[] = [
  {
    text: 'Once',
    value: '0'
  },{
    text: 'Daily',
    value: '1'
  },{
    text: 'Weekly',
    value: '2'
  },{
    text: 'Monthly',
    value: '3'
  },{
    text: 'Yearly',
    value: '4'
  },
];

export const submitTypeOptions: Option[] = [
  {
    text: 'Single submit',
    value: '0'
  },{
    text: 'Yes/No submit',
    value: '1'
  },{
    text: 'Smiley submit',
    value: '2'
  },
];