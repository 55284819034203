import { UpdatePreferencesParams } from '../shared/interfaces/request/UpdatePreferencesParams';
import { User } from '../shared/interfaces/User';
import { http } from '../utils/interceptor';
import { handleError } from '../utils/serviceErrorHandler';

export const getCurrentUser = () => {
  return http.get<User>('/users/current')
    .catch(error => {
      handleError(error);
      throw new Error('Failed to get current user');
    });
};

export const getVisibleAndActiveUsers = () => {
  return http.get<User[]>('/users')
    .catch(error => {
      handleError(error);
      throw new Error('Failed to get current user');
    });
};

export const updatePreferences = (params: UpdatePreferencesParams) => {
  return http.post<User>('/users/current/preferences', params)
    .catch(error => {
      handleError(error);
      throw new Error('Failed to update preferences');
    });
};