import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { DateDisplay } from '../components/common/DateDisplay';
import { BookingCard } from '../components/booking/BookingCard';
import styled from 'styled-components';
import { FlexitimeCard } from '../components/booking/FlexitimeCard';
import { TotalCard } from '../components/booking/TotalCard';
import { FlexitimeAlertController } from '../components/booking/FlexitimeAlertController';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Loader } from '../components/common/Loader';
import { useAppDispatch } from '../redux/store';
import { createToast } from '../redux/slices/toastSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchBookings } from '../redux/slices/bookingSlice';
import { fetchFlexitimes, getFlexitimeBalance } from '../redux/slices/flexitimeSlice';
import { BookingsSelector } from '../redux/selectors/bookings';
import { DailyFlexitimeSelector } from '../redux/selectors/flexitimes';
import { DeviationCard } from '../components/booking/DeviationCard';

interface ParamTypes {
  date: string;
}

export const DayView = () => {

  const { date } = useParams<ParamTypes>();
  const [validDate, setValidDate] = useState<moment.Moment | null>(null);
  const [loaded, setLoaded] = useState(false);
  const bookings = BookingsSelector(validDate, validDate);
  const flexitime = DailyFlexitimeSelector(validDate);
  const bookingTotalHours = bookings?.reduce((t, b) => t + b.hours, 0) || 0;
  const { initialized } = useKeycloak();
  const dispatch = useAppDispatch();

  //parse date from url param
  useEffect(() => {
    let dateParsed;
    if (date == null) {
      dateParsed = moment();
    } else {
      dateParsed = moment(date, 'DD.MM.YYYY', true);
    }
    if (dateParsed.isValid()) {
      setValidDate(dateParsed);
    }
  }, [date]);

  //fetch data from api
  useEffect(() => {
    if (!validDate || !initialized) return;
    setLoaded(false);
    // Setup redux state
    // Load bookings of yesterday and tomorrow as well for more responsive switching between days
    dispatch(fetchBookings({ startDate: validDate.clone().subtract(1, 'days'), endDate: validDate.clone().add(1, 'days') }))
      .then(unwrapResult)
      .then(() => {
        setLoaded(true);
      })
      .catch(err => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Could not connect to API'));
      });
    dispatch(fetchFlexitimes({ startDate: validDate, endDate: validDate }))
      .then(unwrapResult)
      .catch(err => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Could not connect to API'));
      });
    dispatch(getFlexitimeBalance())
      .then(unwrapResult)
      .catch(err => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Could not connect to API'));
      });
  }, [initialized, validDate, dispatch]);

  if (!validDate) {
    return (
      <div>
        Given date is invalid
      </div>
    );
  }

  return (
    <Container>
      <DateDisplay
        date={validDate}
        timeUnit="days"
        onDateChange={(newDate) => { setValidDate(newDate); }}
      />
      <FlexitimeAlertController />
      <AddHoursContainer>
        <AddHoursLink to={`/createbooking/${validDate.format('DD.MM.yyyy')}?fromView=dayview&fromDate=${validDate.format('DD.MM.yyyy')}`}>
          +
        </AddHoursLink>
      </AddHoursContainer>
      {(!bookings || (!loaded && bookings && bookings.length === 0) || flexitime === undefined) && (
        <div data-testid="loader">
          <Loader />
        </div>
      )}
      {bookings && (loaded || bookings.length > 0) && flexitime !== undefined && (
        <>
          {bookings.map((booking, i) => (
            <BookingCard
              key={i}
              id={booking.id}
              aiResult={booking.aiResult}
              userResponse={booking.userResponse}
              project={booking.project.name}
              task={booking.task?.name}
              hours={booking.hours}
              description={booking.description}
              reference={booking.reference}
              fromView="dayview"
              fromDate={validDate.format('DD.MM.yyyy')}
            />
          ))}
          <TotalCard hours={bookingTotalHours} difference={0} />
          <FlexitimeCard
            flexitime={flexitime}
            date={validDate}

          />
          <DeviationCard
            hours={bookingTotalHours}
            date={validDate}
            flexitime={flexitime}
          />
        </>
      )}
    </Container>
  );

};

const Container = styled.div`
    font-family: "Consolas", monospace;
`;

const AddHoursContainer = styled.div`
    text-align: right;
    padding: 5px;
`;

const AddHoursLink = styled(Link)`
    padding: 0px 8px 3px 8px;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    text-align: right;
    color: white;
    background-color: var(--atrMainColor);
`;
