import { useEffect } from 'react';

export const useScript = (loading: boolean, token: string) => {
  useEffect(() => {
    const createScript = () => {
      const script = document.createElement('script');
      script.src = process.env.REACT_APP_SURVEY_SCRIPT_URL!;
      script.async = true;
      const payload = {
        for: 'survey',
        token,
        url: process.env.REACT_APP_API_BASE_URL
      };
      script.onload = () => {
        window.postMessage(payload, window.location.href);
      };
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    };
    if (!loading) {
      createScript();
    }
  }, [loading, token]);
};