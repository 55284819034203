import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IoArrowBackOutline } from 'react-icons/io5';
import { FaWrench } from 'react-icons/fa';
import { unwrapResult } from '@reduxjs/toolkit';
import { createToast } from '../redux/slices/toastSlice';
import { fetchTravelById } from '../redux/slices/travelSlice';
import { useAppDispatch } from '../redux/store';
import { useKeycloak } from '@react-keycloak/web';
import { Loader } from '../components/common/Loader';
import { TravelByIdSelector } from '../redux/selectors/travels';
import moment from 'moment';


interface ParamTypes {
    id: string;
}

export const TravelDetails = () => {

  const { id } = useParams<ParamTypes>();
  const { initialized } = useKeycloak();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const travel = TravelByIdSelector(id);

  let redirectUrl = '/weekview';
  const query = new URLSearchParams(location.search);
  if (query.has('fromView') && query.has('fromDate')) {
    redirectUrl = `/${query.get('fromView')}/${query.get('fromDate')}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!initialized || !id) return;
      setLoading(true);
      await Promise.all([
        // Setup redux state
        dispatch(fetchTravelById(id))
          .then(unwrapResult)
          .catch((err) => {
            console.log(err);
            dispatch(createToast('error', 'Error', 'Travel not found'));
          })
      ]);
      setLoading(false);
    };
    fetchData();
  }, [id, initialized, dispatch]);

  if (loading || !travel) return (
    <Content><Loader /></Content>
  );

  return (
    <Content>
      <BackLink to={redirectUrl}><IconButton><IoArrowBackOutline /></IconButton>  Back to timesheet</BackLink>
      <Table>
        <Caption>
          <CaptionContent>
            <Title>TRAVEL DETAILS</Title>
            {!travel.invoiced && <ModifyLink to={`/modifytravel/${travel.id}`}><IconButton><FaWrench /></IconButton>MODIFY TRAVEL</ModifyLink>}
          </CaptionContent>
        </Caption>
        <TableBody>
          <tr>
            <TableHeader>Start date</TableHeader>
            <td>{moment(travel.startTime).format('DD.MM.YYYY HH:mm')}</td>
            <TableHeader>End date</TableHeader>
            <td>{moment(travel.endTime).format('DD.MM.YYYY HH:mm')}</td>
          </tr>
          <tr>
            <TableHeader>Project</TableHeader>
            <td>{travel.project.name}</td>
            <TableHeader>Task</TableHeader>
            <td>{travel.task?.name}</td>
          </tr>
          <tr>
            <TableHeader>Travel meter at start</TableHeader>
            <td>{travel.meterAtStart}</td>
            <TableHeader>Travel meter at end</TableHeader>
            <td>{travel.meterAtEnd}</td>
          </tr>
          <tr>
            <TableHeader>Invoiced</TableHeader>
            <td>{travel.invoiced ? 'Yes' : 'No'}</td>
            <TableHeader>Is invoiced from customer</TableHeader>
            <td>{travel.invoicedFromCustomer ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <TableHeader>Route</TableHeader>
            <td colSpan={3}>{travel.description}</td>
          </tr>
          <tr>
            <TableHeader>Purpose</TableHeader>
            <td colSpan={3}>{travel.purpose}</td>
          </tr>
        </TableBody>
      </Table>
      <ExpensesTitleContainer>
        <ExpensesTitle>EXPENSES</ExpensesTitle>
        {!travel.invoiced && <AddExpenseLink to={`/addexpense/${travel.id}`}><AddExpenseButton>+</AddExpenseButton>ADD EXPENSE</AddExpenseLink>}
      </ExpensesTitleContainer>
      {travel.expenses.length > 0 &&
                <ExpenseTable>
                  <thead>
                    <tr>
                      <ExpenseHeader>Expense type</ExpenseHeader>
                      <ExpenseHeader>Quantity</ExpenseHeader>
                      <ExpenseHeader>Cost</ExpenseHeader>
                      <ExpenseHeader>Currency</ExpenseHeader>
                      <ExpenseHeader>Currency exchange rate</ExpenseHeader>
                      <ExpenseHeader>Cost in euros</ExpenseHeader>
                      <ExpenseHeader>Description</ExpenseHeader>
                      <ExpenseHeader>Invoiced</ExpenseHeader>
                      <ExpenseHeader>Actions</ExpenseHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {travel.expenses.map((expense, i) => (
                      <ExpenseTableRow key={expense.id} color={i % 2 === 0 ? 'primary' : ''}>
                        <td style={{ padding: '7px' }}>{expense.expenseType.name}</td>
                        <td>{expense.quantity.toFixed(1)}</td>
                        <td>{expense.cost.toFixed(2)}€</td>
                        <td>{expense.currency}</td>
                        <td>{expense.currencyExRate.toFixed(2)}</td>
                        <td>{expense.costEUR.toFixed(2)}€</td>
                        <td>{expense.description}</td>
                        <td>{expense.invoiced ? 'Yes' : 'No'}</td>
                        <td>{!travel.invoiced && <ModifyLink to={`/modifyexpense/${expense.id}`}><IconButton><FaWrench /></IconButton></ModifyLink>}</td>
                      </ExpenseTableRow>
                    ))}
                  </tbody>
                </ExpenseTable>
      }
    </Content>
  );
};

const Content = styled.div`
    margin: 3px;
`;
const Table = styled.table`
    width: 800px;
`;

const ExpenseTable = styled.table`
    width: 100%;
    border-spacing: 0;
`;

const Title = styled.span`
    font-weight: 500;
`;

const TableBody = styled.tbody`
    text-align: left;
`;

const ExpensesTitleContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
`;

const ExpensesTitle = styled.h3`
    font-weight: 500;
    font-size: 16px;
`;

const ExpenseHeader = styled.th`
    text-align: left;
    font-weight: 500;
    border-bottom: 2px solid black;
`;

const ExpenseTableRow = styled.tr`
    transition: background-color 0.35s;
    background: ${props => props.color ? 'var(--bodyColor)' : 'var(--atrSecondaryColor)'};
`;

const TableHeader = styled.th`
    padding: 5px 8px;
    font-weight: 500;
`;

const Caption = styled.caption`
    border-bottom: 2px solid black;
`;

const CaptionContent = styled.div`
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
`;

const BackLink = styled(Link)`
    font-size: 16px;
    display: block;
    margin: 19px 0;
    text-decoration: none;
    color: var(--linkColor);
`;

const ModifyLink = styled(Link)`
    font-size: 16px;
    display: block;
    text-decoration: none;
    color: var(--linkColor);
`;

const AddExpenseLink = styled(Link)`
    font-size: 16px;
    padding: 0;
    margin-top: 10px;
    display: block;
    text-decoration: none;
    color: var(--linkColor);
`;

const AddExpenseButton = styled.div`
    padding: 0px 6px 4px 6px;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    display: inline-block;
    margin-right: 4px;
    border-radius: 7px;
`;

const IconButton = styled.span`
    padding: 3px 4px;
    margin: 2px;
    margin-left: 0;
    overflow: visible;
    font-weight: bold;
    color: white;
    background-color: var(--atrMainColor);
    border-radius: 7px;
`;