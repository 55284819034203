import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMidweekHolidays } from '../../services/MidweekHolidayService';
import { MidweekHoliday } from '../../shared/interfaces/MidweekHoliday';

export const fetchMidweekHolidays = createAsyncThunk(
  'midweekHolidays/fetchMidweekHolidays',
  async ({ startDate, endDate }: { startDate: moment.Moment, endDate: moment.Moment }) => {
    return getMidweekHolidays(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .then(res => {
        return res.data;
      });
  }
);

export const midweekHolidaysSlice = createSlice({
  name: 'midweekHolidays',
  initialState: [] as MidweekHoliday[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMidweekHolidays.fulfilled, (state, { payload }) => {
      payload.forEach(holiday => {
        const index = state.findIndex(h => h.date === holiday.date);
        index !== -1 ? state[index] = holiday : state.push(holiday);
      });
    });
    builder.addCase(fetchMidweekHolidays.rejected, (state, { payload }) => {
      console.log('fetchMidweekHolidays rejected');
    });
  }
});