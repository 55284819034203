import styled from 'styled-components';
import moment from 'moment';
import { TravelsSelector } from '../../redux/selectors/travels';
import { WeekDataSelector } from '../../redux/selectors/weekData';
import { Link } from 'react-router-dom';
import { FaMoneyBillAlt, FaWrench } from 'react-icons/fa';
import { BsBriefcaseFill } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';



interface TravelTableProps {
    weekDays: moment.Moment[];
    loading: boolean;
}

export const TravelTable = ({ weekDays, loading }: TravelTableProps) => {
  const travels = TravelsSelector(weekDays[0], weekDays[6]);
  const { bookingsEmpty } = WeekDataSelector(weekDays);

  if (!travels) return null;

  const getTotalExpenseAmount = () => {
    let total = 0;
    travels.forEach(travel => {
      travel.expenses.forEach(expense => {
        total += expense.costEUR;
      });
    });
    return total;
  };

  if (bookingsEmpty && loading) return null;

  if (travels.length === 0) return (
    <>
      <Title>Travels and expenses</Title>
      <NoTravelsText>No travels created for this week</NoTravelsText>
      <CreateTravelButton to={`/createtravel?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>Create new travel</CreateTravelButton>
    </>
  );

  return (
    <>
      <Title>Travels and expenses <AddExpenseButton
        to={`/createtravel?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>
        <BsBriefcaseFill />
                +
      </AddExpenseButton></Title>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableHeader>Project</TableHeader>
            <TableHeader>Task</TableHeader>
            <TableHeader>Expense</TableHeader>
            {window.innerWidth > 1350 &&
                            <>
                              <TableHeader>Amount</TableHeader>
                              <TableHeader>Quantity</TableHeader>
                            </>
            }
            <TableHeader>Total</TableHeader>
            <TableHeader>Distance</TableHeader>
            <TableHeader>Purpose</TableHeader>
            <TableHeader>Add</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {travels.map((travel, i) => (
            travel.expenses.length > 0 ? travel.expenses.map((expense, j) => (
              <TableRow key={expense.id} color={i % 2 === 0 ? 'primary' : ''}>
                <TableColumnDate>
                  {j === 0 &&
                                        <>
                                          <span style={{ display: 'block' }}>{moment(travel.startTime).format('DD.MM.YYYY HH:mm')}</span>
                                          <span>{moment(travel.endTime).format('DD.MM.YYYY HH:mm')}</span>
                                        </>
                  }
                </TableColumnDate>
                <TableColumn>
                  {j === 0 &&
                                        <>
                                          <EditHoursLink to={`/traveldetails/${travel.id}?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>
                                            <FaSearch />
                                          </EditHoursLink>
                                          {!travel.invoiced &&
                                                <EditHoursLink to={`/modifytravel/${travel.id}?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>
                                                  <FaWrench />
                                                </EditHoursLink>
                                          }
                                          <span>{travel.project.name}</span>
                                        </>
                  }
                </TableColumn>
                <TableColumn>
                  {j === 0 && travel.task && <span>{travel.task.name}</span>}
                </TableColumn>
                <TableColumn>
                  <span>
                    {!travel.invoiced &&
                                            <EditHoursLink to={`/modifyexpense/${expense.id}?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>
                                              <FaWrench />
                                            </EditHoursLink>
                    }
                    {expense.expenseType.name}
                  </span>
                </TableColumn>
                {window.innerWidth > 1350 &&
                                    <>
                                      <TableColumn>
                                        {expense.quantity.toFixed(1)}
                                      </TableColumn>
                                      <TableColumn>
                                        {expense.cost.toFixed(2)}€
                                      </TableColumn>
                                    </>
                }
                <TableColumn>
                  {expense.costEUR.toFixed(2)}€
                </TableColumn>
                <TableColumn>
                  {j === 0 && <span>{(travel.meterAtEnd - travel.meterAtStart).toFixed(2)}km</span>}
                </TableColumn>
                <TableColumnPurpose>
                  {j === 0 && <span>{travel.purpose.length > 35 ? travel.purpose.slice(0, 35) + '...' : travel.purpose}</span>}
                </TableColumnPurpose>
                <TableColumnAddExpense>
                  {j === 0 && !travel.invoiced && <AddExpenseButton
                    to={`/addexpense/${travel.id}?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>
                    <FaMoneyBillAlt />
                                        +
                  </AddExpenseButton>
                  }
                </TableColumnAddExpense>
              </TableRow>
            )) :
              <TableRow key={travel.id} color={i % 2 === 0 ? 'primary' : ''}>
                <TableColumnDate>
                  <>
                    <span style={{ display: 'block' }}>{moment(travel.startTime).format('DD.MM.YYYY HH:mm')}</span>
                    <span>{moment(travel.endTime).format('DD.MM.YYYY HH:mm')}</span>
                  </>
                </TableColumnDate>
                <TableColumn>
                  <>
                    <EditHoursLink to={`/traveldetails/${travel.id}?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>
                      <FaSearch />
                    </EditHoursLink>
                    {!travel.invoiced &&
                                            <EditHoursLink to={`/modifytravel/${travel.id}?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>
                                              <FaWrench />
                                            </EditHoursLink>
                    }
                    {travel.project.name}
                  </>
                </TableColumn>
                <TableColumn>
                  {travel.task && travel.task.name}
                </TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
                {window.innerWidth > 1350 &&
                                    <>
                                      <TableColumn>

                                      </TableColumn>
                                      <TableColumn>

                                      </TableColumn>
                                    </>
                }
                <TableColumn>
                  {(travel.meterAtEnd - travel.meterAtStart).toFixed(2)}km
                </TableColumn>
                <TableColumnPurpose>
                  {travel.purpose.length > 35 ? travel.purpose.slice(0, 35) + '...' : travel.purpose}
                </TableColumnPurpose>
                <TableColumn>
                  {!travel.invoiced &&
                                        <AddExpenseButton
                                          to={`/addexpense/${travel.id}?fromView=weekview&fromDate=${weekDays[0].format('DD.MM.yyyy')}`}>
                                          <FaMoneyBillAlt />
                                            +
                                        </AddExpenseButton>
                  }
                </TableColumn>
              </TableRow>
          ))}
          <TableRow>
            <TableColumnExpense>Total</TableColumnExpense>
            <TableColumnExpense></TableColumnExpense>
            <TableColumnExpense></TableColumnExpense>
            <TableColumnExpense></TableColumnExpense>
            <TableColumnExpense></TableColumnExpense>
            <TableColumnExpense></TableColumnExpense>
            <TableColumnExpense>{getTotalExpenseAmount().toFixed(2)}€</TableColumnExpense>
            <TableColumnExpense></TableColumnExpense>
            <TableColumnExpense></TableColumnExpense>
            <TableColumnExpense></TableColumnExpense>
          </TableRow>
        </tbody>
      </Table >
    </>
  );
};

const Table = styled.table`
    width: 100%;
    font-size: 14px;
    border-spacing: 0;
    @media(max-width: 1250px) {
        font-size: 12px;
    }
`;

const TableRow = styled.tr`
    transition: background-color 0.35s;
    background: ${props => props.color === 'primary' ? 'var(--atrSecondaryColor)' : 'var(--bodyColor)'};
`;

const TableHeader = styled.th`
    border-bottom: 2px solid black;
    text-align: left;
    padding: 0 10px;
`;

const TableColumn = styled.td`
    padding: 5px 0;
`;

const TableColumnPurpose = styled.td`
    max-width: 150px;
`;

const TableColumnExpense = styled.td`
    padding: 0 4px;
    background-color: #7e7e7e32;
`;

const TableColumnDate = styled.td`
    padding: 0 4px;
    min-width: 140px;
    max-width: 150px;
`;

const TableColumnAddExpense = styled.td`
    padding: 4px;
    min-width: 52px;
`;

const Title = styled.h2`
    font-weight: 600;
    font-size: 1.3rem;
`;

const NoTravelsText = styled.p`
    margin-bottom: 0;
`;

const EditHoursLink = styled(Link)`
    padding: 3px 4px;
    padding-top: 5px;
    margin: 4px;
    margin-left: 0;
    overflow: visible;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    border-radius: 7px;
`;

const CreateTravelButton = styled(Link)`
    display: inline-block;
    color: white;
    background-color: var(--atrMainColor);
    padding: 7px;
    margin-top: 10px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
`;

const AddExpenseButton = styled(Link)`
    padding: 3px 6px 0px 6px;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    display: inline-block;
`;