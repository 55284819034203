import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import * as flexitimeService from '../../services/FlexitimeService';
import { Flexitime } from '../../shared/interfaces/Flexitime';
import { SaveFlexitimeParam } from '../../shared/interfaces/request/SaveFlexitimeParam';

interface InitialState {
    flexitimes: Flexitime[];
    flexitimeBalance: number;
}

export const fetchFlexitimes = createAsyncThunk(
  'flexitimes/fetchFlexitimes',
  async ({ startDate, endDate }: { startDate: moment.Moment, endDate: moment.Moment }) => {
    return flexitimeService.getFlexitimes(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .then(res => {
        const flexitimes: Flexitime[] = res.data;
        return flexitimes;
      });
  }
);

export const getFlexitimeBalance = createAsyncThunk(
  'flexitimes/getFlexitimeBalance',
  async () => {
    return flexitimeService.getFlexitimeTotal().then(res => {
      return res.data;
    });
  }
);

export const modifyFlexitime = createAsyncThunk(
  'flexitimes/modifyFlexitime',
  async (flexitime: SaveFlexitimeParam) => {
    return flexitimeService.saveFlexitime(flexitime)
      .then(res => {
        return flexitime;
      });
  }
);

export const flexitimeSlice = createSlice({
  name: 'flexitimes',
  initialState: {
    flexitimes: [],
    flexitimeBalance: 0
  } as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFlexitimes.fulfilled, (state, { payload }) => {
      payload.forEach((flexitime) => {
        const index = state.flexitimes.findIndex(f => f.date === flexitime.date);
        index !== -1 ? state.flexitimes[index] = flexitime : state.flexitimes.push(flexitime);
      });
    });
    builder.addCase(fetchFlexitimes.rejected, (state, action) => {
      console.log('not fullfilled');
    });
    builder.addCase(getFlexitimeBalance.fulfilled, (state, { payload }) => {
      state.flexitimeBalance = payload;
    });
    builder.addCase(modifyFlexitime.fulfilled, (state, { payload }) => {
      const index = state.flexitimes.findIndex(f => f.date === payload.date);
      if (index !== -1) {
        state.flexitimes[index].hours = Number(payload.hours);
      }
    });
  }
});
