import styled from 'styled-components';

type CancelButtonProps = {
    text: string;
    onClick: () => void;
}

export const CancelButton = ({ text, onClick }: CancelButtonProps) => {
  return (
    <Button
      type="reset"
      value={text}
      onClick={() => onClick()}
    />
  );
};

const Button = styled.input`
    padding: 15px 25px;
    margin: 25px 0;
    border: 0;
    width: 45%;
    font-weight: bold;
    background-color: #7e7e7e7d;
    color: white;
    cursor: pointer;
`;
