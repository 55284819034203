import styled from 'styled-components';
import { FlexitimeBalanceSelector } from '../../redux/selectors/flexitimes';
import { CurrentUserSelector } from '../../redux/selectors/users';


export const FlexitimeAlertController = () => {
  
  const flexitimeBalance = FlexitimeBalanceSelector();
  const weeklyHours = CurrentUserSelector()?.weeklyHours;

  if (weeklyHours == undefined
    || weeklyHours == 0
    || Math.abs(flexitimeBalance) < weeklyHours / 2) {
    return(<></>);
  }

  return (
    <>
      {Math.abs(flexitimeBalance) > weeklyHours ?
        <ContainerRed>
        YOU HAVE EXCEEDED THE FLEXI TIME LIMIT
        ({flexitimeBalance > 0 ? '' : '-'}{weeklyHours}).
        CONTACT YOUR FOREPERSON TO RESOLVE THE ISSUE.
        </ContainerRed>
        :
        <ContainerYellow>
        YOUR FLEXI HOURS ARE APPROACHING THE LIMIT
        ({flexitimeBalance > 0 ? '' : '-'}{weeklyHours}).
        </ContainerYellow>}
    </>
  );
};

const Container = styled.div`
  padding: 10px;
  margin: 0;
  font-weight: 700;
`;

const ContainerRed = styled(Container)`
  background: palevioletred;
`;

const ContainerYellow = styled(Container)`
  background: #f4d13676;
`;