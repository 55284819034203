import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch } from 'react-redux';
import { fetchBookings } from '../redux/slices/bookingSlice';
import { fetchFlexitimes } from '../redux/slices/flexitimeSlice';
import { FlextimeTable } from '../components/booking/FlextimeTable';
import { Loader } from '../components/common/Loader';

export const FlextimeView = (props: any) => {

  const { initialized } = useKeycloak();
  const [weekDays, setWeekDays] = useState<moment.Moment[]>([]);
  const [weekStart, setWeekStart] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  /**
     * Setup flexitime data to redux state and initialize weekdays
     * if keycloak is initialized and URL week-param is found
     */
  useEffect(() => {
    if (!initialized || !props.match.params.week) return;

    let weekDaysCopy: moment.Moment[] = [];
    // Initialize week days
    const weekStartTmp = moment(props.match.params.week, 'YYYY-MM-DD').clone().startOf('isoWeek');
    for (let i = 0; i < 7; i++) {
      weekDaysCopy.push(moment(weekStartTmp).add(i, 'days'));
    }

    // Setup flexitime data to redux state
    dispatch(fetchBookings({ startDate: weekStartTmp, endDate: weekStartTmp.clone().add(6, 'days') }));
    dispatch(fetchFlexitimes({ startDate: weekStartTmp, endDate: weekStartTmp.clone().add(6, 'days') }));

    setWeekStart(weekStartTmp.format('YYYY-MM-DD'));
    setWeekDays(weekDaysCopy);
    setLoading(true);

  }, [initialized, weekStart, props.match.params.week, dispatch]);

  if (!loading) return <Loader />;

  return (
    <Container>
      <WeekTitle>Modify flextime</WeekTitle>
      <WeekTitle>Week {weekDays[0].week()}</WeekTitle>
      <FlextimeTable weekDays={weekDays} />
    </Container>
  );
};

const Container = styled.div`
    margin: 2px;
    font-family: "Consolas", monospace;
`;

const WeekTitle = styled.h2`
    text-align: center;
    margin: 5px;
    font-size: 24px;
    font-weight: bold;
`;