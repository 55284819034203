import styled from 'styled-components';
import { useAppDispatch } from '../redux/store';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { createToast } from '../redux/slices/toastSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchTravelById } from '../redux/slices/travelSlice';
import { changeExpense, fetchExpenseById } from '../redux/slices/expenseSlice';
import { fetchExpenseTypes } from '../redux/slices/expenseTypeSlice';
import { Loader } from '../components/common/Loader';
import { Expense } from '../shared/interfaces/Expense';
import { ExpenseForm, ExpenseFormData } from './forms/ExpenseForm/ExpenseForm';
import { ExpenseByIdSelector } from '../redux/selectors/expense';
import { TravelByIdSelector } from '../redux/selectors/travels';
import { ExpenseType } from '../shared/interfaces/ExpenseType';
import moment from 'moment';

interface ParamTypes {
    id: string;
}
export const ModifyExpense = () => {

  const { initialized } = useKeycloak();
  const { id } = useParams<ParamTypes>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const expense = ExpenseByIdSelector(id);
  const travel = TravelByIdSelector(expense?.travelId.toString());

  /**
     * Parse return location from url
     */
  let redirectUrl = '/weekview';
  const query = new URLSearchParams(location.search);
  if (query.has('fromView') && query.has('fromDate')) {
    redirectUrl = `/${query.get('fromView')}/${query.get('fromDate')}`;
  }

  /**
     * If id is valid and keycloak is initialized
     * fetch and set up redux state from API
     */
  useEffect(() => {
    const fetchData = async () => {
      if (!initialized || !id) return;
      setLoading(true);
      await Promise.all([
        // Setup redux state
        dispatch(fetchExpenseById(id))
          .then(unwrapResult)
          .then((expense: Expense) => {
            dispatch(fetchTravelById(expense.travelId.toString()))
              .then(unwrapResult)
              .catch((err) => {
                console.log(err);
                dispatch(createToast('error', 'Error', 'No expense types found'));
              });
          })
          .catch((err) => {
            console.log(err);
            dispatch(createToast('error', 'Error', 'Travel not found'));
          }),
        dispatch(fetchExpenseTypes())
          .then(unwrapResult)
          .catch((err) => {
            console.log(err);
            dispatch(createToast('error', 'Error', 'No expense types found'));
          })
      ]);
      setLoading(false);
    };
    fetchData();
  }, [id, initialized, dispatch]);

  /**
     * Create Expense-object and send request to modify expense to API trough redux-dispatch
     */
  const onSubmit = async (data: ExpenseFormData, expenseType: ExpenseType | undefined) => {
    if (!expenseType || !travel || !expense) {
      dispatch(createToast('error', 'Error', 'Error changing expense'));
      return;
    }

    const modifiedExpense: Expense = {
      id: Number(id),
      bookingdate: moment(travel.startTime).format('YYYY-MM-DD'),
      expenseType,
      costEUR: Number(data.cost) * Number(data.quantity) * Number(data.currencyexchangerate),
      travelId: travel.id,
      description: data.description,
      cost: Number(data.cost),
      invoiced: expense.invoiced,
      currency: data.currency,
      currencyExRate: Number(data.currencyexchangerate),
      quantity: Number(data.quantity)
    };
    await dispatch(changeExpense(modifiedExpense))
      .then(unwrapResult)
      .then(res => {
        history.push(redirectUrl);
        dispatch(createToast('success', 'Success', 'Expense changed succesfully'));
      })
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error changing expense'));
      });
  };

  const onCancel = () => {
    history.push(redirectUrl);
  };

  if (loading || !travel || !expense) return (
    <Container data-testid="loader">
      <Loader />
    </Container>
  );
  return (
    <Container>
      <h2>Modify expense</h2>
      <ExpenseForm
        travel={travel}
        expense={expense}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Container>
  );
};

const Container = styled.div`
    max-width: 500px;
    padding: 10px;
    font-family: "Consolas", monospace;
`;