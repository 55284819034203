import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastContent } from '../../shared/interfaces/util/ToastContent';

let id = 0;

export const createToast = createAction('toasts/createToast', (status: 'success' | 'error', title: string, description: string) => {
  return {
    payload: {
      status,
      title,
      description,
      id: id++
    } as ToastContent
  };
});

export const toastSlice = createSlice({
  name: 'toasts',
  initialState: [] as ToastContent[],
  reducers: {
    removeToast: (state, action: PayloadAction<number>) => state.filter(toast => toast.id !== action.payload)
  },
  extraReducers: (builder) => {
    builder.addCase(createToast, (state, action) => {
      const copies = state.filter(t => t.description === action.payload.description);
      if(copies.length === 0) {
        state.push(action.payload);
      }
    });
  }
});

export const { removeToast } = toastSlice.actions;