import { CreateTravelParam } from '../shared/interfaces/request/CreateTravelParam';
import { ModifyTravelParam } from '../shared/interfaces/request/ModifyTravelParam';
import { Travel } from '../shared/interfaces/Travel';
import { http } from '../utils/interceptor';
import { handleError } from '../utils/serviceErrorHandler';

export const getTravels = (startDate: string, endDate: string) => {
  return http.get<Travel[]>(`/travel?from=${startDate}&to=${endDate}`).then((response) => {
    return response;
  }).catch((error) => {
    handleError(error);
    throw new Error('Failed to get travels');
  });
};

export const getTravelById = (id: string) => {
  return http.get<Travel>(`/travel/${id}`).then((response) => {
    return response;
  }).catch((error) => {
    handleError(error);
    throw new Error('Failed to get travels');
  });
};

export const createTravel = (travel: CreateTravelParam) => {
  return http.post<Travel>('/travel', travel)
    .catch((error) => {
      handleError(error);
      throw new Error('Failed to create travel');
    });
};

export const updateTravel = (travel: ModifyTravelParam) => {
  return http.put<Travel>('/travel', travel)
    .catch((error) => {
      handleError(error);
      throw new Error('Failed to modify travel');
    });
};