import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Travel } from '../../shared/interfaces/Travel';
import * as travelService from '../../services/TravelService';
import { CreateTravelParam } from '../../shared/interfaces/request/CreateTravelParam';
import { ModifyTravelParam } from '../../shared/interfaces/request/ModifyTravelParam';

export const fetchTravels = createAsyncThunk(
  'travels/fetchTravels',
  async ({ startDate, endDate }: { startDate: moment.Moment, endDate: moment.Moment }) => {
    return travelService.getTravels(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).then(res => res.data);
  }
);

export const createTravel = createAsyncThunk(
  'travels/createTravel',
  async (travel: CreateTravelParam) => {
    return travelService.createTravel(travel)
      .then(res => (res.data));
  }
);

export const updateTravel = createAsyncThunk(
  'travels/updateTravel',
  async (travel: ModifyTravelParam) => {
    return travelService.updateTravel(travel)
      .then(res => res.data);
  }
);

export const fetchTravelById = createAsyncThunk(
  'travels/fetchTravelById',
  async (id: string) => {
    return travelService.getTravelById(id).then(res => res.data);
  }
);

export const travelSlice = createSlice({
  name: 'travels',
  initialState: [] as Travel[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTravels.fulfilled, (state, { payload }) => {
      payload.forEach((travel) => {
        const index = state.findIndex(t => t.id === travel.id);
        index !== -1 ? state[index] = travel : state.push(travel);
      });
    });
    builder.addCase(fetchTravelById.fulfilled, (state, { payload }) => {
      const index = state.findIndex(t => t.id === payload.id);
      index !== -1 ? state[index] = payload : state.push(payload);
    });
    builder.addCase(fetchTravels.rejected, (state, { payload }) => {
      console.log('fetchTravels rejected');
    });
    builder.addCase(createTravel.fulfilled, (state, { payload }) => {
      state.push(payload);
    });
    builder.addCase(updateTravel.fulfilled, (state, { payload }) => {
      const index = state.findIndex(t => t.id === payload.id);
      index !== -1 ? state[index] = payload : state.push(payload);
      return state;
    });
  }
});