import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getExpenseTypes } from '../../services/ExpenseTypeService';
import { ExpenseType } from '../../shared/interfaces/ExpenseType';

export const fetchExpenseTypes = createAsyncThunk(
  'projects/fetchExpenseTypes',
  async () => {
    return getExpenseTypes().then(res => res.data);
  }
);

export const expenseTypeSlice = createSlice({
  name: 'expenseTypes',
  initialState: [] as ExpenseType[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExpenseTypes.fulfilled, (state, { payload }) => {
      payload.forEach((expenseType) => {
        const index = state.findIndex(e => e.id === expenseType.id);
        index !== -1 ? state[index] = expenseType : state.push(expenseType);
      });
    });
    builder.addCase(fetchExpenseTypes.rejected, (state, { payload }) => {
      console.log('fetchExpenseTypes rejected');
    });
  }
});