import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as userService from '../../services/UserService';
import { UpdatePreferencesParams } from '../../shared/interfaces/request/UpdatePreferencesParams';
import { User } from '../../shared/interfaces/User';


interface InitialState {
    currentUser: User | undefined;
    visibleAndActiveUsers: User[];
}

export const fetchCurrent = createAsyncThunk(
  'users/fetchCurrent',
  async () => {
    return userService.getCurrentUser().then(res => (res.data));
  }
);

export const fetchVisibleAndActiveUsers = createAsyncThunk(
  'users/fetchVisibleAndActiveUsers',
  async () => {
    return userService.getVisibleAndActiveUsers().then(res => (res.data));
  }
);

export const updatePreferences = createAsyncThunk(
  'users/updatePreferences',
  async (params: UpdatePreferencesParams) => {
    return userService.updatePreferences(params).then(res => res.data);
  }
);

export const userSlice = createSlice({
  name: 'users',
  initialState: {
    currentUser: undefined,
    visibleAndActiveUsers: []
  } as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrent.fulfilled, (state, { payload }) => {
      state.currentUser = payload;
    });
    builder.addCase(fetchCurrent.rejected, (state, { payload }) => {
      //TODO: error handling
      console.log('error handling goes here');
    });
    builder.addCase(fetchVisibleAndActiveUsers.fulfilled, (state, { payload }) => {
      payload.forEach((username) => {
        const index = state.visibleAndActiveUsers.findIndex(u => u.username === username.username);
        index !== -1 ? state.visibleAndActiveUsers[index] = username : state.visibleAndActiveUsers.push(username);
      });
    });
    builder.addCase(fetchVisibleAndActiveUsers.rejected, (state, { payload }) => {
      //TODO: error handling
      console.log('error handling goes here');
    });
    builder.addCase(updatePreferences.fulfilled, (state, { payload }) => {
      state.currentUser = payload;
    });
  }
});