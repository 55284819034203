import React, { useState } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { Errors, Validation } from '../../shared/interfaces/util/FormValidation';
import { InputError } from './InputError';
import { Label } from './Label';
import { FaInfoCircle } from 'react-icons/fa';
import { ExpenseType } from '../../shared/interfaces/ExpenseType';
import { ExpenseTypePopup } from '../travel/ExpenseTypePopup';
import { Expense } from '../../shared/interfaces/Expense';

type MultiCheckboxInputProps = {
  label: string;
  expenseName: string;
  expenseTypeName: string;
  expenses?: Expense[];
  expenseTypes: ExpenseType[];
  useDefaultSelected: boolean;
  register: UseFormRegister<FieldValues>;
  validation?: Validation;
  errors?: Errors;
}

export const ExpenseCheckboxInput = ({
  label,
  expenseName,
  expenseTypeName,
  expenses,
  expenseTypes,
  useDefaultSelected,
  register,
  validation,
  errors
}: MultiCheckboxInputProps) => {

  const [popupExpenseType, setPopupExpenseType] = useState<ExpenseType | null>(null);

  const onInfoButtonClick = (expenseType: ExpenseType) => {
    setPopupExpenseType(expenseType);
  };

  const removePopup = () => {
    setPopupExpenseType(null);
  };

  return (
    <>
      {popupExpenseType && (
        <ExpenseTypePopup
          expenseType={popupExpenseType}
          removePopup={removePopup}
        />
      )}
      <Container>
        <Label forInput={expenseName} text={label} />
        {expenses?.map((expense, i) => (
          <OptionContainer key={expense.id}>
            <InputContainer>
              <CheckboxInputField
                type="checkbox"
                id={expense.id.toString()}
                value={expense.id.toString()}
                defaultChecked={true}
                {...register(expenseName + '.' + i, validation)}
              />
              <InputLabel htmlFor={expense.id.toString()}>
                {expense.expenseType.name}
              </InputLabel>
            </InputContainer>
            <IconContainer
              aria-label={`${expense.expenseType.name} help`}
              type="button"
              onClick={ () => onInfoButtonClick(expense.expenseType)}>
              <FaInfoCircle />
            </IconContainer>
          </OptionContainer>
        ))}
        {expenseTypes.map((expenseType, i) => (
          <OptionContainer key={i}>
            <InputContainer>
              <CheckboxInputField
                type="checkbox"
                id={expenseType.id.toString()}
                value={expenseType.id.toString()}
                defaultChecked={useDefaultSelected && expenseType.selectedByDefault}
                {...register(expenseTypeName + '.' + i, validation)}
              />
              <InputLabel htmlFor={expenseType.id.toString()}>
                {expenseType.name}
              </InputLabel>
            </InputContainer>
            <IconContainer
              aria-label={`${expenseType.name} help`}
              type="button"
              onClick={ () => onInfoButtonClick(expenseType)}>
              <FaInfoCircle />
            </IconContainer>
          </OptionContainer>
        )
        )}
        {errors && <InputError message={errors.message}/>}
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 5px 0;
  flex: 1;
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: flex;
`;

const CheckboxInputField = styled.input`
  display: inline-block;
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
`;

const InputLabel = styled.label`
`;

const IconContainer = styled.button`
  color: var(--textColor);
  padding: 0 5px;
  cursor: pointer;
  border: none;
  background: none;
`;