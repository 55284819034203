import styled from 'styled-components';

type LabelProps = {
    forInput?: string;
    text: string;
}

export const Label = ({ forInput, text }: LabelProps) => {
  return(
    <LabelStyled htmlFor={forInput}>{text}</LabelStyled>
  );
};

const LabelStyled = styled.label`
    display: block;
    font-size: 16px;
    font-weight: bold;
`;
