import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { BookingsSelector } from './bookings';

const MidweekHolidaysSelector = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
  const bookings = BookingsSelector(startDate, endDate);

  return useSelector((state: RootState) => {
    if (!startDate || !endDate) return;
    return state.midweekHolidays.filter((holiday) => {
      const date = moment(holiday.date);
      // Check if midweek-holiday date already has bookings
      // If bookings already exist, no reason for autofill-alert
      const booking = bookings?.find(booking => booking.date === holiday.date);
      if (booking) return false;

      return startDate.isSameOrBefore(date, 'days') && date.isSameOrBefore(endDate, 'days');
    });
  });
};

const MidweekHolidayByDaySelector = (date: moment.Moment) => {
  return useSelector((state: RootState) => {
    return state.midweekHolidays.find((holiday) => holiday.date === date.format('YYYY-MM-DD'));
  });
};

export { MidweekHolidaysSelector, MidweekHolidayByDaySelector };