import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { createSurvey } from '../redux/slices/surveySlice';
import { createToast } from '../redux/slices/toastSlice';
import { useAppDispatch } from '../redux/store';
import { CreateSurveyParam } from '../shared/interfaces/request/CreateSurveyParam';
import { SurveyForm, SurveyFormData } from './forms/SurveyForm/SurveyForm';

export const CreateSurvey = () => {

  const dispatch = useAppDispatch();
  const history = useHistory();

  /**
   * Parse form data to Survey-object and send post-request to API
   * to create a new survey
   */
  const onSubmit = async (data: SurveyFormData) => {

    const survey: CreateSurveyParam = {
      name: data.name,
      description: data.description,
      surveyText: data.surveyText,
      //if undefined, default to once
      schedule: data.schedule !== undefined ? Number(data.schedule) : 0,
      recurEvery: data.recurEvery !== '' ? Number(data.recurEvery) : 1,
      startDate: data.startDate,
      endDate: data.endDate,
      skippable: data.skippable === '1',
      //if undefined, set to smiley submit
      submitType: data.submitType !== undefined ? Number(data.submitType) : 2,
      forAll: data.forAll === '1',
      scoreSchedule: data.scoreSchedule === '1'
    };

    await dispatch(createSurvey(survey))
      .then(unwrapResult)
      .then(res => {
        dispatch(createToast('success', 'Success', 'Survey created successfully'));
        history.push('/viewsurveys');
      })
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error creating survey'));
      });
  };

  /**
   * Cancel button method
   */
  const onCancel = () => {
    history.push('/viewsurveys');
  };

  return (
    <SurveyForm
      status="create"
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};