import moment from 'moment';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BookingForm, BookingFormData } from './forms/BookingForm/BookingForm';
import { Booking } from '../shared/interfaces/Booking';
import { useAppDispatch } from '../redux/store';
import { createToast } from '../redux/slices/toastSlice';
import { fetchBooking, updateBooking } from '../redux/slices/bookingSlice';
import { useKeycloak } from '@react-keycloak/web';
import { unwrapResult } from '@reduxjs/toolkit';
import { BookingByIdSelector } from '../redux/selectors/bookings';
import React from 'react';
import { Loader } from '../components/common/Loader';
import { ModifyBookingParam } from '../shared/interfaces/request/ModifyBookingParam';

interface ParamTypes {
  id: string;
}

export const ModifyBooking = () => {

  const { id } = useParams<ParamTypes>();
  const booking = BookingByIdSelector(parseInt(id));
  const dispatch = useAppDispatch();
  const { initialized } = useKeycloak();
  const location = useLocation();
  const history = useHistory();

  /**
    * Parse return location from URL
    */
  let redirectUrl = '/weekview';
  const query = new URLSearchParams(location.search);
  if (query.has('fromView') && query.has('fromDate')) {
    redirectUrl = `/${query.get('fromView')}/${query.get('fromDate')}`;
  }

  /**
     * Fetch form booking data from API
     * if id is valid and keycloak is initialized
     */
  useEffect(() => {
    if (isNaN(parseInt(id)) || !initialized) return;
    dispatch(fetchBooking(parseInt(id)))
      .then(unwrapResult)
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error fetching booking data'));
      });
  }, [id, dispatch, initialized]);

  /**
     * Parse form data to Booking-object and send put-request to API to update booking
     * trough redux-dispatch
     */
  const onSubmit = async (data: BookingFormData) => {
    if (typeof data.hours === 'string') {
      data.hours = data.hours.replace(',', '.');
    }
    const newBooking: ModifyBookingParam = {
      id: booking?.id || -1,
      date: data.date,
      project: {
        id: parseInt(data.project)
      },
      task: data.task ? {
        id: parseInt(data.task)
      } : undefined,
      hours: Number(data.hours),
      description: data.description,
      reference: data.reference,
      userResponse: !!+Number(data.userResponse)
    };
    await dispatch(updateBooking(newBooking))
      .then(unwrapResult)
      .then(() => {
        history.push(redirectUrl);
        dispatch(createToast('success', 'Success', 'Booking modified successfully'));
      })
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error modifying booking'));
      });
  };

  /**
    * Cancel-button method
    */
  const onCancel = () => {
    history.push(redirectUrl);
  };

  if (isNaN(parseInt(id))) return (<div>Booking id has to be a number</div>);

  if (!booking) {
    return (
      <div data-testid="loader"><Loader /></div>
    );
  }

  return (
    <BookingForm
      status="modify"
      date={moment(booking.date).utc(true)}
      project={booking.project.id.toString()}
      task={booking.task?.id.toString()}
      hours={booking.hours}
      description={booking.description}
      reference={booking.reference}
      aiResult={booking.aiResult}
      userResponse={booking.userResponse}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};