import { http } from '../utils/interceptor';

interface Error {
    status: number;
    message: string;
}

export const LogError = (error: any) => {
  console.log(error);
  const message = 'error: ' + error.data.error + ', method: ' + error.config.method + ', body: ' + error.config.data + ', path: ' + error.data.path;
  const log: Error = {
    status: parseInt(error.data.status),
    message
  };
  http.post('/logger', log);
};