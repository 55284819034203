import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';
import { DateDisplay } from '../components/common/DateDisplay';
import { WeekTable } from '../components/booking/WeekTable';
import { useAppDispatch } from '../redux/store';
import { createToast } from '../redux/slices/toastSlice';
import { fetchBookings } from '../redux/slices/bookingSlice';
import { getFlexitimeBalance, fetchFlexitimes } from '../redux/slices/flexitimeSlice';
import { AccordionController } from '../components/booking/AccordionController';
import { unwrapResult } from '@reduxjs/toolkit';
import { Loader } from '../components/common/Loader';
import { fetchMidweekHolidays } from '../redux/slices/midweekHolidaySlice';
import { HolidayAlertController } from '../components/booking/HolidayAlertController';
import { FlexitimeAlertController } from '../components/booking/FlexitimeAlertController';
import { fetchProjects } from '../redux/slices/projectSlice';
import { useParams } from 'react-router-dom';
import { fetchTravels } from '../redux/slices/travelSlice';
import { TravelAccordionController } from '../components/travel/TravelAccordionController';
import { TravelTable } from '../components/travel/TravelTable';
import React from 'react';

interface ParamTypes {
  date: string;
}

export const WeekView = () => {

  const { initialized } = useKeycloak();
  const { date } = useParams<ParamTypes>();
  const [validDate, setValidDate] = useState<moment.Moment | null>(null);
  const [weekDays, setWeekDays] = useState<moment.Moment[]>([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();

  /**
     * Parse date from URL-parameter before doing anything else
     */
  useEffect(() => {
    let dateParsed;
    if (date == null) {
      dateParsed = moment();
    } else {
      dateParsed = moment(date, 'DD.MM.YYYY', true);
    }
    if (dateParsed.isValid()) {
      setValidDate(dateParsed);
    }
  }, [date]);

  /**
     * After valid date is parsed and keycloak is initialized
     * calculate weekdays and set up redux state
     */
  useEffect(() => {
    const fetchData = async () => {
      if (!validDate || !initialized) return;

      setLoading(true);
      // Calculate week days
      const weekStartTmp = validDate.clone().startOf('isoWeek');
      let weekDaysCopy = [];
      for (let i = 0; i < 7; i++) {
        weekDaysCopy.push(moment(weekStartTmp).add(i, 'days'));
      }
      setWeekDays(weekDaysCopy);

      // Fetch data from API to redux state
      await Promise.all([
        dispatch(getFlexitimeBalance())
          .then(unwrapResult)
          .catch(err => {
            console.log(err);
            dispatch(createToast('error', 'Error', 'Could not connect to API'));
          }),
        dispatch(fetchProjects())
          .then(unwrapResult)
          .catch(error => {
            console.log(error);
            dispatch(createToast('error', 'Error', 'Could not connect to API'));
          }),
        dispatch(fetchBookings({ startDate: weekStartTmp, endDate: weekStartTmp.clone().add(6, 'days') }))
          .then(unwrapResult)
          .catch(err => {
            console.log(err);
            dispatch(createToast('error', 'Error', 'Could not connect to API'));
          }),
        dispatch(fetchFlexitimes({ startDate: weekStartTmp, endDate: weekStartTmp.clone().add(6, 'days') }))
          .then(unwrapResult)
          .catch(err => {
            console.log(err);
            dispatch(createToast('error', 'Error', 'Could not connect to API'));
          }),
        dispatch(fetchMidweekHolidays({ startDate: weekStartTmp, endDate: weekStartTmp.clone().add(6, 'days') }))
          .then(unwrapResult)
          .catch(error => {
            console.log(error);
            dispatch(createToast('error', 'Error', 'Could not connect to API'));
          }),
        dispatch(fetchTravels({ startDate: weekStartTmp, endDate: weekStartTmp.clone().add(6, 'days') }))
          .then(unwrapResult)
          .catch(error => {
            console.log(error);
            dispatch(createToast('error', 'Error', 'Could not connect to API'));
          })
      ]);
      setLoading(false);
    };
    fetchData();
  }, [validDate, initialized, dispatch]);


  if (!weekDays) return <Loader />;

  // Mobile resolution
  if (window.innerWidth < 1100 && validDate) {
    return (
      <Container>
        <DateDisplay
          date={validDate}
          timeUnit="weeks"
          onDateChange={(newDate) => setValidDate(newDate)}
        />
        <HolidayAlertController
          weekDays={weekDays}
          loading={loading}
        />
        <FlexitimeAlertController />
        <WeekTitle>Week {validDate.week() === 1 ? 52 : validDate.week() - 1}</WeekTitle>
        <AccordionController
          loading={loading}
          weekData={weekDays}
          selectedDate={moment(date, 'DD.MM.YYYY', true)}
        />
        <TravelAccordionController
          weekDays={weekDays}
          loading={loading}
        />
      </Container>
    );
  }

  // Desktop resolution
  if (validDate) {
    return (
      <Container>
        <DateDisplay
          date={validDate}
          timeUnit="weeks"
          onDateChange={(newDate) => setValidDate(newDate)}
        />
        <HolidayAlertController
          weekDays={weekDays}
          loading={loading}
        />
        <FlexitimeAlertController />
        <WeekTitle>Week {validDate.week() === 1 ? 52 : validDate.week() - 1}</WeekTitle>
        <WeekTable
          weekDays={weekDays}
          loading={loading}
        />
        <TravelTable
          weekDays={weekDays}
          loading={loading}
        />
      </Container>
    );
  }

  return null;
};

const Container = styled.div`
    margin: 2px;
    font-family: "Consolas", monospace;
`;

const WeekTitle = styled.h2`
    text-align: center;
    margin: 5px;
    font-size: 1.4rem;
    font-weight: bold;
`;