import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export function FlexitimesSelector(startDate: moment.Moment | null, endDate: moment.Moment | null) {
  return useSelector((state: RootState) => {
    if (!startDate || !endDate) return;
    return state.flexitimes.flexitimes.filter((flexitime) => {
      const date = moment(flexitime.date);
      return startDate.isSameOrBefore(date, 'days') && date.isSameOrBefore(endDate, 'days');
    });
  });
}

export const DailyFlexitimeSelector = (date: moment.Moment | null) => {
  return useSelector((state: RootState) => {
    if (date === null) return undefined;
    const dailyFlexi = state.flexitimes.flexitimes.find(wf => wf.date === date.format('YYYY-MM-DD'));
    return dailyFlexi !== undefined ? dailyFlexi.hours : 0;
  });
};

export function FlexitimeBalanceSelector() {
  const { flexitimeBalance } = useSelector((state: RootState) => state.flexitimes);
  return flexitimeBalance;
}