import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DropDownInput } from '../../../components/forms/DropDownInput';
import { DateInput } from '../../../components/forms/DateInput';
import { TextInput } from '../../../components/forms/TextInput';
import { SubmitButton } from '../../../components/forms/SubmitButton';
import { CancelButton } from '../../../components/forms/CancelButton';
import { TextAreaInput } from '../../../components/forms/TextAreaInput';
import moment from 'moment';
import { FieldValues, useForm } from 'react-hook-form';
import { TimeInput } from '../../../components/forms/TimeInput';
import { ExpenseCheckboxInput } from '../../../components/forms/ExpenseCheckboxInput';
import { useKeycloak } from '@react-keycloak/web';
import { useAppDispatch } from '../../../redux/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchProjects } from '../../../redux/slices/projectSlice';
import { createToast } from '../../../redux/slices/toastSlice';
import { ProjectsSelector } from '../../../redux/selectors/projects';
import { ExpenseTypesSelector } from '../../../redux/selectors/expenseTypes';
import { fetchExpenseTypes } from '../../../redux/slices/expenseTypeSlice';
import { Option, OptionGroup } from '../../../shared/interfaces/util/SelectOption';
import { Loader } from '../../../components/common/Loader';
import { Validation } from '../../../shared/interfaces/util/FormValidation';
import * as Validator from './Validators';
import { Expense } from '../../../shared/interfaces/Expense';
import { ExpenseType } from '../../../shared/interfaces/ExpenseType';

/**
 * Props from parent component
 */
interface TravelFormProps {
  datetimeStart?: moment.Moment;
  datetimeEnd?: moment.Moment;
  project?: string;
  task?: string;
  meterAtStart?: number;
  meterAtEnd?: number;
  isInvoiced?: boolean;
  route?: string;
  purpose?: string;
  expenses?: Expense[];
  status: 'add' | 'modify'
  onSubmit: (data: TravelFormData) => void;
  onCancel: () => void;
}

/**
 * Form submit data
 */
export interface TravelFormData {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  project: string;
  task: string;
  meterAtStart: string;
  meterAtEnd: string;
  invoiced: '1' | '0';
  route: string;
  purpose: string;
  expenses: (string | false)[] | undefined;
  expenseTypes: (string | false)[];
}

export const TravelForm = ({
  datetimeStart,
  datetimeEnd,
  project,
  task,
  meterAtStart,
  meterAtEnd,
  isInvoiced,
  route,
  purpose,
  expenses,
  status,
  onSubmit,
  onCancel
}: TravelFormProps) => {

  const { register, watch, setValue, getValues, formState: { errors, isSubmitting }, handleSubmit } = useForm<FieldValues>({
    defaultValues: { project: project, task: task }
  });

  const { initialized } = useKeycloak();
  const dispatch = useAppDispatch();
  const projects = ProjectsSelector();
  const expenseTypes = ExpenseTypesSelector();
  const [projectOptions, setProjectOptions] = useState<(Option | OptionGroup)[]>([]);
  const [taskOptions, setTaskOptions] = useState<Option[]>([]);
  const [validExpenseTypes, setValidExpenseTypes] = useState<ExpenseType[]>([]);
  const watchProject = watch('project');
  const invoicedOptions: Option[] = [{
    text: 'Yes',
    value: '1'
  }, {
    text: 'No',
    value: '0'
  }];

  /**
   * When keycloak is initalized, set up redux state data
   */
  useEffect(() => {
    if (!initialized) return;
    dispatch(fetchProjects())
      .then(unwrapResult)
      .catch(error => {
        console.log(error);
        dispatch(createToast('error', 'Error', 'Error fetching projects'));
      });
    dispatch(fetchExpenseTypes())
      .then(unwrapResult)
      .catch(error => {
        console.log(error);
        dispatch(createToast('error', 'Error', 'Error fetching expense types'));
      });
  }, [initialized, dispatch]);

  /**
   * Set up form dropdown options for 'general projects' and 'assigned projects'
   */
  useEffect(() => {
    if (projects.length === 0) return;
    let generalProjects: Option[] = [];
    let assignedProjects: Option[] = [];
    projects.forEach((project) => {
      if (project.projectType.name === 'General projects') {
        generalProjects.push({
          text: project.name,
          value: project.id.toString()
        });
      } else {
        assignedProjects.push({
          text: `${project.name} [${project.customer.name}]`,
          value: project.id.toString()
        });
      }
    });
    let options: (Option | OptionGroup)[] = [];
    options.push({
      text: '-- Select project --',
      value: '-1'
    });
    options.push({
      label: 'ASSIGNED PROJECTS:',
      options: assignedProjects
    });
    options.push({
      label: 'GENERAL PROJECTS:',
      options: generalProjects
    });
    setProjectOptions(options);
  }, [projects]);

  /**
   * Set task option values when selected project is updated
   */
  useEffect(() => {
    const currentProject = projects.filter((project) =>
      (project.id.toString() === watchProject));

    if (currentProject.length !== 1) {
      setTaskOptions([]);
      return;
    }
    const activeTasks = currentProject[0].tasks.filter((task) => (task.status && task.status.active));
    const options = activeTasks.map((task) => ({
      value: task.id.toString(),
      text: task.name
    }));
    setTaskOptions(options);

  }, [projects, watchProject]);

  /**
   * When task options change, set selection to either default task or first task
   */
  useEffect(() => {
    if (taskOptions.length > 0) {
      if (task && watchProject === project) {
        setValue('task', task);
      } else {
        setValue('task', taskOptions[0].value);
      }
    }
  }, [taskOptions, watchProject, project, task, setValue]);

  /**
   * Set expense types that can still be added to travel
   */
  useEffect(() => {
    if (!expenses) {
      setValidExpenseTypes(expenseTypes);
    } else {
      let validTypes: ExpenseType[] = [];
      expenseTypes.forEach((expenseType) => {
        if (expenseType.multipleExist) {
          validTypes.push(expenseType);
        } else {
          let sameTypeExpense = expenses.find(e => (e.expenseType.id === expenseType.id));
          if (!sameTypeExpense) {
            validTypes.push(expenseType);
          }
        }
      });
      setValidExpenseTypes(validTypes);
    }
  }, [expenseTypes]);

  const dateValidation: Validation = {
    validate: {
      startDateBeforeEndDate: () =>
        moment(getValues('startDate') + 'T' + getValues('startTime'), 'YYYY-MM-DDTHH:mm', true)
          .isSameOrBefore(moment(getValues('endDate') + 'T' + getValues('endTime'), 'YYYY-MM-DDTHH:mm', true))
        || 'Start date/time must be before end date/time'
    }
  };

  const travelMeterValidation: Validation = {
    required: {
      value: true,
      message: 'Travel meter is required'
    },
    min: {
      value: 0,
      message: 'Travel meter must be 0 or larger'
    },
    pattern: {
      value: /^[0-9]*$/,
      message: 'Travel meter must be a whole number'
    },
    validate: {
      travelEndLargerThanTravelStart:
        () => Number(getValues('meterAtEnd')) >= Number(getValues('meterAtStart'))
          || 'Travel meter cannot be smaller after the trip'
    }
  };

  //prevent rendering before projects are loaded
  //default values don't work if component is rendered before projects are loaded
  if (projectOptions.length === 0) {
    return (
      <div data-testid="loader">
        <Loader data-testid="loader" />
      </div>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <DateTimeContainer>
        <DateInput
          label="Start date *"
          name="startDate"
          defaultDate={datetimeStart ? datetimeStart : moment()}
          register={register}
          dateValidation={dateValidation}
          errors={errors.startDate}
        />
        <TimeInput
          label="Start time *"
          name="startTime"
          defaultValue={datetimeStart ? datetimeStart.format('HH:mm') : ''}
          register={register}
          validation={Validator.timeValidation}
          errors={errors.startTime}
        />
      </DateTimeContainer>
      <DateTimeContainer>
        <DateInput
          label="End date *"
          name="endDate"
          defaultDate={datetimeEnd ? datetimeEnd : moment()}
          register={register}
          dateValidation={dateValidation}
          errors={errors.endDate}
        />
        <TimeInput
          label="End time *"
          name="endTime"
          defaultValue={datetimeEnd ? datetimeEnd.format('HH:mm') : ''}
          register={register}
          validation={Validator.timeValidation}
          errors={errors.endTime}
        />
      </DateTimeContainer>
      <DropDownInput
        label="Project *"
        name="project"
        options={projectOptions}
        register={register}
        validation={Validator.projectValidation}
        errors={errors.project}
        taskOptionCount={taskOptions.length}
        watch={watch}
        popupType={'project'}
      />
      <DropDownInput
        label="Task *"
        name="task"
        options={taskOptions}
        register={register}
        validation={{}}
        errors={errors.task}
        taskOptionCount={taskOptions.length}
        watch={watch}
        popupType={'task'}
      />
      <TextInput
        label="Travel meter at start *"
        name="meterAtStart"
        defaultValue={meterAtStart?.toString()}
        register={register}
        validation={travelMeterValidation}
        errors={errors.meterAtStart}
      />
      <TextInput
        label="Travel meter at end *"
        name="meterAtEnd"
        defaultValue={meterAtEnd?.toString()}
        register={register}
        validation={travelMeterValidation}
        errors={errors.meterAtEnd}
      />
      <DropDownInput
        label="Is invoiced from customer"
        name="invoiced"
        options={invoicedOptions}
        defaultValue={isInvoiced !== undefined && !isInvoiced ? '0' : '1'}
        register={register}
        validation={{}}
        errors={errors.invoiced}
      />
      <TextAreaInput
        label="Route *"
        name="route"
        defaultValue={route}
        register={register}
        validation={Validator.routeValidation}
        errors={errors.route}
      />
      <TextAreaInput
        label="Purpose *"
        name="purpose"
        defaultValue={purpose}
        register={register}
        validation={Validator.purposeValidation}
        errors={errors.purpose}
      />
      <ExpenseCheckboxInput
        label="Expenses"
        expenseName="expenses"
        expenseTypeName="expenseTypes"
        expenses={expenses}
        expenseTypes={validExpenseTypes}
        useDefaultSelected={status === 'add'}
        register={register}
        validation={{}}
        errors={errors.expenses}
      />
      <ButtonContainer>
        <SubmitButton
          disabled={isSubmitting}
          text={{ add: 'ADD TRAVEL', modify: 'MODIFY TRAVEL' }[status]}
        />
        <CancelButton
          text="CANCEL"
          onClick={onCancel}
        />
      </ButtonContainer>
    </Form>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;


const DateTimeContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    width: 49%;
  }

  > * + * {
    margin-left: 4px;
  }
`;

const Form = styled.form`
  max-width: 500px;
  padding: 10px;
  font-family: "Consolas", monospace;
`;