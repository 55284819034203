import React from 'react';
import './Toggle.css';

interface ToggleProps {
    toggleTheme: () => void;
    nightMode: boolean;
}

export const Toggle = ({ toggleTheme, nightMode }: ToggleProps) => {
  return (
    <label className="switch">
      <input data-testid="toggle" readOnly type="checkbox" checked={nightMode} onClick={() => toggleTheme()} />
      <span className="slider round"></span>
    </label>
  );
};
