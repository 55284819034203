import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

interface TotalCardProps {
  hours?: number;
  difference: number;
  color?: string;
  date?: moment.Moment;
}

export const TotalCard = ({ hours, difference, color, date }: TotalCardProps) => {

  return (
    <Container color={color}>
      <TextContainer>
        Total
      </TextContainer>
      <HoursContainer>
        {hours?.toFixed(2)}h
        {difference !== 0 &&
          <DifferenceErrorContainer>
            ({difference > 0 ? '+' : ''}{difference.toFixed(2)}h)
          </DifferenceErrorContainer>
        }
      </HoursContainer>
    </Container>
  );
};

const Container = styled.div`
    transition: background-color 0.25s;
    background-color: ${props => props.color === 'primary' ? 'var(--bodyColor)' : 'var(--atrSecondaryColor)'};
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 6px;
    margin: 5px;
`;

const TextContainer = styled.div`
    grid-column: 1;
`;

const HoursContainer = styled.div`
    grid-column: 2;
    text-align: right;
`;

const DifferenceErrorContainer = styled.span`
    color: red;
    padding-left: 5px;
`;