import React from 'react';
import { FaWrench } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface BookingCardProps {
  id: number;
  project: string;
  task?: string;
  hours: number;
  description: string;
  reference?: string;
  aiResult?: boolean;
  userResponse?: boolean;
  color?: string;
  fromView?: string;
  fromDate?: string;
}

export const BookingCard = ({ id, project, task, hours, description, aiResult, userResponse, reference, color, fromView, fromDate }: BookingCardProps) => {

  return (
    <Container color={aiResult !== undefined && !aiResult && userResponse === undefined ? 'warn' : color} >
      <ProjectContainer to={`/modifybooking/${id}?fromView=${fromView}&fromDate=${fromDate}`}>
        <EditHoursLink>
          <FaWrench />
        </EditHoursLink>{project}
      </ProjectContainer>
      <TaskContainer>{task}</TaskContainer>
      <DescriptionContainer>
        {description}
      </DescriptionContainer>
      <HoursContainer>{hours.toFixed(2)}h</HoursContainer>
      {reference && (
        <ReferenceContainer>Reference: {reference}</ReferenceContainer>
      )}
    </Container >
  );
};

const Container = styled.div`
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 10px 0px;
    margin: 5px;
    background-color: ${props => props.color === 'primary' ? 'var(--bodyColor)' : props.color === 'warn' ? 'var(--atrWarnRowColor)' : 'var(--atrSecondaryColor)'};
`;

const ProjectContainer = styled(Link)`
    grid-row: 1;
    grid-column: 1;
    color: var(--textColor);
    display: flex;
    align-items: center;
    font-size: 16px;
`;

const TaskContainer = styled.div`
    grid-row: 2;
    grid-column: 1;
    padding: 5px 0;
    padding-left: 15px;
    font-size: 16px;
`;

const HoursContainer = styled.div`
    grid-row-start: 1;
    grid-row-end: 5;
    grid-column: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.1rem;
`;

const DescriptionContainer = styled.div`
    grid-row: 4;
    grid-column: 1;
    padding-left: 15px;
    margin: 3px 0;
    font-size: 15px;
`;

const ReferenceContainer = styled.div`
    grid-row: 5;
    grid-column: 1;
    padding: 0 0 0 15px;
    font-size: 14px;
`;

const EditHoursLink = styled.span`
    padding: 5px 4px 2px 4px;
    margin: 4px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    border-radius: 7px;
    float: left;
`;
