import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Loader } from '../components/common/Loader';
import { CurrentUserSelector } from '../redux/selectors/users';
import { createToast } from '../redux/slices/toastSlice';
import { createTravel } from '../redux/slices/travelSlice';
import { useAppDispatch } from '../redux/store';
import { CreateTravelParam } from '../shared/interfaces/request/CreateTravelParam';
import { TravelForm, TravelFormData } from './forms/TravelForm/TravelForm';

interface ParamTypes {
  date: string;
}

export const CreateTravel = () => {

  const { date } = useParams<ParamTypes>();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentUser = CurrentUserSelector();

  /**
   * Parse date from url
   */
  let defaultDate: moment.Moment | undefined = undefined;
  if (date) {
    let parsedDate = moment(date, 'DD.MM.YYYY', true).utc(true);
    if (parsedDate.isValid()) {
      defaultDate = parsedDate;
    }
  }

  /**
   * Parse return location from url
   */
  let redirectUrl = '/weekview';
  const query = new URLSearchParams(location.search);
  if (query.has('fromView') && query.has('fromDate')) {
    redirectUrl = `/${query.get('fromView')}/${query.get('fromDate')}`;
  }

  /**
   * Submit form data - parse CreateTravelParam-object from form data
   * and send request to API trough redux-dispatch
   */
  const onSubmit = async (data: TravelFormData) => {

    const travel: CreateTravelParam = {
      startTime: data.startDate + 'T' + data.startTime + ':00',
      endTime: data.endDate + 'T' + data.endTime + ':00',
      projectId: Number(data.project),
      taskId: Number(data.task),
      meterAtStart: Number(data.meterAtStart),
      meterAtEnd: Number(data.meterAtEnd),
      description: data.route,
      purpose: data.purpose,
      invoicedFromCustomer: data.invoiced === '1',
      expenseTypeIds: data.expenseTypes.filter(e => (e !== false)).map((e) => (Number(e)))
    };

    await dispatch(createTravel(travel))
      .then(unwrapResult)
      .then(() => {
        history.push(redirectUrl);
        dispatch(createToast('success', 'Success', 'Travel created successfully'));
      })
      .catch((err) => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error creating travel'));
      });
  };


  const onCancel = () => {
    history.push(redirectUrl);
  };

  if (!currentUser) {
    return (
      <div data-testid="loader"><Loader /></div>
    );
  }

  return (
    <TravelForm
      status="add"
      datetimeStart={defaultDate}
      project={currentUser?.default_project?.toString()}
      task={currentUser?.default_task?.toString()}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};