import { FieldValues, useForm } from 'react-hook-form';
import { SubmitButton } from '../../../components/forms/SubmitButton';
import { useKeycloak } from '@react-keycloak/web';
import { useAppDispatch } from '../../../redux/store';
import { createToast } from '../../../redux/slices/toastSlice';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DropDownInput } from '../../../components/forms/DropDownInput';
import { ProjectsSelector } from '../../../redux/selectors/projects';
import { fetchProjects } from '../../../redux/slices/projectSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Option } from '../../../shared/interfaces/util/SelectOption';

interface PreferencesFormProps {
    project?: string
    task?: string
    onSubmit: (data: PreferencesFormData) => void;
}

export interface PreferencesFormData {
    defaultproject: string;
    defaulttask: string;
}

export const PreferencesForm = ({ project, task, onSubmit }: PreferencesFormProps) => {

  const { register, setValue, watch, formState: { errors, isSubmitting }, handleSubmit } = useForm<FieldValues>({
    defaultValues: { defaultproject: project, defaulttask: task }
  });
  const watchProject = watch('defaultproject');

  const { initialized } = useKeycloak();
  const dispatch = useAppDispatch();
  const projects = ProjectsSelector();
  const [projectOptions, setProjectOptions] = useState<Option[]>([]);
  const [taskOptions, setTaskOptions] = useState<Option[]>([]);

  /**
     * Fetch projects from API to redux state
     */
  useEffect(() => {
    if (!initialized) return;
    dispatch(fetchProjects())
      .then(unwrapResult)
      .catch(err => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'error fetching projects from api'));
      });
  }, [initialized, dispatch]);

  /**
     * Set up dropdown options for projects
     */
  useEffect(() => {
    if (projects.length === 0) return;
    //sort projects to assigned
    let assignedProjects: Option[] = [];
    projects.forEach((project) => {
      if (project.projectType.name === 'Assigned projects') {
        assignedProjects.push({
          text: `${project.name} [${project.customer.name}]`,
          value: project.id.toString()
        });
      }
    });
    let options: Option[] = [];
    options.push({
      text: '-- None --',
      value: ''
    });
    options = options.concat(assignedProjects);
    setProjectOptions(options);
  }, [projects]);


  /**
     * Set task options when project is selected from dropdown
     */
  useEffect(() => {
    const currentProject = projects.filter((project) =>
      (project.id.toString() === watchProject));
    if (currentProject.length !== 1) {
      setTaskOptions([{ value: '', text: '-- None --' }]);
      return;
    }
    // Set task input to none if selected task is not in selected project
    if (currentProject[0].tasks.filter(t => (t.id.toString() === task)).length === 0) {
      setValue('defaulttask', '');
    }
    const activeTasks = currentProject[0].tasks.filter((task) => (task.status && task.status.active));
    const options = activeTasks.map((task) => ({
      value: task.id.toString(),
      text: task.name
    }));
    options.unshift({ value: '', text: '-- None --' });
    setTaskOptions(options);

  }, [projects, projectOptions, setValue, watchProject, task]);

  /**
     * Prevent rendering before projects are loaded
     * Default values don't work if component is rendered before projects are loaded
     */
  if (projectOptions.length === 0) return (<></>);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <DropDownInput
        label="Default Project"
        name="defaultproject"
        options={projectOptions}
        register={register}
        validation={{}}
        errors={errors.defaultproject}
      />
      <DropDownInput
        label="Default Task"
        name="defaulttask"
        options={taskOptions}
        register={register}
        validation={{}}
        errors={errors.defaulttask}
      />
      <SubmitButton
        disabled={isSubmitting}
        text="SAVE"
      />
    </Form>
  );
};

const Form = styled.form`
    max-width: 500px;
    padding: 10px;
    font-family: "Consolas", monospace;
`;