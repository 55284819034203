import moment from 'moment';
import { DayData } from '../../shared/interfaces/util/DayData';
import { WeekData } from '../../shared/interfaces/util/WeekData';
import { BookingsSelector } from './bookings';
import { FlexitimesSelector } from './flexitimes';

/**
 * Combine all the booking and flexitime data into one WeekData-object.
 * This makes it easier to iterate trough JSX in WeekTable.tsx
 */
export const WeekDataSelector = (weekDays: moment.Moment[]): WeekData => {
  const bookings = BookingsSelector(weekDays[0], weekDays[6]);
  const flexitimes = FlexitimesSelector(weekDays[0], weekDays[6]);

  let weekData: WeekData = {
    days: [],
    totalWeeklyHours: 0,
    totalWeeklyFlexitime: 0,
    bookingsEmpty: true
  };
  for (let i = 0; i < 7; i++) {
    const dayData: DayData = {
      bookings: [],
      flexitime: 0,
      totalHours: 0,
      date: weekDays[i]
    };
    bookings?.forEach(booking => {
      if (weekDays[i].format('YYYY-MM-DD') === booking.date) {
        dayData.totalHours += booking.hours;
        dayData.bookings.push(booking);
        weekData.totalWeeklyHours += booking.hours;
        weekData.bookingsEmpty = false;
      }
    });

    flexitimes?.forEach(flexitime => {
      if (weekDays[i].format('YYYY-MM-DD') === flexitime.date) {
        dayData.flexitime = flexitime.hours;
        weekData.totalWeeklyFlexitime += flexitime.hours;
      }
    });
    weekData.days.push(dayData);
  }
  return weekData;
};
