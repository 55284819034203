import styled from 'styled-components';
import { ToastSelector } from '../../../redux/selectors/toasts';
import { removeToast } from '../../../redux/slices/toastSlice';
import { useAppDispatch } from '../../../redux/store';
import { useEffect } from 'react';
import { Toast } from './Toast';

export const ToastController = () => {
  const toasts = ToastSelector();
  const dispatch = useAppDispatch();

  /**
     * Logic to remove toast after certain amount of time
     */
  useEffect(() => {
    if (toasts.length === 0) return;
    const interval = setTimeout(() => {
      dispatch(removeToast(toasts[0].id));
    }, 3000);
    return () => {
      clearTimeout(interval);
    };
  }, [toasts]);

  return (
    <ToastContainerStyled>
      {toasts.map((toast, i) => (
        <Toast
          key={i}
          content={toast}
          onClick={() => dispatch(removeToast(toast.id))}
        />
      ))}
    </ToastContainerStyled>
  );
};

const ToastContainerStyled = styled.div`
    position: fixed;
    right: 0;
    width: 95%;
    margin: 10px;

    @media (min-width: 900px) {
        width: 500px;
        left: 0;
        margin: 10px auto;
    }
`;