import moment from 'moment';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CancelButton } from '../../../components/forms/CancelButton';
import { SubmitButton } from '../../../components/forms/SubmitButton';
import { TextInput } from '../../../components/forms/TextInput';
import { TextAreaInput } from '../../../components/forms/TextAreaInput';
import { DropDownInput } from '../../../components/forms/DropDownInput';
import { useEffect, useState } from 'react';
import { ExpenseTypesSelector } from '../../../redux/selectors/expenseTypes';
import { ExpenseType } from '../../../shared/interfaces/ExpenseType';
import * as Validator from './Validators';
import { Travel } from '../../../shared/interfaces/Travel';
import { Expense } from '../../../shared/interfaces/Expense';

interface Option {
    value: string;
    text: string;
}

export interface ExpenseFormData {
    cost: number;
    currency: string;
    currencyexchangerate: number;
    description: string;
    expensetype: number;
    quantity: number;
    travelId: number;
}

interface ExpenseFormProps {
    travel: Travel;
    expense?: Expense;
    onSubmit: (data: ExpenseFormData, expenseType?: ExpenseType) => void;
    onCancel: () => void;
}

export const ExpenseForm = ({ travel, expense, onSubmit, onCancel }: ExpenseFormProps) => {

  const { register, formState: { errors, isSubmitting }, watch, setValue, handleSubmit } = useForm();
  const expenseTypes = ExpenseTypesSelector();
  const watchExpenseType = watch('expensetype');

  const [expenseTypeOptions, setExpenseTypeOptions] = useState<Option[]>([]);
  const [currentExpenseType, setCurrentExpenseType] = useState<ExpenseType | null>(null);

  const getQuantity = () => {
    let quantity = 0;
    if (currentExpenseType?.quantityFromTravelDays && travel) {
      quantity = moment(travel.endTime).diff(moment(travel.startTime), 'days') + 1;
    }
    if (currentExpenseType?.quantityFromTravelMeter && travel) {
      quantity = travel.meterAtEnd - travel.meterAtStart;
    }
    return quantity;
  };

  const shouldAddExpenseOption = (expenseType: ExpenseType) => {
    let addExpense = true;
    if (!expenseType.multipleExist) {
      travel?.expenses.forEach(expense => {
        if (expenseType.id === expense.expenseType.id) {
          addExpense = false;
        };
      });
    }
    if (expense && expenseType.id === expense.expenseType.id) {
      addExpense = true;
    }
    return addExpense;
  };
  useEffect(() => {
    if (expenseTypes.length === 0) return;
    let options: Option[] = [];
    let currentExpenseTypeTmp = expenseTypes[0];
    expenseTypes.forEach((expenseType) => {
      if (shouldAddExpenseOption(expenseType)) {
        options.push({
          text: expenseType.name,
          value: expenseType.id.toString()
        });
      }
    });
    const expenseType = expenseTypes.find(e => expense?.expenseType.id === e.id);
    if (expenseType) {
      currentExpenseTypeTmp = expenseType;
    }
    setCurrentExpenseType(currentExpenseTypeTmp);
    setExpenseTypeOptions(options);
  }, [expenseTypes]);

  useEffect(() => {
    const expenseType = expenseTypes.find(e => e.id === Number(watchExpenseType));
    if (!expenseType) return;
    setCurrentExpenseType(expenseType);
  }, [watchExpenseType]);

  useEffect(() => {
    setValue('quantity', getQuantity());
    setValue('cost', currentExpenseType?.costEUR);
    setValue('currency', 'EUR');
    setValue('currencyexchangerate', '1');
    if (expense && currentExpenseType?.costEditable) {
      setValue('cost', expense.cost);
      setValue('currency', expense.currency);
      setValue('currencyexchangerate', expense.currencyExRate);
      setValue('quantity', expense.quantity);
    }
  }, [currentExpenseType]);

  if (!travel || !currentExpenseType) return null;
  return (
    <Form onSubmit={handleSubmit(((data: ExpenseFormData) => onSubmit(data, currentExpenseType)))}>
      <TextInput
        label="Project *"
        name="project"
        defaultValue={travel.project.name}
        validation={Validator.projectValidation}
        errors={errors.project}
        register={register}
        isDisabled={true}
      />
      <TextInput
        label="Time period *"
        name="timeperiod"
        validation={Validator.dateValidation}
        errors={errors.timeperiod}
        defaultValue={`${moment(travel.startTime).format('DD.MM.YYYY HH:mm')} - ${moment(travel.endTime).format('DD.MM.YYYY HH:mm')}`}
        register={register}
        isDisabled={true}
      />
      <DropDownInput
        label="Expense type *"
        name="expensetype"
        options={expenseTypeOptions}
        register={register}
        validation={Validator.expenseTypeValidation}
        defaultValue={currentExpenseType.id.toString()}
        errors={errors.expensetype}
      />
      <TextInput
        label="Quantity *"
        name="quantity"
        register={register}
        defaultValue={getQuantity().toString()}
        validation={Validator.quantityValidation}
        errors={errors.quantity}
        isDisabled={!currentExpenseType.quantityEditable}
      />
      <TextInput
        label="Cost *"
        name="cost"
        register={register}
        defaultValue={currentExpenseType.costEUR.toString()}
        validation={Validator.costValidation}
        errors={errors.cost}
        isDisabled={!currentExpenseType.costEditable}
      />
      <TextInput
        label="Currency *"
        name="currency"
        register={register}
        defaultValue={expense ? expense.currency : 'EUR'}
        validation={Validator.currencyValidation}
        errors={errors.currency}
        isDisabled={!currentExpenseType.costEditable}
      />
      <TextInput
        label="Currency exchange rate *"
        name="currencyexchangerate"
        register={register}
        defaultValue={expense ? expense.currencyExRate.toString() : '1'}
        validation={Validator.currencyExchangeRateValidation}
        errors={errors.currencyexchangerate}
        isDisabled={!currentExpenseType.costEditable}
      />
      <TextAreaInput
        label="Description *"
        name="description"
        defaultValue={expense ? expense.description : ''}
        register={register}
        validation={Validator.descriptionValidation}
        errors={errors.description}
      />
      <ButtonContainer>
        <SubmitButton
          disabled={isSubmitting}
          text="CREATE"
        />
        <CancelButton
          text="CANCEL"
          onClick={onCancel}
        />
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;