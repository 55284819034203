import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSurveyResponses } from '../../services/SurveyService';
import { SurveyResponse } from '../../shared/interfaces/SurveyResponse';

export const fetchSurveyResponses = createAsyncThunk(
  'surveyResponses/fetchSurveyResponses',
  async ({ username, surveyId, submitType, submitValue, startDate, endDate }: { username?: string, surveyId?: string, submitType?: string, submitValue?: string, startDate?: string, endDate?: string }) => {
    return getSurveyResponses(username, surveyId, submitType, submitValue, startDate, endDate).then(res => res.data);
  }
);

interface InitialState {
    surveyResponses: SurveyResponse[];
}

export const surveyResponseSlice = createSlice({
  name: 'projects',
  initialState: {
    surveyResponses: []
  } as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSurveyResponses.fulfilled, (state, { payload }) => {
      state.surveyResponses = [];
      payload.forEach((surveyResponse) => {
        state.surveyResponses.push(surveyResponse);
      });
    });
    builder.addCase(fetchSurveyResponses.rejected, (state, { payload }) => {
      console.log('fetchSurveyResponses rejected');
    });
  }
});