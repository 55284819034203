import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Project } from '../../shared/interfaces/Project';
import { getProjects } from '../../services/ProjectService';

export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async () => {
    return getProjects().then(res => res.data);
  }
);

export const projectSlice = createSlice({
  name: 'projects',
  initialState: [] as Project[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchProjects.rejected, (state, { payload }) => {
      console.log('fetchProjects rejected');
    });
  }
});