import { CreateExpenseParam } from '../shared/interfaces/request/CreateExpenseParam';
import { Expense } from '../shared/interfaces/Expense';
import { http } from '../utils/interceptor';
import { handleError } from '../utils/serviceErrorHandler';

export const createExpense = (createExpense: CreateExpenseParam) => {
  return http.post<CreateExpenseParam>('/travel/expense', createExpense).then((response) => {
    return response;
  }).catch((error) => {
    handleError(error);
    throw new Error('Failed to create expense');
  });
};

export const modifyExpense = (expense: Expense) => {
  return http.put<Expense>('/travel/expense', expense).then((response) => {
    return response;
  }).catch((error) => {
    handleError(error);
    throw new Error('Failed to modify expense');
  });
};

export const getExpenseById = (id: string) => {
  return http.get<Expense>(`/travel/expense/${id}`).then((response) => {
    return response;
  }).catch((error) => {
    handleError(error);
    throw new Error('Failed to get travels');
  });
};