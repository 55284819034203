import moment from 'moment';
import React, { useState } from 'react';
import { FaMoneyBillAlt, FaWrench } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Expense } from '../../shared/interfaces/Expense';
import { Project } from '../../shared/interfaces/Project';
import { Task } from '../../shared/interfaces/Task';
import { ExpenseDisplay } from './ExpenseDisplay';

interface TravelAccordionProps {
    travelId: number;
    invoiced: boolean;
    purpose: string;
    project: Project;
    task: Task;
    color?: string;
    startTime: moment.Moment;
    endTime: moment.Moment;
    expenses: Expense[];
    meterAtStart: number;
    meterAtEnd: number;
    route: string;
    invoicedFromCustomer: boolean
}

export const TravelAccordion = ({
  travelId,
  purpose,
  project,
  task,
  color,
  startTime,
  endTime,
  expenses,
  meterAtStart,
  meterAtEnd,
  route,
  invoiced,
  invoicedFromCustomer
}: TravelAccordionProps) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const currentDate = moment();

  const toggle = () => {
    setClicked(!clicked);
  };

  const getTotal = () => {
    let total = 0;
    expenses.forEach(expense => {
      total += expense.costEUR;
    });
    return total;
  };

  return (
    <AccordionSection>
      <Container>
        <Wrap data-testid="header-wrapper" bold={currentDate.isSame(currentDate, 'days')} color={color} onClick={() => toggle()}>
          <AccordionTitleContainer>
            <DateDisplay>
              {invoiced ?
                <></>
                :
                <EditTravelLink data-testid={`modify-travel[${travelId}]`} to={`/modifytravel/${travelId}?fromView=weekview&fromDate=${startTime.format('DD.MM.yyyy')}`}>
                  <FaWrench />
                </EditTravelLink>
              }
              <u>{project.name}</u></DateDisplay>
            <DateDisplay>{startTime.format('DD.MM.yyyy HH:mm')}</DateDisplay>
            <DateDisplay>{endTime.format('DD.MM.yyyy HH:mm')}</DateDisplay>
          </AccordionTitleContainer>
          <div>
            <DropdownIcon>{clicked ? '-' : '+'}</DropdownIcon>
          </div>
        </Wrap>
        {clicked ? (
          <Dropdown>
            <Content color={color}>
              <Row>
                <Title>
                                    Task
                </Title>
                <Value>
                  {task?.name}
                </Value>
              </Row>
              <Row>
                <Title>
                                    Distance
                </Title>
                <Value>
                  {(meterAtEnd - meterAtStart).toFixed(1)}km
                </Value>
              </Row>
              <Row>
                <Title>
                                    Purpose
                </Title>
                <Value>
                  {purpose}
                </Value>
              </Row>
              <Row>
                <Title>
                                    Route
                </Title>
                <Value>
                  {route}
                </Value>
              </Row>
              <Row>
                <Title>
                                    Invoiced
                </Title>
                <Value>
                  {invoiced ? 'Yes' : 'No'}
                </Value>
              </Row>
              <Row>
                <Title>
                                    Invoiced from customer
                </Title>
                <Value>
                  {invoicedFromCustomer ? 'Yes' : 'No'}
                </Value>
              </Row>
              <Row>
                <Title>
                                    Total
                </Title>
                <Value>
                  {getTotal().toFixed(2)}€
                </Value>
              </Row>
              <Row>
                <Title>
                                    Expenses
                </Title>
                <Value>
                  {!invoiced && <AddExpenseLink data-testid={`add-expense[${travelId}]`} to={`/addexpense/${travelId}?fromView=weekview&fromDate=${startTime.format('DD.MM.yyyy')}`}>
                    <FaMoneyBillAlt />
                                        +
                  </AddExpenseLink>}
                </Value>
              </Row>
              {expenses.map(expense => (
                <ExpenseDisplay
                  id={expense.id}
                  key={expense.id}
                  name={expense.expenseType.name}
                  currency={expense.currency}
                  currencyExchangeRate={expense.currencyExRate}
                  color={color ? color : ''}
                  quantity={expense.quantity}
                  cost={expense.cost}
                  invoiced={expense.invoiced}
                  costEUR={expense.costEUR}
                />
              ))}
            </Content>
          </Dropdown>) : null
        }
      </Container >
    </AccordionSection >
  );
};

const AccordionSection = styled.div`
  background-color: #CCEEF4;
`;

const Container = styled.div`
  font-size: 1.2rem;
`;

const AccordionTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const Wrap = styled.div<{ bold: boolean }>`
  background-color: ${props => props.color ? 'var(--bodyColor)' : 'var(--atrSecondaryColor)'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-weight: ${props => props.bold ? '700' : '400'};
  h1 {
    padding: 0 1.2rem;
    font-size: 1.2rem;
  }
  span {
    margin-right: 1.2rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  background-color:${props => props.color ? 'var(--bodyColor)' : 'var(--atrSecondaryColor)'};
`;

const Dropdown = styled.div`
  background: #ffffff;
  width: 100%;
  border-top: 1px solid #000000;
`;

const DateDisplay = styled.h1`
  min-width: 0;
  font-weight: 200;
  margin: 7px 0;
`;

const DropdownIcon = styled.span`
`;

const Row = styled.div`
    background-color: inherit;
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 0px 8px;
    margin: 5px;
`;

const Title = styled.div`
    grid-column: 1;
    padding-top: 3px;
`;

const Value = styled.div`
    grid-column: 2;
    text-align: right;
    margin: 5px;
`;

const AddExpenseLink = styled(Link)`
    padding: 3px 6px 0px 6px;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    display: inline-block;
`;

const EditTravelLink = styled(Link)`
    padding: 3px 4px;
    padding-top: 5px;
    margin: 4px;
    overflow: visible;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    border-radius: 7px;
`;
