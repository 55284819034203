import { Flexitime } from '../shared/interfaces/Flexitime';
import { SaveFlexitimeParam } from '../shared/interfaces/request/SaveFlexitimeParam';
import { http } from '../utils/interceptor';
import { handleError } from '../utils/serviceErrorHandler';

export const getFlexitimes = (startDate: string, endDate: string) => {
  return http.get<Flexitime[]>(`/flexitime?from=${startDate}&to=${endDate}`).then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error('Failed to get flexitimes');
  });
};

export const getFlexitimeTotal = () => {
  return http.get<number>('/flexitime/total').then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error('Failed to get flexitimes');
  });
};

export const saveFlexitime = (flexiHours: SaveFlexitimeParam) => {
  return http.post('/flexitime', flexiHours).then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error('Failed to save flexitimes');
  });
};