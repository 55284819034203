import React from 'react';
import { FaWrench } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ExpenseDisplayProps {
    name: string;
    color?: string;
    quantity: number;
    cost: number;
    costEUR: number;
    id: number;
    invoiced: boolean;
    currency: string;
    currencyExchangeRate: number;
}

export const ExpenseDisplay = ({ id, name, invoiced, currency, currencyExchangeRate, color, quantity, cost, costEUR }: ExpenseDisplayProps) => {
  return (
    <Container color={color}>
      <Title>
        {!invoiced &&
                    <EditHoursLink data-testid={`modify-expense[${id}]`} to={`/modifyexpense/${id}`}>
                      <FaWrench />
                    </EditHoursLink>
        }
        {name}
      </Title>
      <Row>
                Amount: {cost.toFixed(2)}€
      </Row>
      <Row>
                Quantity: {quantity.toFixed(1)}
      </Row>
      <Row>
                Total: {costEUR.toFixed(2)}€
      </Row>
      <Row>
                Currency: {currency}
      </Row>
      <Row>
                Currency exchange rate: {currencyExchangeRate.toFixed(2)}
      </Row>
      <Row>
                Invoiced: {invoiced ? 'Yes' : 'No'}
      </Row>
    </Container>
  );
};

const Container = styled.div`
    margin: 10px 0;
    padding-left: 20px;
    font-size: 17px;
    background-color:${props => props.color ? 'var(--bodyColor)' : 'var(--atrSecondaryColor)'};
`;

const Row = styled.div`
    background-color: inherit;
    margin-top: 5px;
`;

const Title = styled.div`
    margin: 8px 0;
    text-decoration: underline;
`;

const EditHoursLink = styled(Link)`
    padding: 3px 4px;
    padding-top: 5px;
    margin: 4px;
    overflow: visible;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: var(--atrMainColor);
    border-radius: 7px;
`;