import React from 'react';
import { BiX } from 'react-icons/bi';
import styled from 'styled-components';
import { ProjectByIdSelector, TaskByIdSelector } from '../../redux/selectors/projects';
import { Task } from '../../shared/interfaces/Task';

interface ProjectInfoPopupProps {
    closePopup: () => void;
    projectId: number;
    taskId: number;
}

export const TaskInfoPopup = ({ closePopup, projectId, taskId }: ProjectInfoPopupProps) => {
  const task: Task | undefined = TaskByIdSelector(projectId, taskId);
  return (
    <OuterContainer >
      <Container>
        {task ?
          <TextContainer>
            <TextSegment>
              <Title>
                                Task
              </Title>
              {task.name}
            </TextSegment>
            <TextSegment>
              <Title>
                                Description
              </Title>
              {task.description ? task.description : '-'}
            </TextSegment>
            <TextSegment>
              <Title>
                                Planned hours
              </Title>
              {task.plannedHours ? task.plannedHours : '-'}
            </TextSegment>
          </TextContainer>
          :
          <TextContainer>
            <h2>ERROR</h2>
            <h4>Could not get task information</h4>
          </TextContainer>
        }
        <CloseButton onClick={closePopup}>
          <BiX />
        </CloseButton>
      </Container>
    </OuterContainer>
  );
};


const OuterContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
`;

const Container = styled.div`
  position: fixed;
  background-color: var(--bodyColor);
  padding: 10px;
  width: 850px;
  max-width: 82%;
  max-height: 92vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 3%;
  top: 15%;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  @media(max-width: 1100px){
    overflow-y: scroll;
    max-height: 80vh;
  }
`;

const TextContainer = styled.div`
  pointer-events: none;
`;

const CloseButton = styled.button`
  color: var(--textColor);
  cursor: pointer;
  height: 1em;
  width: 1em;
  font-size: 40px;
  padding: 0;
  border: none;
  background: none;
`;

const Title = styled.h3`
  margin: 0;
`;

const TextSegment = styled.div`
  padding-bottom: 8px;
`;