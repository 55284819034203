import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Validation } from '../../shared/interfaces/util/FormValidation';
import { SaveFlexitimeParam } from '../../shared/interfaces/request/SaveFlexitimeParam';
import { useAppDispatch } from '../../redux/store';
import { createToast } from '../../redux/slices/toastSlice';
import { getFlexitimeBalance, fetchFlexitimes, modifyFlexitime } from '../../redux/slices/flexitimeSlice';

interface FlexitimeCardProps {
  flexitime?: number;
  color?: string;
  date: moment.Moment;
}

interface FormData {
  hours: string
}

export const FlexitimeCard = ({ flexitime, color, date }: FlexitimeCardProps) => {

  const { register, formState: { errors }, handleSubmit } = useForm();

  const [editing, setEditing] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = (data: FormData) => {
    setEditing(false);
    const flexitime = Number(data.hours.replace(',', '.'));
    submitFlexitime(flexitime);
  };

  const submitFlexitime = (flexitime: number) => {
    if (!date) return;
    const saveFlexiParams: SaveFlexitimeParam = {
      date: date?.format('YYYY-MM-DD'),
      hours: flexitime
    };
    dispatch(modifyFlexitime(saveFlexiParams))
      .then(() => {
        dispatch(fetchFlexitimes({ startDate: moment(saveFlexiParams.date), endDate: moment(saveFlexiParams.date) }));
        dispatch(getFlexitimeBalance());
        dispatch(createToast('success', 'Success', 'Flexitime set successfully'));

      })
      .catch(err => {
        console.log(err);
        dispatch(createToast('error', 'Error', 'Error setting flexitime'));
      });
  };

  const hoursValidation: Validation = {
    required: {
      value: true,
      message: 'Required'
    },
    min: {
      value: -24,
      message: 'Must be -24 or larger'
    },
    max: {
      value: 24,
      message: 'Must be 24 or smaller'
    },
    //only accepts numbers and decimals
    pattern: {
      value: /((^|, )(^-?\d*,?\d*|^-?\d*\.?\d*))+$/,
      message: 'Must be a number'
    }
  };

  return (
    <Container color={color}>
      <TextContainer>
        Flexitime
      </TextContainer>
      <HoursContainer>
        {editing &&
          <form onSubmit={handleSubmit(onSubmit)}>
            <EditButton type="button" onClick={() => setEditing(false)}>Cancel</EditButton>
            <SubmitButton type="submit" value="Apply" />
            <Input
              type="text"
              inputMode="decimal"
              defaultValue={flexitime ? flexitime : 0}
              {...register('hours', hoursValidation)}
            />
            {errors.hours && <Error>{errors.hours.message}</Error>}
          </form>
        }
        {!editing &&
          <>
            <EditButton onClick={() => setEditing(true)}>Change</EditButton>
            <HoursText>{flexitime ? flexitime.toFixed(2) : '0.00'}h</HoursText>
          </>
        }
      </HoursContainer>
    </Container>
  );
};

const Container = styled.div`
    transition: background-color 0.25s;
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 6px;
    margin: 5px;
    background-color: ${props => props.color === 'primary' ? 'var(--bodyColor)' : 'var(--atrSecondaryColor)'};
`;

const TextContainer = styled.div`
    grid-column: 1;
    padding-top: 7px;
`;

const HoursContainer = styled.div`
    grid-column: 2;
    text-align: right;
`;

const HoursText = styled.span`
    margin-left: 5px;
`;

const EditButton = styled.button`
    padding: 5px 5px;
    margin: 2px;
    border: none;
    font-size: 1.2rem;
    color: white;
    background-color:  var(--atrMainColor);
`;

const SubmitButton = styled.input`
    padding: 5px 5px;
    margin: 2px;
    border: none;
    font-size: 1.2rem;
    color: white;
    background-color: var(--atrMainColor);`;

const Input = styled.input`
    width: 40px;
    height: 32px;
    border: none;
    padding: 2px;
    margin-top: 1px;
    font-size: 1.2rem;
    color: white;
    background-color: #333;
`;

const Error = styled.div`
    font-size: 1.1rem;
    color: #D42E12;
    grid-row: 2;
    grid-column: 2;
`;