import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

const BookingByIdSelector = (id: number) => {
  return useSelector((state: RootState) => {
    if(isNaN(id)) return;
    return state.bookings.find(b => b.id === id);
  });
};

const BookingsSelector = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
  return useSelector((state: RootState) => {
    if (!startDate || !endDate) return;
    return state.bookings.filter((booking) => {
      const date = moment(booking.date);
      return startDate.isSameOrBefore(date, 'days') && date.isSameOrBefore(endDate, 'days');
    });
  });
};

export { BookingByIdSelector, BookingsSelector };