import styled from 'styled-components';

import { Label } from './Label';
import { InputError } from './InputError';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { Errors, Validation } from '../../shared/interfaces/util/FormValidation';

type TextAreaInputProps = {
    label: string;
    name: string;
    defaultValue?: string;
    register: UseFormRegister<FieldValues>;
    validation: Validation;
    errors: Errors;
}

export const TextAreaInput = ({ label, name, defaultValue, register, validation, errors }: TextAreaInputProps) => {
  return (
    <Container >
      <Label forInput={name} text={label} />
      <TextAreaInputField
        id={name}
        defaultValue={defaultValue}
        {...register(name, validation)}
      />
      {errors && <InputError message={errors.message} />}
    </Container>
  );
};

const Container = styled.div`
    padding: 5px 0px;
`;

const TextAreaInputField = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    border: 0;
    padding: 8px 10px;
    background-color: var(--inputColor);
    color: white;
`;