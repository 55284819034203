import React from 'react';
import styled from 'styled-components';

export const Loader = () => {
  return (
    <LoaderContainer>
      <Spinner />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
    width: 100%;
    padding: 2rem 0;
`;

const Spinner = styled.div`
    margin: 0 auto;
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid var(--atrMainColor); /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;