import moment from 'moment';
import { useSelector } from 'react-redux';
import { Travel } from '../../shared/interfaces/Travel';
import { RootState } from '../store';

export const TravelsSelector = (startDate: moment.Moment, endDate: moment.Moment) => {
  return useSelector((state: RootState) => {
    if (!startDate || !endDate) return;
    return state.travels.filter((travel) => {
      return (moment(travel.startTime).isSameOrAfter(startDate, 'days') && moment(travel.startTime).isSameOrBefore(endDate, 'days')) ||
                (moment(travel.endTime).isSameOrAfter(startDate, 'days') && moment(travel.endTime).isSameOrBefore(endDate, 'days')) ||
                ((moment(travel.startTime).isBefore(startDate, 'days') && moment(travel.endTime).isAfter(endDate, 'days')));
    });
  });
};

export const TravelByIdSelector = (id: string | undefined): Travel | undefined => {
  return useSelector((state: RootState) => {
    return state.travels.find((travel) => travel.id === Number(id));
  });
};