import { CreateSurveyParam } from '../shared/interfaces/request/CreateSurveyParam';
import { Survey } from '../shared/interfaces/Survey';
import { SurveyResponse } from '../shared/interfaces/SurveyResponse';
import { http } from '../utils/interceptor';
import { handleError } from '../utils/serviceErrorHandler';

export const getSurveyResponses = (username?: string, surveyId?: string, submitType?: string, submitValue?: string, startDate?: string, endDate?: string) => {
  let queryString = '';
  queryString = appendQueryParam(queryString, 'username', username);
  queryString = appendQueryParam(queryString, 'surveyId', surveyId);
  queryString = appendQueryParam(queryString, 'submitType', submitType);
  queryString = appendQueryParam(queryString, 'submitValue', submitValue);
  queryString = appendQueryParam(queryString, 'startDate', startDate);
  queryString = appendQueryParam(queryString, 'endDate', endDate);
  return http.get<SurveyResponse[]>(`/users/current/surveys/responses${queryString}`).then(response => {
    return response;
  }).catch(error => {
    handleError(error);
    throw new Error('Failed to get bookings');
  });
};

export const getSurveys = () => {
  return http.get<Survey[]>('/surveys')
    .catch(error => {
      handleError(error);
      throw new Error('Failed to get surveys');
    });
};

const appendQueryParam = (queryString: string, key: string, value?: string) => {
  if (value && Number(value) !== -1 && value !== '') {
    if (queryString.length === 0) {
      return `${queryString}?${key}=${value}`;
    }
    return `${queryString}&${key}=${value}`;
  }
  return queryString;
};

export const getSurvey = (id: number) => {
  return http.get<Survey>(`/surveys/${id}`)
    .catch(error => {
      handleError(error);
      throw new Error('Failed to get survey');
    });
};

export const createSurvey = (survey: CreateSurveyParam) => {
  return http.post<Survey>('/surveys', survey)
    .catch(error => {
      handleError(error);
      throw new Error('Failed to create survey');
    });
};

export const updateSurvey = (survey: Survey) => {
  return http.put<Survey>('/surveys', survey)
    .catch(error => {
      handleError(error);
      throw new Error('Failed to update survey');
    });
};