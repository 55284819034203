import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import HamburgerIcon from '../HamburgerIcon/HamburgerIcon';
import { BiUser, BiLogOut, BiMoon, BiSun } from 'react-icons/bi';
import { Toggle } from '../Toggle/Toggle';
import { FiSettings } from 'react-icons/fi';

interface HeaderProps {
    username?: string;
    logout: () => void;
    sidebarOpen: boolean;
    openSidebar: () => void;
    darkTheme: boolean;
    toggleTheme: () => void;
}

const Header = ({ username, darkTheme, toggleTheme, logout, openSidebar, sidebarOpen }: HeaderProps) => {

  const dropdownRef = useRef<HTMLDivElement>(null);
  const settingsRef = useRef<HTMLSpanElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  /**
     * Check if user clicks outside menu to close it
     */
  useEffect(() => {
    const handler = (event: any) => {
      if (dropdownRef.current && settingsRef.current && !settingsRef.current.contains(event.target) && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  if (window.innerWidth < 900) {
    return (
      <HeaderContainer >
        <UserInfo id="user-info">
          <UserIcon >
            <BiUser></BiUser>
          </UserIcon>
          {dropdownOpen &&
                        <Dropdwon ref={dropdownRef}>
                          <li style={{ fontSize: '19px', marginBottom: '10px' }} onClick={() => logout()}><BiLogOut /> Logout</li>
                          <li style={{ fontSize: '19px', marginLeft: '3px' }}>
                            {darkTheme ? <BiMoon /> : <BiSun />}
                            <Toggle nightMode={darkTheme} toggleTheme={toggleTheme} />
                          </li>

                        </Dropdwon>}
          <span style={{ verticalAlign: '10%' }}>{username}</span>
          <span ref={settingsRef} data-testid="settings" onClick={() => setDropdownOpen(!dropdownOpen)}>
            <FiSettings style={{ fontSize: '20px', marginLeft: '20px', marginRight: '15px' }}>
            </FiSettings>
          </span>
        </UserInfo>
        {
          !sidebarOpen &&
                    <Hamburger onClick={() => openSidebar()} className="sidebar-open" data-testid="open-sidebar">
                      <HamburgerIcon />
                    </Hamburger>
        }
      </HeaderContainer >
    );
  }
  return (
    <HeaderContainer >
      <UserInfo id="user-info">
        <span style={{ marginRight: '15px', verticalAlign: '10%' }}>
          {darkTheme ?
            <BiMoon style={{ fontSize: '20px', verticalAlign: '-19%' }} />
            :
            <BiSun style={{ fontSize: '20px', verticalAlign: '-19%' }} />}
          <Toggle nightMode={darkTheme} toggleTheme={toggleTheme} />
        </span>
        <UserIcon >
          <BiUser></BiUser>
        </UserIcon>
        <span style={{ verticalAlign: '10%' }}>{username}</span>
        <UserInfoButton onClick={() => logout()}>
                    Logout
        </UserInfoButton>
      </UserInfo>
      {
        !sidebarOpen &&
                <Hamburger onClick={() => openSidebar()} className="sidebar-open" data-testid="open-sidebar">
                  <HamburgerIcon />
                </Hamburger>
      }
    </HeaderContainer >
  );
};

export default Header;

const HeaderContainer = styled.header`
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
            display: flex;
            justify-content: space-between;
            height: 3.5rem;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            `;

const Dropdwon = styled.div`
            top: 58px;
            right: 5px;
            position: absolute;
            border-radius: 2px;
            min-width: 120px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            padding: 12px 16px;
            z-index: 2000000;
            text-align: left;
            background: var(--bodyColor);
            list-style: none;
            `;

const Hamburger = styled.button`
            margin-left: 1.3rem;
            background: transparent;
            border: transparent;
            :hover {
                cursor: pointer;
    }
            `;

const UserInfo = styled.div`
            padding: 5px;
            font-size: 16px;
            `;

const UserInfoButton = styled.button`
    color: white;
    background-color: var(--atrMainColor);
    padding: 7px;
    margin: 25px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
`;

const UserIcon = styled.span`
            margin-right: 0.2rem;
            font-size: 20px;
            `;
