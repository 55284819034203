import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

interface DateDisplayProps {
    date: moment.Moment;
    timeUnit: 'days' | 'weeks';
    onDateChange: (newDate: moment.Moment) => void;
}

export const DateDisplay = ({ date, timeUnit, onDateChange }: DateDisplayProps) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = event.target.value;
    const parsedDate = moment(dateString, 'YYYY-MM-DD', true);
    if (parsedDate.isValid()) {
      onDateChange(parsedDate);
    }
  };

  return (
    <Container>
      <ArrowButton
        title="Previous day"
        onClick={() => onDateChange(date.clone().subtract(1, timeUnit))}
      >
        <Icon viewBox="0 0 15 10">
          <polygon points="0,5 5,10, 15,10 15,0 5,0"
            fill="var(--atrMainColor)" />
        </Icon>
      </ArrowButton>
      <DateInput
        title="Date input"
        type="date"
        value={date.format('YYYY-MM-DD')}
        onChange={(e) => handleChange(e)}
      />
      <ArrowButton
        title="Next day"
        onClick={() => onDateChange(date.clone().add(1, timeUnit))}
      >
        <Icon viewBox="0 0 15 10">
          <polygon points="0,0 0,10, 10,10 15,5 10,0"
            fill="var(--atrMainColor)" />
        </Icon>
      </ArrowButton>
    </Container>
  );
};

const Container = styled.div`
    text-align: center;
`;

const DateInput = styled.input`
    display: inline-block;
    font-weight: bold;
    font-size: 1.5rem;
    border: none;
    background: none;
    max-width: 210px;
    color: var(--textColor)
`;

const ArrowButton = styled.button`
    margin: -5px;
    border: none;
    background: none;
    cursor: pointer;
`;

const Icon = styled.svg`
    display: inline;
    height: 25px;
    margin-bottom: -2px;
    overflow: visible;
`;