import styled from 'styled-components';

type InputErrorProps = {
    message: string;
}

export const InputError = ({ message }: InputErrorProps) => {
  return (
    <InputErrorStyled>{message}</InputErrorStyled>
  );
};

const InputErrorStyled = styled.span`
    display: block;
    color: #D42E12;
    font-size: 14px;
    font-weight: bold;
`;